import { Button, Input, Pagination, Spin, Table, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import GroupService from "../../services/groupService";
import { toast } from "react-toastify";
import AddRoute from "./AddRoute";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { storedKeys } from "../../utils/storedKeys";
import { handleDeleteCommon } from "../../utils/CommonFunctions";
import UserService from "../../services/userService";
import axios from "../../api/axios";
import CreditService from "../../services/creditService";
import useAuth from "../../hooks/useAuth";
import PaginationCommon from "../../components/PaginationCommon";
const Route = ({ userId }) => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState("")
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [routeData, setRouteData] = useState([])
  const [addRoute, setAddRoute] = useState(false);
  const [userCredits, setUserCredits] = useState(null);

  useEffect(() => {
    if (auth?._id) {
      getUserCredits(auth?._id)

    }
  }, [auth?._id])
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      getAllRoute(offSet, limit, response)
      setUserCredits(response.data?.data.userCredits);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  const getAllRoute = async (offSet, limit, userCredits = null) => {
    try {
      let response;
      setLoading(true)
      if (UserService.isAdmin()) {
        response = await axios.get(`/route?offset=${offSet}&limit=${limit}&user_id=${userId}`);
      } else {
        response = await GroupService.allRoute(0, 10);
        if (userCredits && userCredits.data.data.userCredits.length === 0 && response.data.data.routes.length === 0) {
          navigate("/settings/history");
        }
      }

      setRouteData(response.data?.data.routes.map((e) => ({ ...e, key: e._id })));
      setCount(response.data?.data.total_count);
      setLoading(false)
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setLoading(false)

    }
  }
  const handleDelete = (routeId) => {
    handleDeleteCommon(
      `/group?route_id=${routeId}`,
      { route_id: routeId },
      null,
      "Route",
      () => getAllRoute(0, 10),


    )
  }
  const columns = [

    {
      title: "Name",
      render: (e) => (
        <div className="text-primary italic cursor-pointer flex items-center gap-2"
          onClick={() => navigate(`/route/${e._id}`, { state: { route: e, userId: userId } })}
        >
          {e?.name}
          {/* <GrLinkTop /> */}
        </div>
      ),
    },


    {
      title: "Numbers Count", render: (_, record) => {
        {
          const count = record?.number_count ? `${record?.number_count}` : "0";
          return <span>{count}</span>;
        }
      },
    },
    {
      title: "",
      render: (row) => (
        <>
          <Tooltip title="Download">
            <Button
              className="bg-primary text-white hover-none border-none"
              onClick={() => handleDownloadCSV(row)}
              icon={<DownloadOutlined />}
            />
          </Tooltip>
          {!UserService.isAdmin() && row?.is_default === false &&  (
            <Button
              className="ms-2 text-white"
              onClick={() => handleDelete(row._id)}
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          )}
        </>
      ),
    },
  ]
  const handleDownloadCSV = async (row) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/group/download?route_id=${row?._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "text/csv",
          Authorization: JSON.parse(localStorage.getItem(storedKeys.accessToken)),
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${row.name}.csv`);
      // _${getKeyByValue(selectedTypes, true)} this for naming the file
      document.body.appendChild(link);
      link.click();
      toast.success("CSV file downloaded successfully");
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV");

    }
  };
  return (<>
    {
      !UserService.isAdmin() &&
      <div className="flex items-center justify-between">
        <Text className="text-2xl font-bold text-white">Routes</Text>
        <Button
          onClick={() => setAddRoute(true)}
          className="bg-primary text-white flex items-center custom-ant-btn"
        //  icon={<FaPlus />}
        >
          Add Route
        </Button>
      </div>
    }

    <div className="mt-3 bg-card-bg p-3">
      {loading && (
        <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <Spin size="large" className="custom-spin" />
        </div>
      )}
      <div className="flex justify-end mb-3">
        <Input
          // value={searchValue}
          // onChange={filterUsers}
          size="middle"
          className="w-52 custom-input"
          placeholder="Filter Users"
        />
      </div>
      <Table
        className="custom-table"
        // loading={loading}
        columns={columns}
        dataSource={routeData}
        pagination={false}
      />
    </div>
    <PaginationCommon total={count} data={routeData} handleChange={getAllRoute} />
    <AddRoute open={addRoute} onClose={() => setAddRoute(false)} onSuccess={getAllRoute} />

  </>)
}
export default Route;