module.exports.PROXY_STATE = {
    ON : 'ON',
    OFF: 'OFF',
    POWER_ON:'power_on',
    SHUTDOWN:'shutdown',
}
module.exports.COUNTRIES=
     [
        {
            "name": "United States",
            "abbreviation": "US",
            "states": [
                {
                    "name": "Alabama",
                    "abbreviation": "AL"
                },
                {
                    "name": "Alaska",
                    "abbreviation": "AK"
                },
                {
                    "name": "American Samoa",
                    "abbreviation": "AS"
                },
                {
                    "name": "Arizona",
                    "abbreviation": "AZ"
                },
                {
                    "name": "Arkansas",
                    "abbreviation": "AR"
                },
                {
                    "name": "California",
                    "abbreviation": "CA"
                },
                {
                    "name": "Colorado",
                    "abbreviation": "CO"
                },
                {
                    "name": "Connecticut",
                    "abbreviation": "CT"
                },
                {
                    "name": "Delaware",
                    "abbreviation": "DE"
                },
                {
                    "name": "District Of Columbia",
                    "abbreviation": "DC"
                },
                {
                    "name": "Federated States Of Micronesia",
                    "abbreviation": "FM"
                },
                {
                    "name": "Florida",
                    "abbreviation": "FL"
                },
                {
                    "name": "Georgia",
                    "abbreviation": "GA"
                },
                {
                    "name": "Guam",
                    "abbreviation": "GU"
                },
                {
                    "name": "Hawaii",
                    "abbreviation": "HI"
                },
                {
                    "name": "Idaho",
                    "abbreviation": "ID"
                },
                {
                    "name": "Illinois",
                    "abbreviation": "IL"
                },
                {
                    "name": "Indiana",
                    "abbreviation": "IN"
                },
                {
                    "name": "Iowa",
                    "abbreviation": "IA"
                },
                {
                    "name": "Kansas",
                    "abbreviation": "KS"
                },
                {
                    "name": "Kentucky",
                    "abbreviation": "KY"
                },
                {
                    "name": "Louisiana",
                    "abbreviation": "LA"
                },
                {
                    "name": "Maine",
                    "abbreviation": "ME"
                },
                {
                    "name": "Marshall Islands",
                    "abbreviation": "MH"
                },
                {
                    "name": "Maryland",
                    "abbreviation": "MD"
                },
                {
                    "name": "Massachusetts",
                    "abbreviation": "MA"
                },
                {
                    "name": "Michigan",
                    "abbreviation": "MI"
                },
                {
                    "name": "Minnesota",
                    "abbreviation": "MN"
                },
                {
                    "name": "Mississippi",
                    "abbreviation": "MS"
                },
                {
                    "name": "Missouri",
                    "abbreviation": "MO"
                },
                {
                    "name": "Montana",
                    "abbreviation": "MT"
                },
                {
                    "name": "Nebraska",
                    "abbreviation": "NE"
                },
                {
                    "name": "Nevada",
                    "abbreviation": "NV"
                },
                {
                    "name": "New Hampshire",
                    "abbreviation": "NH"
                },
                {
                    "name": "New Jersey",
                    "abbreviation": "NJ"
                },
                {
                    "name": "New Mexico",
                    "abbreviation": "NM"
                },
                {
                    "name": "New York",
                    "abbreviation": "NY"
                },
                {
                    "name": "North Carolina",
                    "abbreviation": "NC"
                },
                {
                    "name": "North Dakota",
                    "abbreviation": "ND"
                },
                {
                    "name": "Northern Mariana Islands",
                    "abbreviation": "MP"
                },
                {
                    "name": "Ohio",
                    "abbreviation": "OH"
                },
                {
                    "name": "Oklahoma",
                    "abbreviation": "OK"
                },
                {
                    "name": "Oregon",
                    "abbreviation": "OR"
                },
                {
                    "name": "Palau",
                    "abbreviation": "PW"
                },
                {
                    "name": "Pennsylvania",
                    "abbreviation": "PA"
                },
                {
                    "name": "Puerto Rico",
                    "abbreviation": "PR"
                },
                {
                    "name": "Rhode Island",
                    "abbreviation": "RI"
                },
                {
                    "name": "South Carolina",
                    "abbreviation": "SC"
                },
                {
                    "name": "South Dakota",
                    "abbreviation": "SD"
                },
                {
                    "name": "Tennessee",
                    "abbreviation": "TN"
                },
                {
                    "name": "Texas",
                    "abbreviation": "TX"
                },
                {
                    "name": "Utah",
                    "abbreviation": "UT"
                },
                {
                    "name": "Vermont",
                    "abbreviation": "VT"
                },
                {
                    "name": "Virgin Islands",
                    "abbreviation": "VI"
                },
                {
                    "name": "Virginia",
                    "abbreviation": "VA"
                },
                {
                    "name": "Washington",
                    "abbreviation": "WA"
                },
                {
                    "name": "West Virginia",
                    "abbreviation": "WV"
                },
                {
                    "name": "Wisconsin",
                    "abbreviation": "WI"
                },
                {
                    "name": "Wyoming",
                    "abbreviation": "WY"
                }
            ]
        },
        {
            "name": "Canada",
            "abbreviation": "CA",
            "states": [
                // {
                //     "name": "All",
                //     "abbreviation": "All"
                // },
                {
                    "name": "Alberta",
                    "abbreviation": "AB"
                },
                {
                    "name": "British Columbia",
                    "abbreviation": "BC"
                },
                {
                    "name": "Manitoba",
                    "abbreviation": "MB"
                },
                {
                    "name": "New Brunswick",
                    "abbreviation": "NB"
                },
                {
                    "name": "Newfoundland and Labrador",
                    "abbreviation": "NL"
                },
                {
                    "name": "Northwest Territories",
                    "abbreviation": "NT"
                },
                {
                    "name": "Nova Scotia",
                    "abbreviation": "NS"
                },
                {
                    "name": "Nunavut",
                    "abbreviation": "NU"
                },
                {
                    "name": "Ontario",
                    "abbreviation": "ON"
                },
                {
                    "name": "Prince Edward Island",
                    "abbreviation": "PE"
                },
                {
                    "name": "Quebec",
                    "abbreviation": "QC"
                },
                {
                    "name": "Saskatchewan",
                    "abbreviation": "SK"
                },
                {
                    "name": "Yukon Territory",
                    "abbreviation": "YT"
                }
            ]
        }
    ]
module.exports.AREACODE= {
    "Alabama": ["205", "251", "256", "334", "659", "938"],
    "Alaska": ["907"],
    "Arizona": ["480", "520", "602", "623", "928"],
    "Arkansas": ["479", "501", "870"],
    "California": ["209", "213", "279", "310", "323", "341", "408", "415", "424", "442", "510", "530", "559", "562", "619", "626", "628", "650", "657", "661", "669", "707", "714", "747", "760", "805", "818", "820", "831", "858", "909", "916", "925", "949", "951"],
    "Colorado": ["303", "719", "720", "970"],
    "Connecticut": ["203", "475", "860", "959"],
    "Delaware": ["302"],
    "District Of Columbia": ["202"],
    "Florida": ["239", "305", "321", "352", "386", "407", "561", "689", "727", "754", "772", "786", "813", "850", "863", "904", "941", "954"],
    "Georgia": ["229", "404", "470", "478", "678", "706", "762", "770", "912"],
    "Hawaii": ["808"],
    "Idaho": ["208", "986"],
    "Illinois": ["217", "224", "309", "312", "331", "618", "630", "708", "773", "779", "815", "847", "872"],
    "Indiana": ["219", "260", "317", "463", "574", "765", "812", "930"],
    "Iowa": ["319", "515", "563", "641", "712"],
    "Kansas": ["316", "620", "785", "913"],
    "Kentucky": ["270", "364", "502", "606", "859"],
    "Louisiana": ["225", "318", "337", "504", "985"],
    "Maine": ["207"],
    "Maryland": ["227", "240", "301", "410", "443", "667"],
    "Massachusetts": ["339", "351", "413", "508", "617", "774", "781", "857", "978"],
    "Michigan": ["231", "248", "269", "313", "517", "586", "616", "734", "810", "906", "947", "989"],
    "Minnesota": ["218", "320", "507", "612", "651", "763", "952"],
    "Mississippi": ["228", "601", "662", "769"],
    "Missouri": ["314", "417", "573", "636", "660", "816"],
    "Montana": ["406"],
    "Nebraska": ["308", "402", "531"],
    "Nevada": ["702", "725", "775"],
    "New Hampshire": ["603"],
    "New Jersey": ["201", "551", "609", "640", "732", "848", "856", "862", "908", "973"],
    "New Mexico": ["505", "575"],
    "New York": ["212", "315", "332", "347", "516", "518", "585", "607", "631", "646", "680", "716", "718", "838", "845", "914", "917", "929", "934"],
    "North Carolina": ["252", "336", "704", "743", "828", "910", "919", "980", "984"],
    "North Dakota": ["701"],
    "Ohio": ["216", "220", "234", "283", "326", "330", "380", "419", "440", "513", "567", "614", "740", "937"],
    "Oklahoma": ["405", "539", "572", "580", "918"],
    "Oregon": ["458", "503", "541", "971"],
    "Pennsylvania": ["215", "223", "267", "272", "412", "445", "484", "570", "610", "717", "724", "814", "878"],
    "Rhode Island": ["401"],
    "South Carolina": ["803", "839", "843", "854", "864"],
    "South Dakota": ["605"],
    "Tennessee": ["423", "615", "629", "731", "865", "901", "931"],
    "Texas": ["210", "214", "254", "281", "325", "346", "361", "409", "430", "432", "469", "512", "682", "713", "726", "737", "806", "817", "830", "832", "903", "915", "936", "940", "956", "972", "979"],
    "Utah": ["385", "435", "801"],
    "Vermont": ["802"],
    "Virginia": ["276", "434", "540", "571", "703", "757", "804"],
    "Washington": ["206", "253", "360", "425", "509", "564"],
    "West Virginia": ["304", "681"],
    "Wisconsin": ["262", "414", "534", "608", "715", "920"],
    "Wyoming": ["307"],
    "Alberta": ["403", "587", "825"],
    "British Columbia": ["236", "250", "778", "236"],
    "Manitoba": ["204", "431"],
    "New Brunswick": ["506"],
    "Newfoundland and Labrador": ["709"],
    "Northwest Territories": ["867"],
    "Nova Scotia": ["782", "902"],
    "Nunavut": ["867"],
    "Ontario": ["226", "249", "289", "343", "365", "416", "437", "519", "548", "613", "647", "705", "807", "905"],
    "Prince Edward Island": ["782", "902"],
    "Quebec": ["418", "438", "450", "514", "579", "581", "819", "873"],
    "Saskatchewan": ["306", "639"],
    "Yukon Territory": ["867"],
    "American Samoa": ["684"],
    "Federated States Of Micronesia": ["691"],
    "Guam" : ["671"],
    "Marshall Islands": ["692"],
    "Northern Mariana Islands" : ["670"],
    "Palau" : ["680"],
    "Puerto Rico" :["787", "939"],
    "Virgin Islands" : ["340"],
};
module.exports.FONE_NUMBERS_TYPE = {
    LOCAL : {value:'local',label:"Local"},
    TOLL_FREE: {value:'toll_free',label:"Toll Free"}
}
module.exports.QUANTITYSELECTION ={
    AREACODE:{value:'area_code',label:"Area Code"},
    STATE:{value:'state',label:"State"},
    QUANTITY:{value:'quantity',label:"Quantity"},
}
module.exports.COUNTRY ={
    US:"US",
    CANADA:"Canada",
    BOTH:"US/Canada"
}
module.exports.MANUAL_CSV ={
    MANUAL:"Manual",
    UPLOADFILE:'Upload File'
}
module.exports.TRANSACTION_TYPE = {
    MANUAL : 'manual',
    CREDIT: 'credit'
}