import { Pagination } from "antd"
import { useEffect, useState } from "react";
export default function PaginationCommon({
  total,
handleChange,
data,
id=null,
setOffSet,
setLimit,
})
{

    const handleChangeCustom=(page,pageSize)=>{
        handleChange(page-1, pageSize,id);
        if (setOffSet) {
          setOffSet(page - 1);
      }
      if (setLimit) {
          setLimit(pageSize);
      }
    }
return(
    <>
    {
      data?.length > 0 && 
      <div className="mt-4 custom-pagination " style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
      <Pagination total={total} showLessItems  onChange={handleChangeCustom} />
  </div>
    }
 

    </>
)
}