import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Typography, Table, Button, Input, Switch, Tooltip } from "antd";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { EditOutlined} from "@ant-design/icons";

import CreditService from "../../services/creditService";
import AddPackage from "./addPackage";
import AddCredit from "./addCredit";
import AddBalance from "./addBalance";
import UserService from "../../services/userService";
import axios from "axios";
import moment from "moment";

const { Text } = Typography;

const Credits = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const addCreditPermission = UserService.getPermission("addCredit");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [addPackage, setAddPackage] = useState(false);
  const [addCredit, setAddCredit] = useState(false);
  const [credits, setCredits] = useState([]);
  const [addBalance, setAddBalance] = useState(false);
  const [balance, setBalance] = useState([]);
  const [isEdit,setIsEdit]=useState(false)
  const [filteredCredits, setFilteredCredits] = useState([]);
  const [users, setUsers] = useState([]);
  const [rows,setRows]=useState([])
  const[dataEdit,setDataEdit]=useState([])
  const [isChecked, setIsChecked] = useState({});
  useEffect(() => {
    getAllCredits();
    getAllUsers();
  }, []);

  const getAllCredits = async () => {
    setLoading(true);
    try {
      const response = await CreditService.all(0, -1);
      setCredits(response.data?.data.credits.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const getAllUsers = async () => {
    setLoading(true);
    try {
      const response = await UserService.all(0, -1);
      setUsers(response.data?.data.users.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };

  const filterCredits = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = credits?.filter((item) => Object.values(item).some((value) => typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())));
    setFilteredCredits(result);
  };

const handleToggle = async (id, currentValue) => {
  try {
    // const newValue = !currentValue;
    const response=await CreditService.toggle(id,currentValue)
    const updatedData = credits.map(item => {
      if (item._id === id) {
        return { ...item, state: currentValue };
      }
      return item;
    });
    setCredits(updatedData);
    toast.success("Status updated succesfully")
    getAllCredits();
  } catch (error) {
    toast.error(error.title)
  }
};
  const columns = [
      {
        title: "Name",  dataIndex: "name",
      },
      {
        title: "Amount",dataIndex: "amount",
      },
      {
        title:<Tooltip title={"Number Reputation "}> NR Price </Tooltip> ,dataIndex: "number_reputation",
      },
      {
        // title: "Robo Killer Price",dataIndex: "robo_killer",
        title:<Tooltip title={"Caller ID Rotator"}> CIR </Tooltip> ,dataIndex: "caller_id_generation",
        render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text == true ? 'True' : "False"}</div>,
      },
      {
        title: "Proxy Price",dataIndex: "proxy",
      },
      {
        title:<Tooltip title={"local Number"}> LN Price </Tooltip>, dataIndex: "local_number",
      },
      {
        title:<Tooltip title={"Incoming Price Per Minute For Local number "}> IP/Min for LN </Tooltip>, dataIndex: "incoming_cost_per_minute_for_local",
      },
      {
        title: <Tooltip title={"Toll Free Number"}> TFN Price </Tooltip>, dataIndex: "toll_free_number",
      },
      {
        title:<Tooltip title={"Incoming Price Per Minute For Toll Free number"}> IP/Min For TFN</Tooltip>, dataIndex: "incoming_cost_per_minute_for_toll_free",
      },
      {
        title: <Tooltip title={"Locked in Period"}> LIP </Tooltip>, dataIndex: "monthly_commitment",
      },
      {
        title:<Tooltip title={"Incoming Price"}> IP/Min</Tooltip>, dataIndex: "incoming_cost_per_minute",
      },
      {
        title: <Tooltip title={"Bring your own carrier"}> BYOC Price </Tooltip>,dataIndex: "bring_own_carrier",
      },
      {
        title: "Change IP",dataIndex: "auto_change_ip",
        render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text == true ? 'True' : "False"}</div>,
       
      },
      {
        title: "Auto Change IP Time", dataIndex: "auto_change_ip_time",render: text => (
          text === "" ? 'N/A' : text
      ),
      },
      {
        title: "Custom",dataIndex: "custom",
        render: text => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text == true ? 'True' : "False"}</div>,
       
      },
    {title:"Action",
    render: (row) => 
    (
      <>
      <div className="flex items-center gap-1">
    <div className="flex items-center gap-1">
                   <Switch
                    checked={row?.state}
                    onChange={(value) => handleToggle(row._id, value)} 
                style={{ border: "1px solid lightgrey" }} />
    </div>
    <div onClick={() => { setAddPackage(true); setIsEdit(true); const data=credits.filter((item)=>item._id==row._id)
      setDataEdit(data)
    }}><EditOutlined /></div>
    </div>
    </>
  ),}
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <Text className="text-2xl font-bold text-white">Packages</Text>
        <div className="flex items-center gap-4">
        <Button onClick={() => setAddBalance(true)} className="bg-primary text-white flex items-center custom-ant-btn" icon={<FaPlus block />}>
            Add Balance
          </Button>
          <Button onClick={() => setAddCredit(true)} className="bg-primary text-white flex items-center custom-ant-btn" icon={<FaPlus block />}>
            Assign Package
          </Button>
          {addCreditPermission && (
            <Button onClick={() => setAddPackage(true)} className="bg-primary text-white flex items-center custom-ant-btn" icon={<FaPlus  block />}>
              Add Package
            </Button>
          )}
        </div>
      </div>
      <div className="mt-3 bg-card-bg p-3">
        <div className="flex justify-end mb-3">
          <Input className="custom-input w-52" value={searchValue} onChange={filterCredits} size="middle" placeholder="Filter groups" />
        </div>
        <Table className="custom-table" loading={loading} columns={columns}  dataSource={searchValue ? filteredCredits : credits} pagination={false}  scroll={{ x: 'max-content' }}/>
      </div>
      <AddPackage data={dataEdit} open={addPackage} isEdit={isEdit} setIsEdit={setIsEdit
      } onClose={() => setAddPackage(false)} onSuccess={getAllCredits} />
      <AddCredit credits={credits} users={users} open={addCredit} onClose={() => setAddCredit(false)} onSuccess={getAllCredits} />
      <AddBalance credits={balance} users={users} open={addBalance} onClose={() => setAddBalance(false)} onSuccess={getAllCredits} />

    </>
  );
};

export default Credits;
