import React, { useEffect, useRef, useState } from "react";
import { Spin,Breadcrumb} from "antd";

import Chart from "chart.js/auto"
import { color } from "chart.js/helpers";
import { toast } from "react-toastify";
 import axios from "../../api/axios";
const Dashboard = () => {
//   const chartRef=useRef(null)
//   const chartInstance=useRef(null)
const [loading, setLoading] = useState(false);
const [overallData,setOverallData]=useState([])
useEffect(()=>{
  // if(chartInstance.current)
  // {
  //   chartInstance.current.destroy()
  // }
  // const myChartRef=chartRef.current.getContext('2d');

  // chartInstance.current=new Chart(myChartRef,{
  //   type:"pie",
  //   data : {
  //     labels: ['Spam','All','Clean' ],
  //     datasets: [{
  //       // label: 'My First Dataset',
  //       data: [300, 50, 100],
  //       backgroundColor: [
  //         'rgb(220,20,60)',
  //         'rgb(54, 162, 235)',
  //         'rgb(34,139,34)'
  //       ],
  //       hoverOffset: 4
  //     }]
  //   }
  // }) 
// return ()=>{
//   if(chartInstance.current)
//   {
//     chartInstance.current.destroy()
//   }
// }
getOverAllData();

},[])

const getOverAllData=async()=>{
  setLoading(true);
  try {
      const response = await axios.get("/group/count");
      setOverallData(
          response.data?.data
          // .map((e) => ({ ...e, key: e._id }))
      );
  } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
  }
  setLoading(false);
}
if (loading) return <Spin  className="custom-spin"/>;

  return(
    <>
    <Breadcrumb className="text-2xl font-bold"
    items={[
      {
        title: 'Dashboard',
      },
    ]}
  />
<div className="flex justify-between p-4 white-text">
      <div className="w-1/3 bg-card-bg shadow-lg rounded-lg p-4 m-2">
        <div className="text-3xl font-bold" style={{ color: 'green' }}>{overallData?.overallNonBlackListed}</div>
        <div className="text-sm text-gray-500">Clean</div>
      </div>
      <div className="w-1/3  bg-card-bg  shadow-lg rounded-lg p-4 m-2">
        <div className="text-3xl font-bold" style={{ color: 'red' }}>{overallData?.overallBlackListed}</div>
        <div className="text-sm text-gray-500">Spam</div>
      </div>
      <div className="w-1/3 bg-card-bg shadow-lg rounded-lg p-4 m-2">
        <div className="text-3xl font-bold" style={{color:"#3399CC"}}>{overallData?.overallTotalCount}</div>
        <div className="text-sm text-gray-500">Total count</div>
      </div>
    
    </div>
      {/* <div style={{width:"250px" ,height:"250px"}}>
      <canvas ref={chartRef}  />
    </div> */}
    </>
);
};

export default Dashboard;
