import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Typography, Table, Dropdown, Button, Spin, Input, Pagination, Tooltip, Menu, Modal, Switch, Row, Col, Checkbox } from "antd";
import { DownloadOutlined, UploadOutlined, PlaySquareOutlined, PhoneOutlined, DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import GroupService from "../../services/groupService";
import { FaPlus } from "react-icons/fa";
import AddGroup from "./addGroup";
import ImportNumbers from "./importNumbers";
import UserService from "../../services/userService";
import { GrLinkTop } from "react-icons/gr";
import { storedKeys } from "../../utils/storedKeys";
import moment from "moment-timezone";
// import { useLoading } from "../../context/LoadingContext";
import { useRowLoading } from "../../context/LoadingContext";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import CreditService from "../../services/creditService";
import { date } from "yup";
import PaginationCommon from "../../components/PaginationCommon";
import logo from "../../images/rovoo.png"
const { Text } = Typography;
const Groups = () => {
  // const { loading, setLoading } = useLoading();
  const { rowLoadingStates, setRowLoading } = useRowLoading();
  const createGroupPremission = UserService.getPermission("createGroup"),
    creatBulkNumberInGroup = UserService.getPermission("creatBulkNumberInGroup");
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [addGroup, setAddGroup] = useState(false);
  const [importNums, setImportNums] = useState(false);
  const [groupId, setGroupId] = useState([])
  const [users, setUsers] = useState([]);
  const [viewNumbers, setViewNumbers] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState();
  const [showpagination, setShowPagination] = useState(true);
  const [current, setCurrent] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [hasRun, setHasRun] = useState(false);
  const [userCredits, setUserCredits] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState('')
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const [modalIsVisble, setModalIsVisibile] = useState(false)
  const [rowLoadingStateId, setRowLoadingStateId] = useState('')
  const [toggle, setToggle] = useState(false)
  const [userCardAndCreditData, setUserCardAndCredit] = useState([])
  const [processedGroups, setProcessedGroups] = useState([]);
  useEffect(() => {
    if (auth?._id)
      getUserCredits(auth?._id)
  }, [auth?._id]);
  useEffect(() => {
    getAllUsers();

  }, []);
  useEffect(() => {
    getCreditCount()
  }, [auth, setAuth])
  const getCreditCount = async () => {
    try {
      const response = await axios.get(`/credit/credits-count`)
      setUserCardAndCredit(
        response.data?.data
      );
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }

  }
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      getAllGroups(offSet, limit, response)
      setUserCredits(response.data?.data.userCredits);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  useEffect(() => {
    setProcessedGroups(prev => groups.filter(g => g.state === 'Running').map(g => g._id));

    groups.forEach(group => {
      if (group.state === 'Running' && !processedGroups.includes(group._id)) {
        waitForCompletion(group._id);
      }
    });
  }, [groups]);

  const getAllGroups = async (offset, limit, usercredits = null) => {
    try {
      setLoading(true)

      const response = await GroupService.all(offset, limit);
      if (usercredits && usercredits.data.data.userCredits.length === 0 && response.data.data.groups.length === 0) {
        navigate("/settings/history");
      }
      setGroups(response.data?.data.groups.map((e) => ({ ...e, key: e._id })))
      setCount(response.data.data.total_count)
      setLoading(false)

    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setLoading(false)

    }
  };
  const waitForCompletion = async (rowId) => {
    setRowLoading(rowId, true);

    const checkStatus = async () => {
      try {
        const response = await GroupService.again(rowId); // Call the service
        if (response.data.data.group.state === "Completed") {
          toast.success("Numbers checked successfully");
          setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
          setRowLoading(rowId, false);
          await getAllGroups(offSet, limit);
        } else {
          setTimeout(checkStatus, 10000);
        }
      } catch (error) {
        const errorMsg = error?.response?.data?.title || error.message || "An error occurred";
        toast.error(errorMsg);
        setRowLoading(rowId, false);
      }
    };

    checkStatus();
  };
  const handleCancel = () => {
    setModalIsVisibile(false);
    setToggle(false)
  };
  const handleChangeToggle = (checked) => {
    setToggle(checked);
  };
  const handleRefresh = async (row) => {
    if (rowLoadingStates[row._id]) {
      return;
    }
    setModalIsVisibile(!modalIsVisble)
    setRowLoadingStateId(row)
  };
  const handleOk = async (row) => {
    setRowLoading(rowLoadingStateId?._id, true);
    try {
      setModalIsVisibile(false)
      await GroupService.status(rowLoadingStateId?._id,);
      await waitForCompletion(rowLoadingStateId?._id)
    }
    catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setRowLoading(rowLoadingStateId?._id, false);
    }
  }
  const getAllUsers = async () => {
    try {
      const response = await UserService.all(0, 10);
      setUsers(response.data?.data.users.map((e) => ({ name: e.name, _id: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  const filterGroups = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = groups?.filter((item) => Object.values(item).some((value) => typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())));
    setFilteredGroups(result);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",

      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios
            .delete(`/group?group_id=${id}`)
            .then(() => {
              getAllGroups(offSet, limit);
              toast.success("Group deleted successfully");
            });
        } catch (error) {
          const e = error?.response?.data?.title;
          toast.error(e || error);
        }
      }
    });
  };
  const getKeyByValue = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);
  const handleDownloadCSV = async (row, selectedTypes) => {
    try {
      const queryString = Object.keys(selectedTypes)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(selectedTypes[key])}`)
        .join("&");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/group/download?group_id=${row?._id}&${queryString}`, {
        method: "GET",
        headers: {
          "Content-Type": "text/csv",
          Authorization: JSON.parse(localStorage.getItem(storedKeys.accessToken)),
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${row.name}.csv`);
      // _${getKeyByValue(selectedTypes, true)} this for naming the file
      document.body.appendChild(link);
      link.click();
      toast.success("CSV file downloaded successfully");
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV");

    }
  };

  const columns = [
    {
      title: "Name",
      render: (e) => (
        <div className="text-primary italic cursor-pointer flex items-center gap-2" onClick={() => navigate(`/groups/${e._id}`, { state: { group: e } })}>
          {e?.name}
          {/* <GrLinkTop /> */}
        </div>
      ),
    },
    {
      title: "Created At",
      render: (_, record) => {
        const dateObj = moment(record?.createdAt).tz("America/New_York");
        const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");
        return <span>{formattedDate == "Invalid date" ? "N/A" : formattedDate || "N/A"}</span>;
      },
    },
    { title: "Spam", dataIndex: "blackListed" },
    { title: "Clean", dataIndex: "nonBlackListed" },
    { title: "Total Count", dataIndex: "totalCount" },


  ];
  const getItems = (row) => {
    return [
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: false, is_total: true, is_black_list: false })}>All </div>, key: "0" },
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: false, is_total: false, is_black_list: true })}>Spam </div>, key: "1" },
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: true, is_total: false, is_black_list: false })}>Clean </div>, key: "2" },
    ];
  };
  if (!UserService.isAdmin()) {
    columns.push({
      title: "Action",
      render: (row) =>
      (
        <div className="flex items-center gap-1">
          <Tooltip title={"Upload Numbers"}>
            <Button
              disabled={rowLoadingStates[row._id]}
              onClick={() => {
                setImportNums(true);
                setGroupId(row?._id);
              }}
              className={` ${rowLoadingStates[row._id] ? '' : 'bg-primary text-white border-none hover-none'}`}
              style={{
                backgroundColor: rowLoadingStates[row._id] ? '#ccc' : '',
                color: rowLoadingStates[row._id] ? '#666' : '',
              }}
              icon={<UploadOutlined />}>

            </Button>
          </Tooltip>
          <Tooltip title={"Check Numbers"}>
            <Button
              className={` ${rowLoadingStates[row._id] || row?.totalCount == 0 ? '' : 'bg-primary text-white border-none hover-none'}`}
              style={{
                backgroundColor: rowLoadingStates[row._id] || row?.totalCount == 0 ? '#ccc' : '',
                color: rowLoadingStates[row._id] || row?.totalCount == 0 ? '#666' : '',
              }}
              disabled={row?.totalCount == 0}
              onClick={() => handleRefresh(row)}
              icon={
                rowLoadingStates[row._id] ? (
                  <Spin className="custom-spin" />
                ) : (
                  <PlaySquareOutlined />
                )
              }
            >
            </Button>
          </Tooltip>
          {/* // } */}
          <Tooltip title={"Download"}>
            <Dropdown disabled={row?.totalCount == 0 || rowLoadingStates[row._id]} overlay={<Menu className="custom-dropdown-menu" items={getItems(row)} />} trigger={['click']}>
              <Button
                disabled={row?.totalCount == 0 || rowLoadingStates[row._id]}
                className={` ${rowLoadingStates[row._id] || row?.totalCount == 0 ? '' : 'bg-primary text-white border-none hover-none'}`}
                onClick={(e) => e.preventDefault()} icon={<DownloadOutlined />}
                style={{
                  backgroundColor: rowLoadingStates[row._id] || row?.totalCount == 0 ? '#ccc' : '',
                  color: rowLoadingStates[row._id] || row?.totalCount == 0 ? '#666' : '',
                }}
              >
              </Button>
            </Dropdown>

          </Tooltip>
          <Button
            className="hover-none"
            onClick={() => handleDelete(row._id)}
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
          </Button>
        </div>
      ),
    });
  }
  const amount = userCredits && userCredits[0]?.credit?.number_reputation * rowLoadingStateId?.nonBlackListed;
  const amount1 = userCredits && userCredits[0]?.credit?.robo_killer * rowLoadingStateId?.nonBlackListed
  const remaingBalance = userCardAndCreditData?.balance - (toggle === true ? amount + amount1 : amount)
  const okButtonStyle = {
    backgroundColor: remaingBalance < 0 ? '#ccc' : '#de4300',
    color: remaingBalance < 0 ? '#666' : 'white',
    border: "none",
    borderRadius: '0.375rem',
    padding: '0.25rem 0.75rem',
    opacity: remaingBalance < 0 ? 0.6 : "",
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <Text className="text-2xl font-bold text-white">Number Reputation</Text>
        <div className="flex items-center gap-4">
          {loading && (
            <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
              <Spin size="large" className="custom-spin" />
            </div>
          )}
          {createGroupPremission && (
            <>
              <Button onClick={() => navigate("/number/status")}  style={{
                backgroundColor:  userCredits.length==0 ? '#ccc' : '',
                color:userCredits.length==0 ? '#666' : '',
              }} disabled={userCredits.length==0} className="bg-primary text-white flex items-center custom-ant-btn">Check Single Number</Button>
              <Button onClick={() => setAddGroup(true)} className="bg-primary text-white flex items-center custom-ant-btn" icon={<FaPlus />}>
                Add Group
              </Button>
              <Button 
               style={{
                backgroundColor: userCredits[0]?.credit?.caller_id_generation === false || userCredits.length==0 ? '#ccc' : '',
                color: userCredits[0]?.credit?.caller_id_generation === false || userCredits.length==0 ? '#666' : '',
              }}
              disabled={userCredits[0]?.credit?.caller_id_generation === false || userCredits.length==0}
                onClick={() => navigate("/calleridgeneration")} className="custom-ant-btn" icon={<FaPlus />}>
                Caller ID Rotator
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="mt-3  p-3">
        <div className="flex justify-end mb-3">
          <Input value={searchValue} onChange={filterGroups} size="middle" className="w-52 custom-input" placeholder="Filter groups" />
        </div>
        <Table
          className="custom-table"
          columns={columns}
          dataSource={searchValue ? filteredGroups : groups}
          pagination={false}
          rowClassName={(record) =>
            hoveredRowKey === record.key ? 'hovered-row' : ''
          }
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
          onRow={(record) => ({
            style: { backgroundColor: 'black', color: 'white', borderBottom: "#5e5555 !important" },
            onMouseEnter: () => setHoveredRowKey(record.key),
            onMouseLeave: () => setHoveredRowKey(null),
          })}
          components={{
            header: {
              cell: (props) => (
                <th
                  {...props}
                  style={{
                    backgroundColor: '#444',
                    color: 'white',
                    padding: '15px',
                    borderBottom: "2px solid #5e5555 "

                  }}
                />
              ),
            },
            body: {
              cell: (props) => (
                <td
                  {...props}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '15px',
                    borderBottom: "2px solid #5e5555 "



                  }}
                />
              ),
            },
          }}
        />
        <PaginationCommon total={count} data={groups} handleChange={getAllGroups} />
      </div>
      <AddGroup open={addGroup} onClose={() => setAddGroup(false)} onSuccess={getAllGroups} />
      <ImportNumbers groupId={groupId} open={importNums} onClose={() => setImportNums(false)} onSuccess={getAllGroups} users={users} groups={groups} />
      <Modal className="custom-modal" visible={modalIsVisble} onOk={handleOk} onCancel={handleCancel} okText="Confirm" onClose={()=>setToggle(false)}
        okButtonProps={{
          style: okButtonStyle,
          disabled: remaingBalance < 0,

        }}
        cancelButtonProps={{
          className: "bg-#1fb6ff text-white",
        }}
      >
        <div className="p-4">
          <div className=" flex justify-between align-item-center mb-4">
            <div className="flex items-center">
              <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>

              <p>&nbsp;Rockydialer Number Reputation</p>
            </div>

            <Switch checked={true} disabled style={{
              transition: 'all 0.3s',
              border: "1px solid lightgrey"
            }} />
          </div>
          {/* <div className=" flex justify-between align-item-center mb-4">
            <div className="flex items-center">
              <img
                src={logo}
                alt="Robo Killer Icon"
                className="h-6 w-6 mr-1"
                style={{ filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%)' }} // Apply the filter to make it white
              />
              <p>Robokiller Number Reputation</p>
            </div>
            <Switch style={{
              transition: 'all 0.3s',
              border: "1px solid lightgrey"
            }} value={toggle} onChange={handleChangeToggle} />
          </div> */}
          <div className="border border-border p-4 rounded-lg shadow-lg">
            <h3 className="text-md mb-4">Summary</h3>
            <table className="w-full mb-4 custom-table2">
              <thead>
                <tr>
                  <th className="text-left">Service</th>
                  <th className="text-left">Price</th>
                  <th className="text-left">Qty</th>
                  <th className="text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>Rockydialer</td>
                  <td>${userCredits && userCredits[0]?.credit?.number_reputation}</td>
                  <td>{rowLoadingStateId && rowLoadingStateId?.nonBlackListed}</td>
                  <td>{parseFloat(amount) % 1 !== 0 ? parseFloat(amount).toFixed(4) : parseFloat(amount)}</td>
                </tr>
                {toggle && (
                  <tr>
                    <td>Robokiller</td>
                    <td>${userCredits && userCredits[0]?.credit?.robo_killer}</td>
                    <td>{rowLoadingStateId?.nonBlackListed}</td>
                    <td>{parseFloat(amount1) % 1 !== 0 ? parseFloat(amount1).toFixed(4) : parseFloat(amount1)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-right">
              <span>Total Amount: ${toggle == true ? amount + amount1 : parseFloat(amount) % 1 !== 0 ? parseFloat(amount).toFixed(4) : parseFloat(amount)}</span>
            </div>
            <div className="text-right ">
              <span>Your {userCardAndCreditData?.balance < 0 ? `Balance: -$${parseFloat(userCardAndCreditData?.balance) % 1 !== 0 ? parseFloat(userCardAndCreditData?.balance).toFixed(4) : parseFloat(userCardAndCreditData?.balance) * -1}` : `Balance: $${parseFloat(userCardAndCreditData?.balance) % 1 !== 0 ? parseFloat(userCardAndCreditData?.balance).toFixed(4) : parseFloat(userCardAndCreditData?.balance)}`}</span>
            </div>
            <div className="text-right  ">
              <span>Remaining Balance: {remaingBalance < 0 ? `-$${parseFloat(remaingBalance) % 1 !== 0 ? parseFloat(remaingBalance).toFixed(4) * -1 : parseFloat(remaingBalance) * -1}` : `$${parseFloat(remaingBalance) % 1 !== 0 ? parseFloat(remaingBalance).toFixed(4) : parseFloat(remaingBalance)}`}</span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Groups;
