import React, { useState, useEffect } from "react";
import {
  //   DollarCircleOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Table,
  Card,
  Button,
  Spin,
  Input,
  Modal,
  // Select,
  Checkbox,
  Switch,
  Tooltip,
  Flex,
} from "antd";
import { ApiCall } from "../../../../utils/CommonFunctions";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { redirect, useNavigate } from "react-router-dom";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useBalance } from '../../../../components/BalanceContext';
import { PiScales } from "react-icons/pi";
import PayPalButton from "../../../../components/PayPalButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CreditService from "../../../../services/creditService";
import Select from 'react-select';

// import AddFund from "./addFund";
const { Text } = Typography;
export default function Balance({ handleTabChange }) {
  const { auth, setAuth } = useAuth();
  const { updateBalance } = useBalance();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblePlan, setIsModalVisiblePlan] = useState(false);
  const [type, setType] = useState("");
  const [cardData, setCardData] = useState([]);
  const [addCard, setAddCard] = useState(false);
  const [buyCredit, setBuyCredit] = useState(false);
  const [amount, setAmount] = useState("");
  const [paymentType,setPaymentType]=useState("")
  const [orderID,setOrderID]=useState("");

  const [userCredits, setUserCredits] = useState(null);
  const [autoRecharge,setAutoRecharge]=useState('')
  const [rechargeBelow,setRechargeBelow]=useState({ value: '', label: '' })
  const [rechargeAmount,setRechargeAmount]=useState({ value: '', label: '' })
  const handleChange = (value) => {
    setAmount(value);
  };

  const [loading, setLoading] = useState(false);
  const [loadingFull, setLoadingFull] = useState(false);

  useEffect(() => {
    getUserProfile();
  }, []);
  useEffect(() => {
    if (auth?._id) {
      getUserCredits(auth._id);
    }
  }, [auth?._id]);
  
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      setUserCredits(response.data?.data?.userCredits[0]?.credit);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/user/profile");
      setCardData(
        response.data?.data
        // .map((e) => ({ ...e, key: e._id }))
      );
      setAutoRecharge(response.data?.data.auto_recharge)
      setRechargeBelow({value:response.data?.data?.recharge_below,label:`$${response.data?.data?.recharge_below}`})
      setRechargeAmount({value:response.data?.data?.recharge_amount,label:`$${response.data?.data?.recharge_amount}`})
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };
const handleOpenModalPlan= () => {
  setIsModalVisiblePlan(!isModalVisiblePlan);
};
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setAmount("")
    setSelectPaymentType("")

  };
 const handleSwitchAutoChange=async(checked)=>{
  if(checked == false)
    {
      await ApiCall(
        "patch",
        "user/rechrage_setting",
        {auto_recharge:false,recharge_below:parseInt(0),recharge_amount:parseInt(0)},
        "Balance"
      ).then((res)=>{
        setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
      })
    }
 setAutoRecharge(checked)
 setRechargeBelow('')
 setRechargeAmount('')
 }
 const handleRechargeBelow=(checked)=>{
  setRechargeBelow({ value: checked.value, label: checked.label })
  }
  const handleRechargeAmount=(checked)=>{

    setRechargeAmount({ value: checked.value, label: checked.label })
    }
    const handleRechargeUpdate =async()=>{
    if(rechargeBelow == '')
      {
        toast.error("Recharge Below is required")
        return
      }
    if(rechargeAmount == '')
      {
        toast.error("Recharge amount is required")
        return
      }

      await ApiCall(
        "patch",
        "user/rechrage_setting",
        {auto_recharge:autoRecharge,recharge_below:parseInt(rechargeBelow.value),recharge_amount:parseInt(rechargeAmount.value)},
        "Balance"
      ).then((res)=>{
        setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
      })
    }
  const handleAddFund = async (e) => {
    e.preventDefault();
    if(!selectedPaymentType)
      {
        toast.error("Payment type is required")
        return
      }
    if(!amount)
    {
      toast.error("Amount is required")
      return
    }
    try {
      setLoading(true)
      const addField = {
        amount: parseFloat(amount),
      };
      const response = await axios.post("/credit/fund", addField).then((res) => {
        toast.success("Balance added successfully");
        getUserProfile();
        const updateBalance=res?.data?.data?.balance;
        const updatedAuth={
          ...auth,
          balance:updateBalance
        }
        setAuth(updatedAuth)
        handleCloseModal();
        setLoading(false)
      });
    } catch (error) {
      if (error?.response?.data?.code === 4007) {
        toast.error(error?.response?.data?.title);
        navigate("/settings/billingSetting");
        handleTabChange("2");
        handleCloseModal();
        setLoading(false)
        return;
      }
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setLoading(false)
    }
  };
 
 
  const onCompete = async(details) => {
    setLoadingFull(true)
    try{
      await ApiCall (
        "post",
        "credit/balance/paypal",
        {order_id:details?.orderID},
        "Amout",
        null
      ).then((res)=>{
    getUserProfile();
      setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
          handleCloseModal();
      })
  
    }
    catch(error)
    {
      const e = error?.response?.data?.title;
        toast.error(e || error);
    }finally{
      setLoadingFull(false)
    }
   
  };
  const [selectedPaymentType,setSelectPaymentType]=useState("")
  const handleCardChange=(e,selectedCard)=>{
    e.preventDefault();
setSelectPaymentType(selectedCard)
  }
  const sn=process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const [showPayPalButton, setShowPayPalButton] = useState(false);

  const handleValidationAndShowButton = () => {
    if (!amount) {
      toast.error('Amount cannot be empty');
      setShowPayPalButton(false);
    } else {
      setShowPayPalButton(true);
    }
  };

  // Call this function when the payment type is selected or amount changes
  React.useEffect(() => {
    if (selectedPaymentType === "paypal") {
      handleValidationAndShowButton();
    }
  }, [selectedPaymentType, amount]);
    const balance = cardData?.balance?.toString();
  const formattedBalance = balance?.includes('.')
  ? balance?.replace(/(\.\d{2})\d+$/, '$1')
  : balance;
  const getFormContent = () => {
    // if (type !== "manual")
    return (
    
      <form onSubmit={handleAddFund}>
          {loadingFull && (
        <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <Spin size="large"  className="custom-spin"/>
        </div>
    )}
        <p className="ms-20 mb-4">
          Current Balance  <Tooltip title={"Select a method to add funds to your balance. You can choose to use a credit card or PayPal."}>
              <QuestionCircleOutlined />
              </Tooltip>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {formattedBalance < 0 ? `-$${formattedBalance*-1}`:`$${formattedBalance}`}
        </p>
        <div>
          <Input
          className="custom-input"
            type="number"
            name="amount"
            placeholder="Amount"
            style={{
              width: "50%",
              marginBottom: "1rem",
              // margin: "2rem",
              marginLeft: "5rem",
            }}
            value={amount}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        {
          amount != "" && 
          <div className="ms-20 mb-4 ">
          {/* <button
                key={"1"}
                className={"px-3 py-1 mx-2 rounded-md"}
                onClick={(e) => handleCardChange(e,"paypal")}
                style={{
                  border: selectedPaymentType == "paypal" ? "2px solid #de4300": '1px solid #d9d9d9'
                }}
              >
               <i className="fab fa-paypal me-2" aria-hidden="true"></i>
                Paypal
              </button> */}
              <button
                key={"2"}
                className={"px-3 py-1 mx-2 rounded-md"}
                onClick={(e) => handleCardChange(e,"credit card")}
  
                style={{
                  border: selectedPaymentType == "credit card" ? "2px solid #de4300": '1px solid #d9d9d9'
                }}
              >
                <i className="fa fa-credit-card-alt me-2" aria-hidden="true"></i>
                Credit Card
              </button>
              
              </div>
        }
       
       
        {/* <div>
          <Select
            value={type}
            onChange={(value) => setType(value)}
            style={{
              width: "50%",
              marginBottom: "1rem",
              // margin: "2rem",
              marginLeft: "5rem",
            }}
            placeholder="Select a card"
          >
            <Select.Option value="" disabled>
              Select a card
            </Select.Option>
            {cardData.map((item) => (
              <Select.Option key={item._id} value={item._id}>
                {item.card_number}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Checkbox
            style={{
              marginLeft: "5rem",
            }}
            onChange={() => setAddCard((prev) => !prev)}
          >
            Add Card
          </Checkbox>
        </div> */}
        {/* {addCard && <CardElement className="StripeElement mt-5" />} */}
        {
          selectedPaymentType == "credit card" ? (<>
          <div className="flex justify-end mt-5 mb-2">
          <button
            type="button"
            className="border-primary border-1 text-primary rounded-md p-1 px-3"
            onClick={handleCloseModal}
          >
            Cancel
          </button>{" "}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-1 px-3 custom-ant-btn"
          // disabled={!stripe || !elements || loading}
          >
            {loading && <Spin size="small" className="custom-spin mr-3" />}
            Add
          </button>
        </div>
          </>)
 : amount!= "" && selectedPaymentType=="paypal" ?   (
 <>
  <PayPalScriptProvider  options={{ "client-id": "Abv3dNCnDOJcM-w8UOVXj74VTtmhEQ_Car4WpladD3PQC03hJrICVJTXhEULvIL763Vt1191HIJhuoU" }}>
      <div className="App">
        <PayPalButton  amount={amount} onCompete={onCompete} />
      </div>
    </PayPalScriptProvider>
 </> )  : null       }
        
      </form>
    );
  };
  const getFormContentPlan = () => {
    // if (type !== "manual")
    return (
      <div className="credit-details">
        <div className="credit-row">
          <p className="credit-title">Package</p>
          <p>{userCredits?.name}</p>
        </div>
        <div className="credit-row">
          <p className="credit-title">Billed</p>
          <p>Monthly</p>
        </div>
        <div className="credit-row">
          <p className="credit-title">Package Price</p>
          <p>${userCredits?.amount}</p>
        </div>
        <div className="credit-row">
                <p className="credit-title ">Number Reputation Cost</p>
                <p>${userCredits?.number_reputation}/DIP</p>
              </div>
              <div className="credit-row">
          {/* <p className="credit-title">Robo Killer Cost</p>
          <p>${userCredits?.robo_killer}</p> */}
          <p className="credit-title">Caller ID Rotator</p>
          <p>{userCredits?.caller_id_generation == true ? 'Included' : 'Not Included'}</p>
        </div>
              <div className="credit-row">
                <p className="credit-title border-bottom-dotted"> <Tooltip title={`Incoming cost: $${userCredits?.incoming_cost_per_minute_for_toll_free}/minute`}>Toll Free Number Cost</Tooltip></p>
                <p>${userCredits?.toll_free_number}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title border-bottom-dotted"> <Tooltip title={`Incoming cost: $${userCredits?.incoming_cost_per_minute_for_local}/minute`}>Local Number Cost </Tooltip></p>
                <p>${userCredits?.local_number}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Locked in Period</p>
                <p>{userCredits?.monthly_commitment === 1 ? "1 Month" : `${userCredits?.monthly_commitment} Months`}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Proxy Cost</p>
                <p>${userCredits?.proxy}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Other Carrier Cost</p>
                <p>${userCredits?.bring_own_carrier}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Auto Check Proxy IP</p>
                <p>{userCredits?.auto_change_ip == true ? "Included" : "Not Included"}</p>
              </div>
        <div className="credit-row">
          <p className="credit-title">Check Proxy IP</p>
          <p>{userCredits?.auto_change_ip == true ? `Every ${userCredits?.auto_change_ip_time}Hours` : 'Not Included'}</p>
        </div>
  
      </div>
    );
  };
  return (
    <>
      <div className="mt-3  p-3 " style={{ height: "17rem", backgroundColor:"#1e1d1d" }}>
        <p className="font-bold white-text mb-2">
          <CreditCardOutlined />
          &nbsp; Account Balance
        </p>
        <hr
          style={{
            backgroundColor: "#5e5555",
            height: "1px",
            border: "none",
          }}
        ></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            marginLeft:"18rem",
            marginTop: "4.5rem",
          }}
        >
          <p
          //  className="p-20 "
          style={{ color: "white" }}
          >
            Balance &nbsp;
            {/* <Tooltip title={"Select a method to add funds to your balance. You can choose to use a credit card or PayPal."}>
              <QuestionCircleOutlined />
              </Tooltip> */}
            &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            {loading ? <Spin size="small" className="custom-spin mr-3" /> : `${formattedBalance < 0 ? `-$${formattedBalance*-1}`:`$${formattedBalance}`}`}
          </p>
          <div>
            <Button
              className="bg-primary text-white mt-2 custom-ant-btn"
              // style={{ left: "34rem", position: "absolute", bottom: "15rem" }}
              style={{border:"none"}}
              onClick={handleOpenModal}
            //   onClick={() => setBuyCredit()}
            >
              Add Fund
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-3 white-text p-3 " style={{ height: "17rem" , backgroundColor:"#1e1d1d" }}>
        <p className="font-bold mb-2">
          <CreditCardOutlined />
          &nbsp; Plan
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            height: "1px",
            border: "none",
          }}
        ></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            marginLeft:"18rem",
            marginTop: "4.5rem",
          }}
        >
          <p
          //  className="p-20 "
          // style={{ paddingLeft: "17rem" }}
          
          >
            Current Plan &nbsp;
            {/* <Tooltip title={"View the details of your current subscription plan, including its name and key features."}>
              <QuestionCircleOutlined />
              </Tooltip> */}
            &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            {loading ? <Spin size="small" className="custom-spin mr-3" /> : userCredits ? userCredits?.name : "N/A"}
          </p>
          {
            userCredits && 
            <>
             <div>
            <Button
              className="bg-primary text-white mt-2 custom-ant-btn"
              // style={{ left: "34rem", position: "absolute", bottom: "15rem" }}
              style={{border:"none"}}

              onClick={handleOpenModalPlan}
            //   onClick={() => setBuyCredit()}
            >
              View Current Package
            </Button>
          </div>
            </>
          }
         
        </div>
      </div>
      <div className="mt-3 p-3 " style={{ height: "19rem", backgroundColor:"#1e1d1d"  }}>
        <p className="font-bold white-text mb-2">
          <CreditCardOutlined />
          &nbsp; Recharge Settings
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            height: "1px",
            border: "none",
          }}
        ></hr>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            marginLeft:"18rem",
            marginTop: "4.5rem",
          }} className="white-text"
        >
          <p className="mb-4">
            Auto Recharge &nbsp;
            <Tooltip title={"Enable this option to automatically recharge your balance when it falls below a specified amount. This ensures that your account always has sufficient funds."}>
              <QuestionCircleOutlined />
              </Tooltip>
            &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
            <Switch checked={autoRecharge} onChange={handleSwitchAutoChange} style={{ border: "1px solid lightgrey" }}></Switch>
          </p>
          {
            autoRecharge &&
            <>
              <p className="mb-4" style={{display:"flex" ,alignItems: "center" }}>
              Recharge Below &nbsp;
              <Tooltip title={"Set the balance threshold. When your account balance falls below this amount, the auto-recharge will trigger to top up your balance."}>
                <QuestionCircleOutlined />
                </Tooltip>
              &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
              {/* {loading ? <Spin size="small" className="mr-3" /> : formattedBalance} */}
              <div style={{width:"15rem"}}>
              <Select
                  style={{ width: "20rem !important" }}
                  classNamePrefix="custom-select"
                  placeholder="Please select recharge below"
                  value={rechargeBelow}
                  onChange={handleRechargeBelow}
                  menuPlacement="top"
                  options={[
                    {
                    value:'10',
                    label:'$10'
                  },
                  {
                    value:"25",
                    label:'$25'
                  },
                  {
                    value:"50",
                    label:'$50'
                  },
                  {
                    value:"75",
                    label:'$75'
                  },
                  {
                    value:"100",
                    label:'$100'
                  },
                  {
                    value:"150",
                    label:'$150'
                  },
                  {
                    value:"250",
                    label:'$250'
                  },
                  {
                    value:"500",
                    label:'$500'
                  },
                  {
                    value:"1000",
                    label:'$1000'
                  }
  
                ]}
                >
                </Select>
                </div>
            </p>
            <p style={{display:"flex" ,alignItems: "center" }}>
              Recharge Amount &nbsp;
              <Tooltip title={"Specify the amount to be added to your balance each time the auto-recharge is triggered."}>
                <QuestionCircleOutlined />
                </Tooltip>
              &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div style={{width:"15rem"}}>
              <Select
              // dropdownStyle={{backgroundColor:"#de4300", border:"1px solid green" }}
                  style={{ width: "20%" }}
                  classNamePrefix="custom-select"
                  placeholder="Please select recharge below"
                  value={rechargeAmount}
                  onChange={handleRechargeAmount}
                  menuPlacement="top"
                  options={[
                    {
                    value:'10',
                    label:'$10'
                  },
                  {
                    value:"25",
                    label:'$25'
                  },
                  {
                    value:"50",
                    label:'$50'
                  },
                  {
                    value:"75",
                    label:'$75'
                  },
                  {
                    value:"100",
                    label:'$100'
                  },
                  {
                    value:"150",
                    label:'$150'
                  },
                  {
                    value:"250",
                    label:'$250'
                  },
                  {
                    value:"500",
                    label:'$500'
                  },
                  {
                    value:"1000",
                    label:'$1000'
                  },
                  {
                    value:"2000",
                    label:'$2000'
                  },
                  {
                    value:"2500",
                    label:'$2500'
                  },
                  {
                    value:"5000",
                    label:'$5000'
                  },
                  {
                    value:"10000",
                    label:'$10000'
                  },
  
                ]}
                >
                </Select>
                </div>
            </p>
         
            <div>
            
            <Button
              className="bg-primary text-white mt-2 custom-ant-btn"
              // style={{ left: "34rem", position: "absolute", bottom: "15rem" }}
              style={{border:"none"}}

              onClick={handleRechargeUpdate}
            //   onClick={() => setBuyCredit()}
            >
              Update
            </Button>
          </div>
            </>
          }
        
         
        </div>
        
      </div>
      <div className="flex justify-end mb-3"></div>
      <Modal
        title="Add Fund"
        visible={isModalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        okButtonProps={{ className: "bg-primary" }}
        okText="Save"
        footer={<></>}
        className="custom-modal"
      >
        <br />
        {getFormContent()}
      </Modal>
      <Modal
        title="Current Pricing"
        visible={isModalVisiblePlan}
        onOk={handleOpenModalPlan}
        onCancel={handleOpenModalPlan}
        okButtonProps={{ className: "bg-primary" }}
        okText="Save"
        footer={<></>}
        className="custom-modal"
      >
        <br />
        {getFormContentPlan()}
      </Modal>
    </>
  );
}
