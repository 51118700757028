import React, { useEffect, useRef, useState } from "react";
import { Select, Switch, Input, Button, message, Tooltip, Typography, Row, Col, Tag } from "antd";
import { CopyOutlined, CreditCardOutlined, LinkOutlined } from "@ant-design/icons";
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { QuestionCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import Breadcrumb from "../../../../components/BreadCrumbs";
import Header from "../../../../components/BreadCrumbs";
import { all } from "axios";
import { values } from "lodash";
const { Option } = Select;
const { Text } = Typography;
export default function UrlGeneration() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const { auth, setAuth } = useAuth();
  const apiKey = auth?.api_key;
  const [cidGroup, setCidGroup] = useState([])
  const [loading, setLoading] = useState(false);
  const [includePhoneNumber, setIncludePhoneNumber] = useState(false);
  const [matchStatus, setMatchStatus] = useState(false);
  const [isBlackList, setIsBlackList] = useState(false);
  const [randomOrSeries, setRandomOrSeries] = useState(false);
  const [includeCidGroup, setIncludeCidGroup] = useState(false);
  const [cidFormat, setCidFormat] = useState(0);
  const [selectedCIDGroups, setSelectedCIDGroups] = useState([]);
  const [selectedBlacklist, setSelectedBlacklist] = useState([]);
  const initialValues = {
    from: '',
    to: '',
  }
  const [form, setForm] = useState(initialValues);
  const handleInputChange = (e) => {
    setUuid('')
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [uuid, setUuid] = useState('');
  const generateUUID = () => {
    const newUuid = uuidv4();
    setUuid(newUuid);
  };
  // const inputRef = useRef(null);
  // const inputRef1 = useRef(null);

  const handleSwitchChange = (checked) => {
    setUuid('')
    setIncludePhoneNumber(checked);
    setMatchStatus(checked);

    if (!checked) {
      setMatchStatus(false);
    }
  };

  const handleSwitchChangematchstatus = (checked) => {
    setUuid('')
    setMatchStatus(checked);
    setIncludePhoneNumber(checked);

    if (checked) {
      setIncludePhoneNumber(true);
    }
  };
  const handleSwitchChangeisBlackList = (checked) => {
    setUuid('')
    setIsBlackList(checked);
  };
  const handleSwitchChangeRandomOrSeries = (checked) => {
    setUuid('')
    setRandomOrSeries(checked);
  };
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);

  const handleCopy = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');
      message.success('Copied to clipboard!');
    }
  };

  const handleCopy1 = () => {
    const inputElement = inputRef1.current;
    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');
      message.success('Copied to clipboard!');
    }
  };
  useEffect(() => {
    getCidGroup();
  }, []);
  const getCidGroup = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/group", { params: { offset: 0, limit: -1 } });
      setCidGroup(
        response?.data?.data?.groups
        // .map((e) => ({ ...e, key: e._id }))
      );
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };

  const handleCIDGroupChange = (selectedValues) => {
    setUuid('')
    if (selectedValues) {
      setIncludeCidGroup(true)
      setSelectedCIDGroups(selectedValues);
    }
    else {
      setIncludeCidGroup(false)
    }
  };
  const handleChange = (values) => {
    setUuid('')
    setCidFormat(values)

  }
  const handleChangeBlackListed = (values) => {
    setUuid('')
    setSelectedBlacklist(values)
  }
  function createDynamicObject(arr) {
    const result = []
    arr.forEach((range, index) => {
      if (range == 'N/A') {
        result.push(`0.0`)
      }
      else {
        const [from, to] = range.split('-').map(Number);
        result.push(`${from}.${to}`);
      }
    })
    return result;
  }

  const result = createDynamicObject(selectedBlacklist);

  const options = [
    { value: 'N/A' }, { value: '1-10' }, { value: '11-20' }, { value: '21-30' }, { value: '31-40' }, { value: '41-50' }, { value: '51-59' }, { value: '60-70' }, { value: '71-80' }, { value: '81-90' }, { value: '91-100' },
  ];
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={'white'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
          backgroundColor: value == '1-10' || value == '11-20' || value == '21-30' || value == '31-40' ? '#28a745' : value == '41-50' || value == '51-59' ? '#f59e0b' : value == 'N/A' ? '#5e5555' : '#dc3545'
        }}
      >
        {label}
      </Tag>
    );
  };
  return (
    <>
      <Header mainPageName={"Number Reputation"} subPageName={"Caller ID Rotator"} />
      <div className="mt-3 bg-card-bg p-3 white-text" style={{ height: "38rem" }}>
        <p className="font-bold mb-2">
          <LinkOutlined />
          &nbsp; Generate Url
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            height: "1px",
            border: "none",
          }}
        ></hr>
        <div style={{ padding: "20px" }}>

          <div >
            <div className="mt-4" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "6rem" }}>
              <div className="d-flex">
                <label className="font-bold mb-2 me-1">Number Reputation Group</label>

              </div>


              <Select
                className="custom-select check"
                mode="multiple"
                style={{ width: "71%" }}
                placeholder="Please select Number Reputation Group"
                value={selectedCIDGroups}
                onChange={handleCIDGroupChange}
              >
                {
                  cidGroup?.map((key) => (
                    <Option key={key?._id} value={key?._id}>{key?.name}</Option>
                  ))
                }
              </Select>
            </div>
            <div className="mt-4" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "6rem" }}>
              <div className="d-flex">
                <label className="font-bold mb-2 me-1">CID Format</label>
                <Tooltip title={"Caller ID will be return based on a selected format"}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <Select
                className="custom-select"
                style={{ width: "71%" }}
                placeholder="Please select CID Format"
                onChange={handleChange}
                defaultValue={{
                  value: 0,
                  label: '+12124567890',
                }}
                options={[
                  {
                    value: 0,
                    label: '+12124567890'
                  },
                  {
                    value: 1,
                    label: '12124567890'
                  },
                  {
                    value: 2,
                    label: '2124567890'
                  }
                ]}
              >
              </Select>
            </div>
            <div className="mt-4" style={{ display: "flex", flexDirection: "column", marginLeft: "6rem" }}>
              <div className="">
                <label className="font-bold mb-2 me-1">CID Score</label>
                <Tooltip title={"This Indicate the score level associated with this CID number."}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <Select
                className="custom-select check"
                mode="multiple"
                tagRender={tagRender}
                placeholder={'Please select CID Score range'}
                style={{
                  width: '71%',
                }}
                onChange={handleChangeBlackListed}
                options={options}
              >
              </Select>
            </div>
            <div className="mt-4" style={{ display: "flex", alignItems: "center", marginLeft: "6rem" }}>
              <label style={{ marginRight: "10px" }}>Phone Number</label>
              <Tooltip title={"You will have to pass the called number in order to match the area code."}>
                <QuestionCircleOutlined />
              </Tooltip>
              <Switch checked={includePhoneNumber}
                onChange={handleSwitchChange} className="ms-2" style={{ border: "1px solid lightgrey" }} />

            </div>
            <div className="mt-4" style={{ display: "flex", alignItems: "center", marginLeft: "6rem" }}>
              <label style={{ marginRight: "10px" }}>Match Area Code</label>
              <Tooltip title={"If 'Match Area Code' is enabled, the system will retrieve number based on the area code specified above in phone number."}>

                <QuestionCircleOutlined />
              </Tooltip>
              <Switch checked={matchStatus}
                onChange={handleSwitchChangematchstatus} className="ms-2" style={{ border: "1px solid lightgrey" }} />

            </div>
            {/* <div className="mt-4" style={{ display: "flex", alignItems: "center", marginLeft: "6rem" }}>
              <label style={{ marginRight: "10px" }}>BlackListed</label>
              <Tooltip title={"Upload Numbers"}>

              <QuestionCircleOutlined />
              </Tooltip>

              <Switch checked={isBlackList}
                onChange={handleSwitchChangeisBlackList} className="ms-2" style={{ border: "1px solid lightgrey" }} />

            </div> */}
            <div className="mt-4" style={{ display: "flex", alignItems: "center", marginLeft: "6rem" }}>
              <label style={{ marginRight: "10px" }}>Random Caller Id</label>
              <Tooltip title={"This function generates a random number. When there are multiple occurrences of numbers based on the query filter, it selects any random number from the list of those numbers."}>

                <QuestionCircleOutlined />
              </Tooltip>
              <Switch checked={randomOrSeries}
                onChange={handleSwitchChangeRandomOrSeries} className="ms-2" style={{ border: "1px solid lightgrey" }} />
              <label className="ms-2" style={{ marginRight: "10px" }}>Series Caller Id</label>
              <Tooltip title={"This feature generates a sequence of numbers. For instance, if the query filter returns multiple records, the system displays the numbers one by one in a sequence. Once the sequence ends, it restarts."}>

                <QuestionCircleOutlined />
              </Tooltip>

            </div>
            <div className="flex justify-end mb-3" style={{ marginRight: "17.5rem" }}><Button onClick={generateUUID} className="bg-primary text-white flex items-center custom-ant-btn" >Generate New Link</Button></div>
            {uuid &&
            <>
                   <label className="font-bold mb-2 me-1" style={{marginLeft:'6rem' }}>For Curl</label>

              <div style={{ display: "flex", alignItems: "center", marginBottom:"1rem" }}
              >
                <Input
                  className="custom-input"
                  ref={inputRef}
                  value={`${baseUrl}/group/random\\?api_key\\=${apiKey}\\&url_id\\=${uuid}${selectedCIDGroups.length > 0 ? `\\&cid_group_ids\\=${selectedCIDGroups.join(",")}` : ""}${selectedBlacklist.length > 0 ? `\\&score\\=${result}` : ''}${includePhoneNumber ? "\\&phone_number\\={phone_number}" : ""}${matchStatus ? "\\&match_areacode\\=true" : ""}${`\\&cid_format\\=${cidFormat}`}\\&series\\=${randomOrSeries}`}
                  readOnly
                  style={{ marginLeft: "6rem", marginRight: "1rem", overflowX: "auto", }}
                />
                <Button
                  className="white-text"
                  icon={<CopyOutlined />}
                  onClick={handleCopy}
                  style={{
                    border: "1px solid #5e5555",
                    borderRadius: "5px",
                    marginRight: "9rem",
                    backgroundColor: "transparent",
                    color: "#fff",
                    boxShadow: "none",
                    outline: "none"
                  }}
                >
                  Copy Link
                </Button>

              </div>
              <label className="font-bold mb-2 me-1" style={{marginLeft:'6rem',}}>For http</label>
              <div style={{ display: "flex", alignItems: "center", }}>
              <Input
                className="custom-input"
                ref={inputRef1}
                value={`${baseUrl}/group/random?api_key=${apiKey}&url_id=${uuid}${selectedCIDGroups.length > 0 ? `&cid_group_ids=${selectedCIDGroups.join(",")}` : ""}${selectedBlacklist.length > 0 ? `&score=${result}` : ''}${includePhoneNumber ? "&phone_number={phone_number}" : ""}${matchStatus ? "&match_areacode=true" : ""}${`&cid_format=${cidFormat}`}&series=${randomOrSeries}`}
                readOnly
                style={{ marginLeft: "6rem", marginRight: "1rem", overflowX: "auto" }}
              />
              <Button
                className="white-text"
                icon={<CopyOutlined />}
                onClick={handleCopy1}
                style={{
                  border: "1px solid #5e5555",
                  borderRadius: "5px",
                  marginRight: "9rem",
                  backgroundColor: "transparent",
                  color: "#fff",
                  boxShadow: "none",
                  outline: "none"
                }}
              >
                Copy Link
              </Button>

            </div>
            </>
            }
          </div>
        </div>
      </div>
    </>
  )
}