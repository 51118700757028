import React, { useEffect, useState } from "react";
import { Typography, Table, Card, Button, Spin, Input,pagination, Pagination } from "antd";
// import OrderHistoryService from "../../../../services/orderHistoryService";
import CreditHisoryService from "../../../../services/creditHistoryService";
import { toast } from "react-toastify";
import moment from "moment-timezone";

const { Text } = Typography;

export default function CreditHistory() {
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page,setPage]=useState();
  const [showpagination,setShowPagination]=useState(true);
  const [current, setCurrent] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [hasRun, setHasRun] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 20,
  });
  const filterUsers = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = orderHistory?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredUsers(result);
  };
  useEffect(() => {
    getOrderHistory(0,10);
  }, []);

  const getOrderHistory = async (offset,limit) => {
    setLoading(true);
    try {
      const response = await CreditHisoryService.all(offset,limit);
      setOrderHistory(
        response.data?.data.creditsHotory.map((e) => ({ ...e, key: e._id }))
      );
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    getOrderHistory(offset, pageSize);
    setPagination({ ...pagination, current });
  };
  const handleOnChange =  (page, pageSize) => {
     getOrderHistory(page-1, pageSize)
    setPage(page)
    setPagination({ ...pagination, current: page });
    
  };
  const columns = [
    {
      title: "Date",
      render: (_, record) => {
        const dateObj = moment(record.date).tz("America/New_York");
        const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

        return <span>{formattedDate || "N/A"}</span>;
      },
    },
    {
      title: "DID Group",
      render: (_, record) => {
        {
          const GroupName = record?.group_id?.name ? `${record?.group_id?.name}` : "N/A";
          return <span>{GroupName}</span>;
        }
      },
    },
    {
      title: "DID Count",
      render: (_, record) => {
        {
          const cidCount = record?.cid_count ? `${record?.cid_count}` : "N/A";
          return <span>{cidCount}</span>;
        }
      },
    },
    {
        title: "Credits Used",
        render: (_, record) => {
          {
            const CreditUsed = record?.credits_used ? `${record?.credits_used}` : "N/A";
            return <span>{CreditUsed}</span>;
          }
        },
      },
      {
        title: "Balance Used",
        render: (_, record) => {
          {
            const chargedBalance = record?.charged_balance ? `${record?.charged_balance}` : "0";
            return <span>{chargedBalance}</span>;
          }
        },
      },
      {
        title: "Remaining Credit",
        render: (_, record) => {
          {
            const remainingTotalCredits = record?.remaining_total_credits ? `${record?.remaining_total_credits}` : "0";
            return <span>{remainingTotalCredits}</span>;
          }
        },
      },
      {
        title: "Remaining Balance",
        render: (_, record) => {
          {
            const balance = record?.remaining_balance?.toString();
            const formattedBalance = balance?.includes('.')
            ? balance?.replace(/(\.\d{2})\d+$/, '$1')
            : balance;
            const remainingBalance = formattedBalance ? `${formattedBalance}` : "0";
            return <span>{remainingBalance}</span>;
          }
        },
      },
      
  
     
    // {
    //   title: "Card Number",
    //   render: (_, record) => (
    //     <span>{record.card_id?.card_number || "N/A"}</span>
    //   ),
    // },

    // {
    //   title: "Brand",
    //   render: (_, record) => {
    //     const brand = `${record?.card_id?.brand}`;
    //     return <span>{brand == "undefined" ? "N/A" : brand || "N/A"}</span>;
    //   },
    // },
  ];

  const handleNext = () => {
    if (searchValue) {
      if (filteredUsers.length === pageSize) {
        setCurrent(current + 1);
      }
    } else {
      if (orderHistory.length === pageSize) {
        setCurrent(current + 1);
      }
    }
  };
 


  useEffect(() => {
    if (!hasRun && orderHistory && orderHistory.length> 0) {
     
      if (orderHistory.length >= 9) {
        setTotal(orderHistory?.length + 1);
      } else {
        setTotal(orderHistory?.length);
      }
      setHasRun(true);
     
    }
    if(orderHistory?.length=== 0)
    {
      setShowPagination(false)
    }
    else{
      setShowPagination(true)
    }
  }, [orderHistory, hasRun]);
  useEffect(() => {
    if (hasRun && orderHistory   && page) {
      let newTotal = 0;
      if (orderHistory.length >= 9) {
        newTotal = page * 10 + 1;
      }
       else {
        newTotal = total - 1;
      }
      if(orderHistory?.length===0)
      {
        newTotal = total - 1;
      }
      setTotal(newTotal);
    }
  }, [orderHistory, hasRun, page]);
  return (
    <>
      <div className="mt-3 bg-white p-3">
        <div className="flex justify-end mb-3">
          <Input
            value={searchValue}
            onChange={filterUsers}
            size="middle"
            className="w-52"
            placeholder="Filter Users"
          />
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={searchValue ? filteredUsers : orderHistory}
          // disabled={false}
          pagination={false}
          onChange={handleTableChange}
        />
        {
          showpagination &&
          <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <Pagination  className="" showLessItems total={total} onChange={handleOnChange} showSizeChanger={false} />
          </div>
        }
        
      </div>
    </>
  );
}
