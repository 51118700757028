// BalanceContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from "../hooks/useAuth";

const BalanceContext = createContext();

export const useBalance = () => useContext(BalanceContext);

export const BalanceProvider = ({ children }) => {
  const { auth, setAuth } = useAuth();
  const a=auth?.balance
  useEffect(() => {
    if (auth) {
      setBalance(auth.balance); // Set the balance once auth is available
    }
  }, [auth]);
  const [balance, setBalance] = useState();
  const updateBalance = (newBalance) => {
    setBalance(newBalance);
  };

  return (
    <BalanceContext.Provider value={{ balance, updateBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};
