import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

export default function TransactionHistory(
    { userId }
) {
    const [searchValue, setSearchValue] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(false);


    const filterUsers = (e) => {
        const search = e.target.value;
        setSearchValue(search);
        let result = transactionHistory?.filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(search.toLowerCase())
          )
        );
        setFilteredUsers(result);
      };
      useEffect(() => {
        getTransactionHistory(userId,0,10);
      }, [userId]);
      const getTransactionHistory = async (userId,offset,limit) => {
        setLoading(true);
        try {
          const response = await axios.get(`/credit/transactions?user_id=${userId}`,{ params: { offset, limit }})
          setTransactionHistory(
            response.data?.data.transications.map((e) => ({ ...e, key: e._id }))
          );
        } catch (error) {
          const e = error?.response?.data?.title;
          toast.error(e || error);
        }
        setLoading(false);
      };
      const columns = [
        {
          title: "Date",
          // render: (_, record) => {
          //   const dateObj = new Date(record.purchasing_date);
          //   const year = dateObj.getFullYear();
          //   const month = dateObj.getMonth() + 1; // Month is zero-based, so add 1
          //   const day = dateObj.getDate();
    
          //   const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
          //     day < 10 ? "0" + day : day
          //   }`;
          //   return <span>{formattedDate || "N/A"}</span>;
          // },
          render: (_, record) => {
            const dateObj = moment(record.purchasing_date).tz("America/New_York");
            const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");
    
            return <span>{formattedDate || "N/A"}</span>;
          },
        },
        {
          title: "Expiry Date",
          render: (_, record) => {
            const expiryDate = record.card_id
              ? `0${record?.card_id?.expiration_month}/${record?.card_id?.expiration_year}`
              : "N/A";
            return <span>{expiryDate || "N/A"}</span>;
          },
        },
        { title: "Description", dataIndex: "description" },
        {
          title: "Amount",
          render: (_, record) => {
            {
              const amount = record?.amount ? `$${record?.amount}` : "N/A";
              return <span>{amount}</span>;
            }
          },
        },
    
        {
          title: "Card Number",
          render: (_, record) => (
            <span>{record.card_id?.card_number || "N/A"}</span>
          ),
        },
    
        {
          title: "Brand",
          render: (_, record) => {
            const brand = `${record?.card_id?.brand}`;
            return <span>{brand == "undefined" ? "N/A" : brand || "N/A"}</span>;
          },
        },
      ];
    return (
        <>
            <>
      <div className="bg-white">
        <div className="flex justify-end ">
          {/* <Input
            value={searchValue}
            onChange={filterUsers}
            size="middle"
            className="w-52"
            placeholder="Filter Users"
          /> */}
        </div>
        <Table
          loading={loading}
          columns={columns}
        //   dataSource={searchValue ? filteredUsers : userProfile}
        />
      </div>
    </>
        </>
    )
}