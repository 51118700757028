import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spin, Switch, Tooltip, Typography } from "antd";
import CreditService from "../../../../services/creditService";
import { toast } from "react-toastify";
import BuyCredits from "../../../packages/buyCredit";
import UserService from "../../../../services/userService";
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const { Text } = Typography;

const Subscription = ({ handleTabChange }) => {
  const buyCreditPermission = UserService.getPermission("buyCredit");
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buyCredit, setBuyCredit] = useState(false);
  const [userCredits, setUserCredits] = useState(null);
  const [purchasedCredits, setPurchasedCredits] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [toggle, setToggle] = useState(false)
  const user_id = auth?._id;
  useEffect(() => {
    if (auth?._id) {
      getUserCredits(auth._id);
    }
  }, [auth?._id]);

  useEffect(() => {
    if (purchasedCredits.length > 0) {
      getAllCredits();
    }
    else {
      getAllCredits();
    }
  }, [purchasedCredits]);
  const getAllCredits = async () => {
    setLoading(true);
    try {
      const response = await CreditService.all(0, 100);
      const creditsData = response.data?.data.credits;

      const updatedCredits = creditsData.filter(item => {
        if (item?.state === true) {
          return true;
        }
        return userCredits.userCredits.some(usercredits => usercredits.credit._id === item._id && usercredits.is_expired !== true)
      });
      setCredits(updatedCredits)

    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleChangeToggle = (checked) => {
    setToggle(checked);
  };
  const handleToggleChange = async (creditId, checked) => {
    const addField = {
      credit_id: creditId,
      is_renewal: checked
    }
    try {
      const response = await axios.patch("/credit", addField)
      toast.success("Status updated successfully")
      getAllCredits();
      getUserCredits(auth._id);
    }
    catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }



  }
  const handleBuyCredit = async (e, creditId) => {
    e.preventDefault();
    if (purchasedCredits.some(pc => pc._id == creditId) && userCredits?.userCredits?.find(uc => uc.credit._id === creditId)?.is_expired == false) {
      Swal.fire({
        title: "Are you sure you want to unsubscribe?",
        text: "Please be aware that upon unsubscribing,  Package will be permanently lost and cannot be recovered. This action is irreversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Unsubscribe it!",
        customClass: {
          popup: 'custom-swal'
        }

      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios
              .delete("/credit", { data: { credit_id: creditId } })
              .then(() => {
                getAllCredits();
                getUserCredits(auth._id);
                toast.success("Package unsubscribed successfully");
                setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

              });
          } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
          }
        }
      });
    }
    else {
      setIsModalVisible(true);
      const creditData = credits.find(item => item._id === creditId);
      setSelectedCredit(creditData);
    }

  };
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      setUserCredits(response.data?.data);
      setPurchasedCredits(response.data?.data.userCredits?.map((item) => item?.credit))
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  const handleOk = async () => {
    setLoading(true)
    try {
      const addField = {
        // amount: parseFloat(amount),
        credit_id: selectedCredit._id,
        type: 'credit',
        is_renewal: toggle,
      };
      const ComponentApi = userCredits?.userCredits?.find(uc => uc.credit._id === selectedCredit._id)?.is_expired == true ? "/credit/renewal" : "/credit/purchase"
      const response = await axios.post(ComponentApi, addField).then((res) => {

        toast.success("Package subscribed successfully");
        setIsModalVisible(false);
        getUserCredits(auth?._id)
        setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
        setToggle(false)

      });
      setLoading(false)

    } catch (error) {
      if (error?.response?.data?.code === 4007) {
        toast.error(error?.response?.data?.title);
        navigate("/settings/billingSetting", { state: { activeTab: "2" } });
        // handleTabChange("2");
        // handleCloseModal();
        return;
      }
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setLoading(false)
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // if (loading) return <Spin  className="custom-spin"/>;
  return (
    <>
    {loading && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                        <Spin size="large" className="custom-spin" />
                    </div>
                )}
      <Text className="text-2xl font-bold">Packages</Text>
      <br />
      <br />
      {/* <Text className="text-2xl font-bold flex justify-center">
        {" "}
        You're Off to a Great Start!
      </Text> */}
      <br />
      {/* <Row gutter={[16, 16]}> */}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '10px' }}>
        {credits?.map((credit) => {
          const isCreditPurchased = UserService?.isAdmin()
            ? false
            : credit?._id === userCredits?.credit?._id;
          return (
            // <Col key={credit._id} sm={24} lg={6} >
            <div key={credit?._id} style={{ flex: '0 0 18rem', margin: '10px', minWidth: '15rem' }}>
              <Card
                style={{ minHeight: '400px', width:"18rem", border :"1px solid #5e5555"}}
                className="bg-card-bg white-text "
              >
                <div className="text-center w-full">
                  <div className="font-bold text-2xl mb-5">{credit?.name}</div>
                  {/* <div className="text-gray-600 text-xl mb-5" style={{ color: "#666" }}>Billed: Monthly</div> */}
                  {/* <div className="text-gray-600 text-xl mb-5" style={{ color: "#666" }}>Credits: {credit?.credits}</div> */}
                  <div className="text-3xl font-bold text-black mb-6" >${credit?.amount}/mo</div>
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Number Reputation Cost</span>
                      <span className="text-gray-600">${credit?.number_reputation}/DIP</span>
                    </div>
                    {/* <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Robo Killer Cost</span>
                      <span className="text-gray-600">${credit?.robo_killer}</span>
                    </div> */}
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Caller ID Rotator</span>
                      <span className="text-gray-600">{credit?.caller_id_generation == true ? 'Included' : 'Not Included'}</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600 border-bottom-dotted"><Tooltip title={`Incoming cost: $${credit?.incoming_cost_per_minute_for_toll_free == undefined ? '0' : credit?.incoming_cost_per_minute_for_toll_free}/minute`}>Toll Free Number Cost</Tooltip></span>
                      <span className="text-gray-600">${credit?.toll_free_number}/mo</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600 border-bottom-dotted"><Tooltip title={`Incoming cost: $${credit?.incoming_cost_per_minute_for_local == undefined ? '0' : credit?.incoming_cost_per_minute_for_local} /minute`}>Local Number Cost</Tooltip></span>
                      <span className="text-gray-600">${credit?.local_number}/mo</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600 "> Locked in Period </span>
                      <span className="text-gray-600">{credit?.monthly_commitment === 1 ? "1 Month" : `${credit.monthly_commitment} Months`}</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Proxy Cost</span>
                      <span className="text-gray-600">${credit?.proxy}/mo</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Other Carrier Cost</span>
                      <span className="text-gray-600">${credit?.bring_own_carrier}</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Auto Check Proxy IP</span>
                      <span className="text-gray-600">{credit?.auto_change_ip == true ? "Included" : "Not Included"}</span>
                    </div>
                    <div className="flex justify-between items-center text-gray-600 text-md mb-4">
                      <span className="text-gray-600">Check Proxy IP</span>
                      <span className="text-gray-600">{credit?.auto_change_ip == true ? `Every ${credit?.auto_change_ip_time} Hours` : 'Not Included'} </span>
                    </div>
                  </div>


                  {purchasedCredits?.some(item => item?._id == credit?._id) &&
                    userCredits?.userCredits?.find(uc => uc.credit?._id === credit?._id)?.is_expired == false && (
                      <div className="flex justify-between items-center text-gray-600 text- mb-4 ">
                        <Text className="me-2 text-white">Auto Renewal</Text>
                        <Switch
                          checked={userCredits?.userCredits?.find(uc => uc.credit._id === credit._id)?.is_renewal}
                          onChange={(checked) => handleToggleChange(credit?._id, checked)}
                          style={{ border: "1px solid lightgrey" }}
                        />
                      </div>
                    )}

                  {
                    userCredits?.userCredits?.find(uc => uc.credit?._id === credit?._id)?.is_expired &&
                    <div style={{ marginBottom: "-1.5rem" }
                    } className="flex justify-center w-full px-4 mt-3">
                      <div className="expired-label">Expired</div>
                    </div>
                  }

                  {buyCreditPermission && (
                    <Button
                      className={`  rounded-full ${purchasedCredits?.some(item => item?._id === credit?._id) && userCredits?.userCredits?.find(uc => uc?.credit?._id === credit?._id)?.is_expired == false ? 'text-white hover-none' : 'bg-primary text-white mt-10 border-none hover-none'}`}
                      // className={`${purchasedCredits.some(item => item._id === credit._id) && userCredits?.userCredits?.find(uc => uc.credit._id === credit._id)?.is_expired == false ? 'text-white' : 'bg-primary text-white mt-8'}`}
                      // style={
                      //   purchasedCredits.some(item => item._id === credit._id) && userCredits?.userCredits?.find(uc => uc.credit._id === credit._id)?.is_expired == false ?
                      //     { backgroundColor: "#df4747" } :
                      //     {}


                      // }
                      style={{
                        width: '16rem',
                        height: "35px",
                        backgroundColor: purchasedCredits?.some(item => item?._id === credit?._id) && userCredits?.userCredits?.find(uc => uc?.credit?._id === credit?._id)?.is_expired == false ? "red" : undefined,
                        border:userCredits?.userCredits?.find(uc => uc?.credit?._id === credit?._id)?.is_expired == false ? "none" : undefined
                      }}
                      onClick={(e) => handleBuyCredit(e, credit?._id)}
                    >
                      {userCredits?.userCredits?.find(uc => uc.credit?._id === credit?._id)?.is_expired == true ? "Renewal" : purchasedCredits?.length === 0 ? 'Purchase Package' : purchasedCredits?.some((item) => item?._id === credit?._id) ? 'Unsubscribe' : 'Switch Package'}
                    </Button>
                  )}
                </div>
              </Card>
              </div>
            // </Col>


          );
        })}
        </div>
      {/* </Row> */}
      <BuyCredits
        open={buyCredit}
        onClose={() => setBuyCredit(false)}
        onSuccess={() => {
          setAuth((e) => ({
            ...e,
            refreshCredits: e?.refreshCredits ? false : true,
          }));
          getUserCredits(user_id);
        }}
      />
      <Modal className="custom-modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Subscribe"
        okButtonProps={{
          className: "bg-primary text-white rounded-md p-1 px-3 hover-none",

        }}
        cancelButtonProps={{
          className: "bg-#1fb6ff text-white", // Add this line to apply the custom class to the cancel button
        }}
      >
        {selectedCredit ? (
          <>
           
            <div className="credit-details">
            {
              userCredits?.userCredits[0]?.is_expired == false &&
              <p style={{ color: "red", padding: "2px" }}>Please be aware that upon subscribing,Already subscribed Package will be permanently lost and cannot be recovered.</p>
            }
              <div className="credit-row">
                <p className="credit-title">Package</p>
                <p>{selectedCredit.name}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Billed</p>
                <p>Monthly</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Package Price</p>
                <p>${selectedCredit.amount}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title ">Number Reputation Cost</p>
                <p>${selectedCredit.number_reputation}/DIP</p>
              </div>
              <div className="credit-row">
                {/* <p className="credit-title">Robo Killer Cost</p>
                <p>${selectedCredit.robo_killer}</p> */}
                <p className="credit-title">Caller ID Rotator</p>
                <p>{selectedCredit.caller_id_generation == true ? 'Included' : 'Not Included'}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title border-bottom-dotted"> <Tooltip title={`Incoming cost: $${selectedCredit?.incoming_cost_per_minute_for_toll_free == undefined  ? '0' : selectedCredit?.incoming_cost_per_minute_for_toll_free}/minute`}>Toll Free Number Cost</Tooltip></p>
                <p>${selectedCredit.toll_free_number}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title border-bottom-dotted"> <Tooltip title={`Incoming cost: $${selectedCredit?.incoming_cost_per_minute_for_local == undefined  ? '0' : selectedCredit?.incoming_cost_per_minute_for_local }/minute`}>Local Number Cost </Tooltip></p>
                <p>${selectedCredit.local_number}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Locked in Period</p>
                <p>{selectedCredit?.monthly_commitment === 1 ? "1 Month" : `${selectedCredit.monthly_commitment} Months`}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Proxy Cost</p>
                <p>${selectedCredit.proxy}/mo</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Other Carrier Cost</p>
                <p>${selectedCredit.bring_own_carrier}</p>
              </div>


              <div className="credit-row">
                <p className="credit-title">Auto Check Proxy IP</p>
                <p>{selectedCredit.auto_change_ip == true ? "Included" : "Not Included"}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Check Proxy IP</p>
                <p>{selectedCredit.auto_change_ip == true ? `Every ${selectedCredit.auto_change_ip_time} Hours` : 'Not Included'}</p>
              </div>
              <div className="credit-row">
                <p className="credit-title">Auto Renewal</p>
                <Switch style={{
                  transition: 'all 0.3s',
                  border: "1px solid lightgrey"
                }} onChange={handleChangeToggle} />
              </div>
            </div>

            {/* <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          padding: '10px',
          border: '1px solid lightgrey',
          borderRadius: '5px'
      }}>
            <label style={{
              textAlign: 'left', 
              width: '100%'
          }}>
              Auto-renewal
          </label>
          <Switch style={{
              transition: 'all 0.3s',
              border: "1px solid lightgrey" 
          }} />
      
      </div> */}
          </>
        ) : (
          <p>No credit selected</p>
        )}


      </Modal>
    </>
  );
};

export default Subscription;
