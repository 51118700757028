import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Modal,
  Spin,
  Input,
  Row,
  Col,
  Typography,
  Checkbox,
  Select,
  Button,
} from "antd";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import PackageService from "../../services/packageService";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import axios from "../../api/axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const buyCreditSchema = Yup.object().shape({
  amount: Yup.string().required("Transaction Amount is required"),
  transactionId: Yup.string().required("Transaction Id is required"),
});
const initialValues = { amount: "", transactionId: "" };
const Index = ({ open, onClose, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { auth } = useAuth();
  const user_id = auth?._id;
  const [type, setType] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [cardData, setCardData] = useState([]);
  useEffect(() => {
    getAllUserCards();
  }, []);
  const getAllUserCards = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/credit/cards");
      setCardData(response.data?.data.cards.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);

  const handleStripeFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (addCard) {
      try {
        const cardNumberElement = elements.getElement(CardElement);
        const { token } = await stripe.createToken(cardNumberElement);

        if (!token) {
          setLoading(false);

          toast.error("Error while creating stripe token.");

          return;
        }

        await PackageService.buy({
          credit_id: open?._id,
          type: "credit",
          token_id: token?.id,
        });
        onSuccess();
        onClose();
        setType("");
        toast.success("Credit Bought successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
      }
      setLoading(false);
    } else {
      try {
        await PackageService.buy({
          card_id: type,
          credit_id: open?._id,
          type: "credit",
        });
        onSuccess();
        onClose();
        setType("");
        toast.success("Credit Bought successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
      }
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: buyCreditSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await PackageService.buy({
          // user_id,
          credit_id: open?._id,
          amount: values.amount,
          type: type,
          tranication_id: values.transactionId,
        });
        onSuccess();
        onClose();
        resetForm();
        toast.success("Credit Bought successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getFormContent = () => {
    // if (type !== "manual")
    return (
      <form onSubmit={handleStripeFormSubmit}>
        <Select
          value={type}
          onChange={(value) => setType(value)}
          style={{ width: "100%", marginBottom: "1rem" }}
          placeholder="Select a card"
        >
          <Select.Option value="" disabled>
            Select a card
          </Select.Option>
          {cardData.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.card_number}
            </Select.Option>
          ))}
        </Select>
        <Checkbox onChange={() => setAddCard((prev) => !prev)}>
          Add Card
        </Checkbox>
        {addCard && <CardElement className="StripeElement mt-5" />}
        <div className="flex justify-end mt-5 mb-2">
          <button
            type="button"
            className="border-primary border-1 text-primary rounded-md p-1 px-3"
            onClick={onClose}
          >
            Cancel
          </button>{" "}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-1 px-3"
            disabled={!stripe || !elements || loading}
          >
            {loading && <Spin size="small" className="custom-spin mr-3" />}
            Purchase
          </button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      title="Purchase Credits"
      open={open}
      onOk={() => {}}
      onCancel={onClose}
      okButtonProps={{ className: "bg-primary" }}
      okText="Save"
      footer={<></>}
    >
      {/* <br /> */}
      {/* <Checkbox onChange={() => setType((e) => (e === "manual" ? "credit" : "manual"))}>
          Transaction Type: <b>{type.toUpperCase()}</b>
        </Checkbox> */}
      <br />
      {getFormContent()}
    </Modal>
  );
};
const AddFund = (props) => {
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_TOKEN)
  );

  return (
    <Elements stripe={stripePromise}>
      <Index {...props} />
    </Elements>
  );
};

export default AddFund;
