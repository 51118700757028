import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Input, Modal, Pagination, Spin, Table, Tooltip, Typography } from "antd";
import GroupService from "../../services/groupService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { DeleteOutlined,CopyOutlined } from "@ant-design/icons";
import { copyToClipboard, handleDeleteCommon, scoreBadge } from "../../utils/CommonFunctions";
import ChangeRoute from "../OrderNumbers/ChangeRoute";
import ChangeGroup from "../OrderNumbers/ChangeGroup";
import UserService from "../../services/userService";
import axios from "../../api/axios";
import PaginationCommon from "../../components/PaginationCommon";
import Header from "../../components/BreadCrumbs";


const ViewRouteNumbers = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState("")
  const group = state?.route;
  const userId = state?.userId;
  const [loading, setLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [wholeSelect, setWholeSelect] = useState(false)
  const [nums, setNums] = useState([]);
  const [showpagination, setShowPagination] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 20,
  });
  const [changeRoute, setChangeRoute] = useState(false);
  const [changeGroup, setChangeGroup] = useState(false);
  const [routeChange, setRouteChange] = useState(true);
  const [groupChange, setGroupChange] = useState(true);

  const [page, setPage] = useState();
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (group) getGroupNumbers(0, 10, group?._id);
    else navigate("/groups");
  }, [group]);

  const getGroupNumbers = async (offset, limit, groupId = null) => {
    setLoading(true);
    try {
      let response;

      if (UserService.isAdmin()) {
        response = await axios.get(`/group/ordered/numbers?route_id=${groupId}&offset=${offset}&limit=${limit}&user_id=${userId}`);
      } else {
        response = await GroupService.routeNumbers(offset, limit, groupId);
      }
      setNums(response.data?.data?.numbers?.map((e, _) => ({ ...e, key: _ })));
      setCount(response.data?.data.total_count)
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  }
  const columns = [
    {
      title: (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {
              nums.length > 0 && !UserService.isAdmin() ?
                <div
                  className="checkBoxDesign"
                  onClick={(e) => handleSelectAllChange(e.target.value)}
                  style={{
                    backgroundColor: selectedKeys?.length >= 1 ? '#de4300' : 'transparent',
                    color: selectedKeys?.length >= 1 ? '#fff' : '#000',
                  }}
                >
                  {selectAll ? "✓" : selectedKeys?.length >= 1 ? "-" : ""}
                </div> : null
            }


          </div>

        </>
      ),
      dataIndex: 'checkbox',
      render: (_, record) => (
        !UserService.isAdmin() &&
        <input type="checkbox"
          className="checkbox-custom"
          checked={selectedKeys.includes(record._id)}
          style={{ marginLeft: "1rem" }}
          onChange={(e) => handleCheckboxChange(record._id)}

        />
      ),
    },
    {
      title: "Order Date",
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => {
        const dateObj = moment(record.createdAt).tz("America/New_York");
        const formattedDate = dateObj.format("YYYY-MM-DD");

        return <span>{formattedDate || "N/A"}</span>;
      },
    },
    //   {
    //     title: "Expiry Date",
    //     render: (_, record) => {
    //         const timestamp = record?.createdAt;
    //         const dateObj = new Date(timestamp);

    //         if (isNaN(dateObj.getTime())) {
    //             return <span>N/A</span>;
    //         }
    //         dateObj.setMonth(dateObj.getMonth() + 3);
    //         const dateString = dateObj.toISOString().split('T')[0];

    //         return <span>{dateString}</span>;
    //     },
    // },
    { title: "Phone Number", dataIndex: "phone_number", sorter: (a, b) => (a.phone_number || '').localeCompare(b.phone_number || ''),render: (text) => (
      <span>
        {text || 'N/A'}  <CopyOutlined
          onClick={() => copyToClipboard(text || '')}
        />
      </span>
    ), },

    {
      title: "State/Country",
      dataIndex: 'state',
      sorter: (a, b) => (a.state || '').localeCompare(b.state || ''),
      render: (_, record) => {
        {
          const state = record?.state ? `${record?.state}` : "N/A";
          return <span>{state}</span>;
        }
      },
    },
    {
      title: 'Score',
      dataIndex: 'risk_level',
      sorter: (a, b) => (a.risk_level || '').localeCompare(b.risk_level || ''),
      render: (_, record) => {
        const badgeStyle = scoreBadge(record?.risk_level);
        return (
          <span>
            <div style={badgeStyle}>
              {record?.risk_level ? record?.risk_level : 'N/A'}
            </div>
          </span>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "",
      // sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
      render: (_, record) => {
        {
          const type = record?.type == 'toll_free' ? `${record?.credit_id ? `$${record?.credit_id?.toll_free_number}` :'N/A'}` : `${record?.credit_id ? `$${record?.credit_id?.local_number}` :'N/A'}`;
          return <span>{type}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: 'type',
      sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
       render: (_, record) => {
        {
          const type = record?.type == 'toll_free' ? 'Toll Free' : 'Local';
          return <span>{type}</span>;
        }
      },
    },
    {
      title:
        <Tooltip title={"Number Reputation Group "}>
          Group
        </Tooltip>, 
         dataIndex: 'group_id?.name',
         sorter: (a, b) => (a.group_id?.name || '').localeCompare(b.group_id?.name || ''),
        render: (_, record) => {
          {
            const group = record?.group_id?.name ? `${record?.group_id?.name}` : "N/A";
            return <span>{group}</span>;
          }
        },
    },
  ];
  const handleCheckboxChange = (key) => {
    let updatedSelectedKeys;
    if (selectedKeys.includes(key)) {
      updatedSelectedKeys = selectedKeys.filter((k) => k !== key);
    } else {
      updatedSelectedKeys = [...selectedKeys, key];
    }

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(updatedSelectedKeys.length === nums.length);
    if (selectedKeys.length < 10) {
      setWholeSelect(false)
    }
  };
  const handleSelectAllChange = (checked) => {
    const updatedSelectedKeys = selectAll ? [] : nums.map((record) => record._id);

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(!selectAll);
  };
  const wholeSelectAllChange = () => {
    if (selectAll) {
      setWholeSelect(!wholeSelect)
    }
    else {
      const updatedSelectedKeys = nums.map((record) => record._id);
      setSelectedKeys(updatedSelectedKeys);
      setWholeSelect(!wholeSelect)
      setSelectAll(!selectAll);

    }

  }
  const handleDelete = () => {
    if (wholeSelect) {
      handleDeleteCommon(
        "group/ordered/numbers",
        { "numbers": selectedKeys },
        { "route_id": group?._id },
        selectedKeys?.length == 1 ? "Number" : "Numbers",
        () => getGroupNumbers(offset, 10, group?._id)
      )
    }
    else {
      handleDeleteCommon(
        "group/ordered/numbers",
        { "numbers": selectedKeys },
        { "all": wholeSelect },
        selectedKeys?.length == 1 ? "Number" : "Numbers",
        () => getGroupNumbers(offset, 10, group?._id)
      )
    }

  }
  return (
    <>
    <Header mainPageName={"Route Numbers"} subPageName={"Route"} heading={group?.name} />
      <br />
      {/* <Table columns={columns} dataSource={nums} loading={loading} pagination={false}/> */}
      <div className="mt-3 bg-card-bg p-3">
        {loading && (
          <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <Spin size="large" className="custom-spin" />
          </div>
        )}
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start" >
            {
              selectedKeys.length >= 1 &&
              <>
                <div className="flex items-center ms-4">
                  {selectedKeys?.length >= 1 && (
                    <>
                      <div
                        className="checkBoxDesign m-3 mr-3"
                        onClick={(e) => wholeSelectAllChange(e.target.value)}
                        style={{
                          backgroundColor: selectAll ? '#de4300' : 'transparent',
                          color: selectedKeys?.length >= 1 ? '#fff' : '#000',
                        }}

                      >
                        {wholeSelect ? "✓" : selectAll ? "-" : ""}

                      </div>
                      <div className="white-text">  {` Select All ${count}`} </div>
                    </>

                  )}
                  <Button className=" text-white font-bold  rounded ms-3 me-3 custom-ant-btn" style={{ backgroundColor: "red" }} icon={<DeleteOutlined />} onClick={handleDelete}>  Cancel Number</Button>
                  <Button className="bg-primary text-white flex items-center ms-2 me-3 custom-ant-btn " icon={<i className="fas fa-exchange" aria-hidden="true"></i>} onClick={() => setChangeRoute(true)} >  Change Route</Button>
                  <Button className="bg-primary text-white flex items-center ms-2 me-3 custom-ant-btn" icon={<i className="fas fa-exclamation-triangle" aria-hidden="true"></i>} onClick={() => setChangeGroup(true)} >  Change Reputation Group</Button>
                </div>
              </>
            }
          </div>

          <div className="flex flex-grow justify-end mb-3">

            <Input
              // value={searchValue}
              // onChange={filterUsers}
              size="middle"
              className="w-52 custom-input"
              placeholder="Filter Users"
            />
          </div>
        </div>
        <Table
          className="custom-table"
          // loading={loading}
          columns={columns}
          dataSource={nums}
          pagination={false}
        />
      </div>
      <PaginationCommon total={count} data={nums} handleChange={getGroupNumbers} id={group?._id} />
      <ChangeRoute routeId={group?._id} wholeSelect={wholeSelect} routeChange={routeChange} selectedKeys={selectedKeys} open={changeRoute} onClose={() => setChangeRoute(false)} onSuccess={() => { getGroupNumbers(offset, 10, group?._id); setSelectedKeys([]); setWholeSelect(false); setSelectAll(false); }} />
      <ChangeGroup routeId={group?._id} wholeSelect={wholeSelect} groupChange={groupChange} selectedKeys={selectedKeys} open={changeGroup} onClose={() => setChangeGroup(false)} onSuccess={() => { getGroupNumbers(offset, 10, group?._id); setSelectedKeys([]); setWholeSelect(false); setSelectAll(false); }} />
    </>
  );
};

export default ViewRouteNumbers;
