import { Card } from "antd";

const CardDesign = ({name,selected,setSelected,handleClick,condition}) => {
    return (
        <>
            <div style={{
                border: selected === `${name}` ? '2px solid #de4300' : '1px solid #d9d9d9',
                borderRadius: 4,
                cursor: 'pointer',
                transition: 'border 0.2s',
                padding: '8px',
            }} 
            onClick={() => handleClick(name)}
            >
                {name}
            </div>
        </>
    )
}
export default CardDesign;