import React, { useEffect, useState } from "react";
import { Modal, Spin, Input, Select } from "antd";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import PackageService from "../../services/packageService";
import * as Yup from "yup";
import { useFormik } from "formik";
import UserService from "../../services/userService";
const buyCreditSchema = Yup.object().shape({
  amount: Yup.string().required("Transaction Amount is required"),
  transactionId: Yup.string().required("Transaction Id is required"),
  user_id: Yup.string().required("User Id is required"),
//   credit_id: Yup.string().required("Credit Id is required"),
});
const initialValues = { amount: "", transactionId: "", user_id: "", credit_id: "" };

const AddBalance = ({ open, onClose, users, credits, onSuccess }) => {
  const [creditName, setCreditName] = useState(null);
  const [userName,setUserName]=useState(null);
  const { auth } = useAuth();
  const [type] = useState("manual");
  const formik = useFormik({
    initialValues,
    validationSchema: buyCreditSchema, 
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await PackageService.balance({
          user_id: values.user_id,
        //   credit_id: values.credit_id,
          amount: values.amount,
        //   type: type,
          tranication_id: values.transactionId,
        });
        onSuccess();
        onClose();
        resetForm();
        setCreditName(null);
        setUserName(null)
        toast.success("Credit bought successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // if (UserService.isAdmin()) formik.setFieldValue("user_id", auth?._id);
  }, [auth]);

  return (
    <Modal
    className="custom-modal"
      title="Add Balance"
      open={open}
      onOk={() => {}}
      onCancel={() => {
        onClose();
        formik.resetForm();
        setCreditName(null)
        setUserName(null)
      }}
      okButtonProps={{ className: "bg-primary" }}
      okText="Save"
      footer={<></>}
    >
      <form className="" onSubmit={formik.handleSubmit} noValidate>
        <div className="mb-3">
          <label className="">Transaction Id</label>
          <Input name="transactionId" placeholder="Transaction Id" {...formik.getFieldProps("transactionId")} className={`${formik.touched.transactionId && formik.errors.transactionId ? "border-red" : "" } custom-input`} />
          {formik.touched.transactionId && formik.errors.transactionId && <div className="text-red text-[12px] pt-1"> {formik.errors.transactionId}</div>}
        </div>
        <div className="mb-3">
          <label className="">Amount</label>
          <Input type="number" name="amount" placeholder="Amount" {...formik.getFieldProps("amount")} className={`${formik.touched.amount && formik.errors.amount ? "border-red" : "" } custom-input`} />
          {formik.touched.amount && formik.errors.amount && <div className="text-red text-[12px] pt-1"> {formik.errors.amount}</div>}
        </div>
        <div className="mb-3">
          <label className="">User Name</label>
          <Select placeholder="User Name" name="user_id" value={userName} options={users.map((e) => ({ value: e._id, label: `${e.name } (${e.company})`}))} className={`w-full ${formik.touched.user_id && formik.errors.user_id ? "border-red" : "" } custom-select`}
           onChange={(e) =>{ formik.setFieldValue("user_id", e);setUserName(e);}}
            />
          {formik.touched.user_id && formik.errors.user_id && <div className="text-red text-[12px] pt-1"> {formik.errors.user_id}</div>}
        </div>
{/* 
        <div className="mb-3">
          <label className="">Credit Name</label>
          <Select placeholder="Credit Name" name="credit_id" value={creditName} options={credits.map((e) => ({ value: e._id, label: `${ e.name } - ${e?.credits} Credits` }))} className={`w-full ${formik.touched.credit_id && formik.errors.credit_id ? "border-red" : "" } custom-input`} 
          // onChange={} 
          // onChange={(e) => setCreditName(e)}
          onChange={(e) => {
            formik.setFieldValue("credit_id", e);
            setCreditName(e);
          }}
          />
          {formik.touched.credit_id && formik.errors.credit_id && <div className="text-red text-[12px] pt-1"> {formik.errors.credit_id}</div>}
        </div> */}

        <div className="flex justify-end mt-5 mb-2">
          <button className="border-primary border-1 text-primary rounded-md p-1 px-3" disabled={formik.isSubmitting} onClick={(e) => {
            e.preventDefault(); 
            onClose(); 
            formik.resetForm();
            setCreditName(null);
            setUserName(null)
          }}>
            Cancel
          </button>{" "}
          <button type="submit" className="bg-primary text-white rounded-md p-1 px-3" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small custom-spin" className="mr-3" />}
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddBalance;
