import axios from "../api/axios";

const CreditService = {
  all: (offset = 0, limit = 10) => axios.get("/credit"),
  create: (callerIdGeneration,amount, name, number_reputation,proxy,local_number,incoming_cost_local,toll_free_number,commitment,incoming_cost,bring_own_carrier,auto_change_ip,auto_change_ip_time,state,custom) => axios.post("/credit", {caller_id_generation:callerIdGeneration, amount, name, number_reputation,proxy ,local_number,incoming_cost_per_minute_for_local:incoming_cost_local,toll_free_number,monthly_commitment:commitment,incoming_cost_per_minute_for_toll_free:incoming_cost,bring_own_carrier,auto_change_ip,auto_change_ip_time,state,custom}),
  edit: (callerIdGeneration,amount, name, number_reputation,proxy,local_number,incoming_cost_local,toll_free_number,commitment,incoming_cost,bring_own_carrier,auto_change_ip,auto_change_ip_time,state,credit_id,custom) => axios.patch("/credit/credit", { caller_id_generation:callerIdGeneration,amount, name, number_reputation,proxy ,local_number,incoming_cost_per_minute_for_local:incoming_cost_local,toll_free_number,monthly_commitment:commitment,incoming_cost_per_minute_for_toll_free:incoming_cost,bring_own_carrier,auto_change_ip,auto_change_ip_time,state,credit_id,custom}),

  userCredit: (user_id) => axios.get("/credit/user-credits", { params: { user_id } }),
  toggle:(credit_id,state)=>axios.patch("/credit/state",{credit_id:credit_id,state:state})
};

export default CreditService;
