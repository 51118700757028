import { Modal, Spin, Input, Select, } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GroupService from "../../services/groupService";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "../../api/axios";

const addGroupSchema = Yup.object().shape({
  route_name: Yup.string().required("Route name is required"),
});

const initialValues = {
  route_name: "",
};

const ChangeRoute = ({ open, onClose, onSuccess, selectedKeys, wholeSelect, routeChange = false ,routeId }) => {
  const [routeName, setRouteName] = useState(null);
  const [routeData, setRouteData] = useState([])
  useEffect(() => {
    getRouteData()
  }, [])
  const getRouteData = async () => {
    try {
      const response = await GroupService.allRoute(0, -1);
      setRouteData(response.data?.data.routes.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  }
  const formik = useFormik({
    initialValues: {
      route_name: "",
    },
    validationSchema: Yup.object({
      route_name: Yup.string().required("Route name is required"),
    }),
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const addField = {
              numbers: selectedKeys,
              type: 'route',
              id: values?.route_name,
              all: wholeSelect,
            }
      
        if (routeChange && wholeSelect) {
          addField.route_id = routeId;
        }
      
        const response = await axios.patch(("group/ordered/numbers"), addField)
            onSuccess();
            onClose();
            resetForm()
            toast.success("Route changed successfully");
            setRouteName(null)
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal  className="custom-modal" title="Change Route" open={open} onOk={() => { }} onCancel={() => {
      onClose();
      formik.resetForm();
      setRouteName(null)
    }} okButtonProps={{ className: "bg-primary" }} okText="Save" footer={<></>}>
      <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        {/* <div className="mb-3">
          <label className="">Select Route Name</label>
          <Input type="name" name="name" placeholder="Name" {...formik.getFieldProps("name")} className={`${formik.touched.name && formik.errors.name && "border-red"}`} />
          {formik.touched.name && formik.errors.name && <div className="text-red text-[12px] pt-1"> {formik.errors.name}</div>}
        </div> */}
        <div className="mb-3">
          <label className="mb-3">Route Name</label>
          <Select placeholder="Route Name" name="route_name" value={routeName} options={routeData.map((e) => ({ value: e._id, label: e.name }))} className={`w-full ${formik.touched.route_name && formik.errors.route_name ?  "border-red" : ""}custom-select`}
            onChange={(e) => { formik.setFieldValue("route_name", e); setRouteName(e); }}
          />
          {formik.touched.route_name && formik.errors.route_name && <div className="text-red text-[12px] pt-1"> {formik.errors.route_name}</div>}
        </div>
        <div className="flex justify-end mt-5 mb-2">
          <button className="border-primary border-1 text-primary rounded-md p-1 px-3" disabled={formik.isSubmitting}
            //  onClick={onClose}
            onClick={(e) => {
              e.preventDefault();
              onClose();
              formik.resetForm();
              setRouteName(null)
            }}
          >
            Cancel
          </button>{" "}
          <button type="submit" className="bg-primary text-white rounded-md p-1 px-3" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small" className="mr-3 custom-spin" />}
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeRoute;
