import { Modal, Spin, Input, Row, Col, Select, Table, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import GroupService from "../../services/groupService";
import * as Yup from "yup";
import { useFormik } from "formik";
import Papa from "papaparse";
import { IoCloudUpload } from "react-icons/io5";
import UserService from "../../services/userService";
import useAuth from "../../hooks/useAuth";

const addGroupSchema = Yup.object().shape({
  group_id: Yup.string().required("Group name is required"),
  // user_id: Yup.string().required("user name is required"),
});

const initialValues = {
  group_id: "",
  user_id: "",
};

const ImportNumbers = ({ groupId,open, onClose, groups, users, onSuccess, }) => {
  const { auth } = useAuth();
  const [csvFile, setCsvFile] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [fileUploading, setFileUplaoding] = useState(false);
  const [loading,setLoading]=useState(false)
  const fileInputRef = useRef(null);
  const formik = useFormik({
    initialValues,
    // validationSchema: addGroupSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await GroupService.createBulkNumbers(groupId, UserService.isAdmin() ? values.user_id : auth?._id, numbers).then(()=>{
          onSuccess();
        onClose();
        resetForm();
        setCsvFile(null);
        setNumbers([]);
        toast.success("Bulk numbers imported successfully");
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        })
        
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleDownloadTemplate=(e)=>{
    e.preventDefault(); 
    const csvContent = "number\n2536389494,";
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "template.csv";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  setCsvFile(new File([blob], "template.csv"));
  }
  const handleUploadFile = (e) => {
    setFileUplaoding(true);
    setNumbers([]);
    try {
      setLoading(true)
      let file = e.target.files[0];
      setCsvFile(file);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (results.data.length > 0) {
            const keys = Object.keys(results.data[0]);
            const parsedData = results.data.map((row) =>
              keys.reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {})
            );
            const formatted = parsedData.filter((obj) => obj.number && obj.number.trim() !== "");
            const cleanedData = formatted?.map((obj) => {
              const newObj = {};
              Object.keys(obj).forEach((key) => {
                if (obj[key].trim() !== "") {
                  newObj[key] = obj[key];
                }
              });
              return newObj;
            });
            if (cleanedData.length === 0) {
              toast.error("No Numbers found, Please try a different file");
              setCsvFile(null);
              setFileUplaoding(false);
            } else {
              setFileUplaoding(false);
              setNumbers(cleanedData);
            }
             setLoading(false)
          }
          else
            {
              toast.error("Invalid file")
              setLoading(false)
              setCsvFile(null)
              return
            }
        },
      });
    } catch (e) {
      toast.error("Invalid file");
      setFileUplaoding(false);
      setCsvFile(null)
    }
    setFileUplaoding(false);
  };
  const handleCancel=()=>{
    // formik.resetForm();
    // formik.setFieldValue("group_id", "");
    // formik.setFieldValue("group_id", "")
    // formik.setValues({
    //   ...formik.values,
    //   group_id: [],
    // });
    onClose();

    formik.setFieldValue("group_id", null);

  }
  return (
    <Modal className="custom-modal" title="Import Numbers" open={open} onOk={() => {}} onCancel={() => {
      onClose();
      formik.resetForm();
      setCsvFile(null);
      setNumbers([]);
      if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
    }}  
     okButtonProps={{ className: "bg-primary" }} okText="Save" footer={<></>}>
      <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Row gutter={[16, 16]}>
          {/* <Col className="mb-3" lg={12} sm={24}>
            <label className="">Group Name</label>
            <Select placeholder="Group Name" options={groups.map((e) => ({ value: e._id, label: e.name }))} className={`w-full ${formik.touched.group_id && formik.errors.group_id && "border-red"}`} onChange={(e) => formik.setFieldValue("group_id", e)} />
            {formik.touched.group_id && formik.errors.group_id && <div className="text-red text-[12px] pt-1"> {formik.errors.group_id}</div>}
          </Col> */}

          {UserService.isAdmin() && (
            <Col className="mb-3" lg={12} sm={24}>
              <label className="">User Name</label>
              <Select placeholder="User Name" options={users.map((e) => ({ value: e._id, label: e.name }))} onChange={(e) => formik.setFieldValue("user_id", e)} className={`w-full ${formik.touched.user_id && formik.errors.user_id && "border-red"}`} />
              {formik.touched.user_id && formik.errors.user_id && <div className="text-red text-[12px] pt-1"> {formik.errors.user_id}</div>}
            </Col>
          )}
        </Row>

        <div className="flex items-center justify-center w-full">
          <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${numbers.length && "border-primary"}`}>
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <IoCloudUpload style={{ width: 75, height: 75 }} />
              {fileUploading || loading && <Spin  className="custom-spin"/>}
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs font-semibold text-gray-500 dark:text-gray-400">{csvFile ? csvFile?.name : "CSV File"}</p>
            </div>
            <input id="dropzone-file" type="file" accept=".csv" className="hidden"  onChange={handleUploadFile} ref={fileInputRef} />
          </label>
        </div>

        {numbers.length ? (
          <div className="mt-4 mb-8 h-52 overflow-auto">
            <Table className="custom-table" columns={[{ title: "Numbers", dataIndex: "number" }]} dataSource={numbers.map((e, i) => ({ ...e, key: i }))} />
          </div>
        ) : null}
        <p className="mt-3 ">Supported file format: <span className="font-bold ">.csv</span>, column name should be <span className="font-bold ">number</span> and one number per row without country code.</p>
        <div className="flex justify-start mt-5 mb-2">
          <button className="bg-primary text-white rounded-md p-1 px-3" onClick={(e)=>handleDownloadTemplate(e)}> Download Template</button>
          <div className="flex flex-grow justify-end" >
          <button className="border-primary border-1 text-primary rounded-md p-1 px-3" disabled={formik.isSubmitting}  onClick={(e) => {
            e.preventDefault(); 
            onClose(); 
            formik.resetForm();
            setNumbers([])
            setCsvFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          }}>
            Cancel
          </button>{" "}
          <button type="submit" className="bg-primary text-white rounded-md p-1 px-3" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small " className="mr-3 custom-spin" />}
            Save
          </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ImportNumbers;
