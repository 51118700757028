import React from "react";
import { Card, Col, Input, Row, Spin, Typography } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { storedKeys } from "../../utils/storedKeys";
import { useNavigate } from "react-router-dom";
import LogoForOutside from "../../images/logo.png"
const { Text, } = Typography;
const { TextArea } = Input;

const initialValues = { name: "", email: "", phone: "", country: "", zip: "", state: "", city: "", address: "", company: "", password: "",rePassword:"" };
const registrationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  country: Yup.string(),
  zip: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  address: Yup.string(),
  company: Yup.string().required("Company is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  rePassword: Yup.string().required("Re-Password is required").oneOf([Yup.ref("password"), null], "Passwords must match"),
  
});

const SignUp = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { rePassword, ...formData } = values;
      setSubmitting(true);
      try {
        await AuthService.register(formData);
        toast.success("User registered successfully");
        return navigate("/");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
    
    <section  style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor:"black"  }}>
    <div >
      <img style={{height: "10rem" ,marginBottom: "-3rem"}} src={LogoForOutside}></img>
      </div>
      <Card className="mx-auto p-8 w-90 md:w-[600px] bg-card-bg text-white border border-solid border-border">
        <div className="mb-8">
          <h1 className="text-3xl font-bold">Signup</h1>
          {/* <Text className="text-gray-500">Welcome! Web Portal</Text> */}
        </div>
        <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
          <Row gutter={[16, 16]}>
            <Col className="mb-3" lg={12} sm={24}>
              <label className="capitalize">Name</label>
              <Input type="name" name="name" placeholder="Name" {...formik.getFieldProps("name")} className={`${formik.touched.name && formik.errors.name ? "border-red":""} custom-input`} />
              {formik.touched.name && formik.errors.name && <div className="text-red text-[12px] pt-1"> {formik.errors.name}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">company</label>
              <Input type="company" name="company" placeholder="company" {...formik.getFieldProps("company")} className={`${formik.touched.company && formik.errors.company ? "border-red":""} custom-input`} />
              {formik.touched.company && formik.errors.company && <div className="text-red text-[12px] pt-1"> {formik.errors.company}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">Email</label>
              <Input type="email" name="email" placeholder="Email" {...formik.getFieldProps("email")} className={`${formik.touched.email && formik.errors.email ? "border-red":""} custom-input`} />
              {formik.touched.email && formik.errors.email && <div className="text-red text-[12px] pt-1"> {formik.errors.email}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">phone</label>
              <Input type="phone" name="phone" placeholder="phone" {...formik.getFieldProps("phone")} className={`${formik.touched.phone && formik.errors.phone ? "border-red":""} custom-input`} />
              {formik.touched.phone && formik.errors.phone && <div className="text-red text-[12px] pt-1"> {formik.errors.phone}</div>}
            </Col>
            <Col sm={24}>
              <label className="capitalize">address</label>
              <TextArea autoSize placeholder="address" {...formik.getFieldProps("address")} className={`${formik.touched.address && formik.errors.address ? "border-red":""} custom-input`} />
              {formik.touched.address && formik.errors.address && <div className="text-red text-[12px] pt-1"> {formik.errors.address}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">City</label>
              <Input type="city" name="city" placeholder="city" {...formik.getFieldProps("city")} className={`${formik.touched.city && formik.errors.city ? "border-red":""} custom-input`} />
              {formik.touched.city && formik.errors.city && <div className="text-red text-[12px] pt-1"> {formik.errors.city}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">state/Province</label>
              <Input type="state" name="state" placeholder="state" {...formik.getFieldProps("state")} className={`${formik.touched.state && formik.errors.state ? "border-red":""} custom-input`} />
              {formik.touched.state && formik.errors.state && <div className="text-red text-[12px] pt-1"> {formik.errors.state}</div>}
            </Col>
            

          

          

            <Col lg={12} sm={24}>
              <label className="capitalize">zip</label>
              <Input type="zip" name="zip" placeholder="zip" {...formik.getFieldProps("zip")} className={`${formik.touched.zip && formik.errors.zip ? "border-red":""} custom-input`} />
              {formik.touched.zip && formik.errors.zip && <div className="text-red text-[12px] pt-1"> {formik.errors.zip}</div>}
            </Col>
            <Col lg={12} sm={24}>
              <label className="capitalize">country</label>
              <Input type="country" name="country" placeholder="country" {...formik.getFieldProps("country")} className={`${formik.touched.country && formik.errors.country ? "border-red":""} custom-input`} />
              {formik.touched.country && formik.errors.country && <div className="text-red text-[12px] pt-1"> {formik.errors.country}</div>}
            </Col>

          
            <Col  sm={24}>
              <label className="capitalize">Password</label>
              <Input type="password" name="password" placeholder="Password" {...formik.getFieldProps("password")} className={`${formik.touched.password && formik.errors.password ? "border-red":""} custom-input`} />
              {formik.touched.password && formik.errors.password && <div className="text-red text-[12px] pt-1"> {formik.errors.password}</div>}
            </Col>
            <Col  sm={24}>
              <label className="capitalize">Re-enter Password</label>
              <Input type="password" name="rePassword" placeholder="Re-Password" {...formik.getFieldProps("rePassword")} className={`${formik.touched.rePassword && formik.errors.rePassword ? "border-red":""} custom-input`} />
              {formik.touched.rePassword && formik.errors.rePassword && <div className="text-red text-[12px] pt-1"> {formik.errors.rePassword}</div>}
            </Col>

           
          </Row>

          <button type="submit" className="w-[100%] bg-primary text-white rounded-md p-1 mt-6 hover-none" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small" className="mr-3 custom-spin" />}
            Signup
          </button>

          <button className="w-[100%] border border-primary text-primary rounded-md p-1 mt-4" onClick={() => navigate("/")}>
            Cancel
          </button>
        </form>
      </Card>
      <div className="mt-4 " >
      <p style={{ color:"#6a7684" ,fontSize:"x-small", marginBottom:"2rem"}}>© Copyright 2024 | All Rights Reserved | v 1.0</p>
    </div>
    </section>
    </>
  );
};

export default SignUp;
