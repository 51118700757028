import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { Typography, Table, Dropdown, Button, Spin, Input } from "antd";
import { toast } from "react-toastify";
import GroupService from "../../../services/groupService";
import { FaPlus } from "react-icons/fa";
import AddGroup from "../../groups/addGroup";
import ImportNumbers from "../../groups/importNumbers";
import UserService from "../../../services/userService";
import { GrLinkTop } from "react-icons/gr";
import { storedKeys } from "../../../utils/storedKeys";
import moment from "moment-timezone";
import { DownloadOutlined} from "@ant-design/icons";
import PaginationCommon from "../../../components/PaginationCommon";
  
const { Text } = Typography;
const UserCidGroup = ({userId,activeTab,userStateData}) => {
  const createGroupPremission = UserService.getPermission("createGroup"),
    creatBulkNumberInGroup = UserService.getPermission("creatBulkNumberInGroup");
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [addGroup, setAddGroup] = useState(false);
  const [importNums, setImportNums] = useState(false);
  const [users, setUsers] = useState([]);
  const [viewNumbers, setViewNumbers] = useState(null);
  const [offSet,setOffSet]=useState(0);
  const [limit,setLimit]=useState(10);
  const [count,setCount]=useState('');

  useEffect(() => {
    getAllGroups(offSet,limit,userId);
    getAllUsers();
  }, []);

  const getAllGroups = async (offset,limit,userId=null) => {
    setLoading(true);
    try {
      const response = await GroupService.user(offset, limit,userId);
      setGroups(response.data?.data.groups.map((e) => ({ ...e, key: e._id })));
      setCount(response.data.data.total_count)
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const getAllUsers = async () => {
    try {
      const response = await UserService.all(0, 10);
      setUsers(response.data?.data.users.map((e) => ({ name: e.name, _id: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  const filterGroups = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = groups?.filter((item) => Object.values(item).some((value) => typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())));
    setFilteredGroups(result);
  };
  const handleRefresh = async (row) => {
    setLoading(true)
    try{
    await GroupService.status(row._id);
    }
   catch (error) {
    const e = error?.response?.data?.title;
    toast.error(e || error);
  }
    await getAllGroups(offSet,limit);
    setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
  };
  const getKeyByValue = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);
  const handleDownloadCSV = async (row, selectedTypes) => {
    try {
      const queryString = Object.keys(selectedTypes)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(selectedTypes[key])}`)
        .join("&");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/group/download?group_id=${row?._id}&${queryString}`, {
        method: "GET",
        headers: {
          "Content-Type": "text/csv",
          Authorization: JSON.parse(localStorage.getItem(storedKeys.accessToken)),
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${row.name}_${getKeyByValue(selectedTypes, true)}.csv`);
      document.body.appendChild(link);
      link.click();
      toast.success("CSV file downloaded successfully");
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV");

    }
  };

  const columns = [
    {
      title: "Name",
      render: (e) => (
        <div className="text-primary italic cursor-pointer flex items-center gap-2" onClick={() => navigate(`/groups/${e._id}`, { state: { group: e ,userId:userId ,userStateData:userStateData} })}>
          {e?.name}
          {/* <GrLinkTop /> */}
        </div>
      ),
    },
    { title: "Spam", dataIndex: "blackListed" },
    { title: "Clean", dataIndex: "nonBlackListed" },
    { title: "Total Count", dataIndex: "totalCount" },
    { title: "Created At", 
    // dataIndex: "totalCount"
    render: (_, record) => {
      const dateObj = moment(record?.createdAt).tz("America/New_York");
      const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

      return <span>{formattedDate == "Invalid date" ? "N/A" : formattedDate || "N/A"}</span>;
    },
   },

  ];
  const getItems = (row) => {
    return [
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: false, is_total: true, is_black_list: false })}>All </div>, key: "0" },
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: false, is_total: false, is_black_list: true })}>Spam </div>, key: "1" },
      { label: <div onClick={() => handleDownloadCSV(row, { is_non_black_list: true, is_total: false, is_black_list: false })}>Clean </div>, key: "2" },
    ];
  };
  // if (!UserService.isAdmin()) {
  columns.push({
    title: "Action",
    render: (row) => (
      <div className="flex items-center gap-5">
        {/* <Button className="bg-primary text-white" onClick={() => handleRefresh(row)}>
          Check Status
        </Button> */}
        <Dropdown menu={{ items: getItems(row) }} trigger={["click"]}>
          <Button className="bg-primary text-white" onClick={(e) => e.preventDefault()} icon={<DownloadOutlined />}>
          </Button>
        </Dropdown>
      </div>
    ),
  });
  // }

  return (
    <>
      <div className="flex items-center justify-between">
        {/* <Text className="text-2xl font-bold">Groups</Text> */}
        <div className="flex items-center gap-4">
          {/* {createGroupPremission && (
            <Button onClick={() => setAddGroup(true)} className="bg-primary text-white flex items-center" icon={<FaPlus />}>
              Add CID Group
            </Button>
          )} */}
          {/* {creatBulkNumberInGroup && (
            <Button className="bg-primary text-white" onClick={() => setImportNums(true)}>
              Import Numbers
            </Button>
          )} */}
        </div>
      </div>
      <div className="mt-3 bg-card-bg p-3">
        {/* <div className="flex justify-end mb-3">
          <Input value={searchValue} onChange={filterGroups} size="middle" className="w-52" placeholder="Filter groups" />
        </div> */}
        <Table  className="custom-table" loading={loading} columns={columns} scroll={{ x: true }} dataSource={searchValue ? filteredGroups : groups} pagination={false}/>
        <PaginationCommon total={count}  data={groups} handleChange={getAllGroups} id={userId} />
      </div>
      <AddGroup open={addGroup} onClose={() => setAddGroup(false)} onSuccess={getAllGroups} />
      <ImportNumbers open={importNums} onClose={() => setImportNums(false)} onSuccess={getAllGroups} users={users} groups={groups} />
    </>
  );
};

export default UserCidGroup;
