import React from "react";
import { Card, Col, Input, Row, Spin, Typography } from "antd";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
// import AuthService from "../../services/authService";
import AuthService from "../../../../services/authService";
import UserService from "../../../../services/userService";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { storedKeys } from "../../../../utils/storedKeys";
import { useNavigate } from "react-router-dom";
const { Text, } = Typography;
const { TextArea } = Input;

const initialValues = {  password: "",rePassword:"" };
const registrationSchema = Yup.object().shape({
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  rePassword: Yup.string().required("Re-Password is required").oneOf([Yup.ref("password"), null], "Passwords must match"),
  
});

const ChangePassword = ({userId}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      const { rePassword, ...formData } = values;
      setSubmitting(true);
      try {
        if(UserService.isAdmin)
          {
            await AuthService.adminChangePassword(formData,userId)
          }
          else{
        await AuthService.changePassword(formData);

          }
        toast.success("Password changed successfully");
        resetForm();
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <section className="flex items-center ">
      <Card className="mx-auto p-8 w-90 md:w-[600px] bg-card-bg" style={{border: "1px solid #5e5555 "}}>
        
        <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
          <Row gutter={[16, 16]}>
            <Col  sm={24}>
              <label className="capitalize white-text">Password</label>
              <Input  type="password" name="password" placeholder="Password" {...formik.getFieldProps("password")} className={`${formik.touched.password && formik.errors.password ? 'border-red' : ''} custom-input`} />
              {formik.touched.password && formik.errors.password && <div className="text-red text-[12px] pt-1"> {formik.errors.password}</div>}
            </Col>
            <Col  sm={24}>
              <label className="capitalize white-text">Re-enter Password</label>
              <Input type="password" name="rePassword" placeholder="Re-Password" {...formik.getFieldProps("rePassword")} className={`${formik.touched.rePassword && formik.errors.rePassword ? "border-red" : ""} custom-input`} />
              {formik.touched.rePassword && formik.errors.rePassword && <div className="text-red text-[12px] pt-1"> {formik.errors.rePassword}</div>}
            </Col>

           
          </Row>

          <button type="submit" className="w-[100%] bg-primary text-white rounded-md p-1 mt-6 hover-none" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small" className="mr-3 custom-spin" />}
            Change Password
          </button>

        
        </form>
      </Card>
    </section>
  );
};

export default ChangePassword;
