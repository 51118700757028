import defaultAxios from "axios";
import { storedKeys } from "../utils/storedKeys";
import { navigateToLogin } from "./navigateHelper";
import { toast } from "react-toastify";

const axios = defaultAxios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

let isRedirecting = false;

axios.interceptors.request.use((config) => {
  const authToken = JSON.parse(localStorage.getItem(storedKeys.accessToken));
  if (authToken) {
    config.headers["Authorization"] = authToken;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response && error.response.status;
    if (status === 403 && !isRedirecting) {
      isRedirecting = true;
      localStorage.clear();
        toast.error("Your session has expired");
      navigateToLogin();
      setTimeout(() => {
        isRedirecting = false;
      }, 10000);
      return new Promise(() => {}); 
    } else if (!isRedirecting) {
    return Promise.reject(error);

    }

  }
);


export default axios;
