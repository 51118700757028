import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import routes from "./routes";
import { setNavigateFunction } from "./api/navigateHelper";
import { useNavigate } from 'react-router-dom';
const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();

  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  return (
    <React.Fragment>
      <ToastContainer  toastStyle={{ backgroundColor: "#1e1d1d", border:"red" }} position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
      {content}
    </React.Fragment>
  );
};

export default App;
