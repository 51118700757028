import React, { useEffect, useState } from "react";
import { Breadcrumb, Tabs, Typography } from "antd";
import Balance from "./Tabs/balance";
import Cards from "./Tabs/Cards";
import Transactions from "../History/Tabs/Transactions";
import CreditHistory from "../History/Tabs/CreditHistory";
import { useLocation } from "react-router-dom";
const { TabPane } = Tabs;
export default function BillingSetting() {
  const location = useLocation();
  const callback = (key) => {
  };
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  useEffect(() => {
    setActiveTab(location.state?.activeTab || "1");
  }, [location.state?.activeTab]);
  return (
    <>
      <div className="flex items-center justify-between">

      <Typography.Text className="text-2xl font-bold white-text">Billing Setting</Typography.Text>

       <Breadcrumb className="custom-breadcrumb font-bold"
    items={[
      {
        title: 'Settings',
      },
      {
        title: <span style={{ color: 'white' }}>Billing</span>
      }
    ]}
  />
  </div>
   <Tabs className="custom-tabs" activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Balance" key="1">
        <Balance handleTabChange={handleTabChange} />
      </TabPane>
      <TabPane tab="Cards" key="2">
        <Cards />
      </TabPane>
      <TabPane tab="Transaction History" key="3">
        <Transactions handleTabChange={handleTabChange} />
      </TabPane>
      {/* <TabPane tab="Credit History" key="4">
        <CreditHistory />
      </TabPane> */}
    </Tabs>
    </>
   
  );
}
