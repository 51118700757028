import React, { useState } from "react";
import { Breadcrumb, Tabs, Typography } from "antd";
import Subscription from "./Tabs/Subscription";
import Transactions from "./Tabs/Transactions";
import CreditHistory from "./Tabs/CreditHistory";
const { TabPane } = Tabs;
export default function History() {
  const callback = (key) => {
  };
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <>
 <div className="flex items-center justify-between">

<Typography.Text className="text-2xl font-bold white-text">Pricing</Typography.Text>

<Breadcrumb
  className="custom-breadcrumb font-bold"
  items={[
    {
      title: 'Settings',
    },
    {
      title: <span style={{ color: 'white' }}>Pricing</span>,
    }
  ]}
/>
</div>
    <Tabs className="custom-tabs" activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Subscription" key="1">
        <Subscription handleTabChange={handleTabChange}/>
      </TabPane>
      {/* <TabPane tab="Transaction History" key="2">
        <Transactions handleTabChange={handleTabChange} />
      </TabPane>
      <TabPane tab="Credit History" key="3">
        <CreditHistory />
      </TabPane> */}
      {/* <TabPane tab="Tab 3" key="3">
        Content of Tab Pane 3
      </TabPane> */}
    </Tabs>
    </>
  );
}
