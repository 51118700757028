import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Typography } from "antd";
const { Text } = Typography;

const Header = ({mainPageName,subPageName,heading=null}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex items-center justify-between white-text">
                <div className="flex item-center">
                    <button
                        onClick={() => navigate(-1)}
                        className="bg-primary text-white rounded-md p-1 ms-1 me-3"
                    >
                        <LeftOutlined />
                    </button>
                    <Text className="text-2xl font-bold white-text">{heading  ? heading  : subPageName}</Text></div>

                <Breadcrumb className="custom-breadcrumb font-bold"
                    items={[
                        {
                            title: mainPageName,
                        },
                        {
                            title: <span style={{ color: 'white' }}> {subPageName}</span>
                        }
                    ]}
                />
            </div>
        </>
    )
}
export default Header;