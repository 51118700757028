import React, { useEffect, useState } from "react";
import 'flag-icon-css/css/flag-icons.css';

export default function RegionCard({ regions, selectedCountry, setSelectedCountry }) {
  const [selectedContinent, setSelectedContinent] = useState();
useEffect(() => {
  const selectRegionFirstTime = regions?.find((item) => item?.CONTIENENT === 'USA');
  if (selectRegionFirstTime) {
    setSelectedContinent(selectRegionFirstTime?.CONTIENENT);
  }
}, [regions]);
  const handleContinentSelect = (continent) => {
    setSelectedContinent(continent);
  };
  const handleCountrySelect = (country,name) => {
    setSelectedCountry({name:name,slug:country});
  };
  const uniqueContinents = [...new Set(regions.map(region => region.CONTIENENT))];
  const RegionBasedOnContinent = regions.filter(region => region.CONTIENENT === selectedContinent);
  return (<>
    <div className=" mt-2">
      <div>
        <div>
          {uniqueContinents.map((continent, index) => (
            <button
              key={index}
              className={"px-3 py-1 mx-2 rounded-md"}
              onClick={() => handleContinentSelect(continent)}
              style={{
                border: selectedContinent === continent ? '2px solid #de4300' : '1px solid #d9d9d9'
              }}
            >
              {continent}
            </button>
          ))}
        </div>
      </div>
    </div>
    {selectedContinent && (
      <div className="mt-2">
        <div className="flex flex-wrap">
          {RegionBasedOnContinent.map((country) => (
            <button
            disabled={country.available===false}
              key={country.key}
              className={`flex items-center px-3  mx-2 mb-2 rounded-md ${selectedCountry?.name === country?.NAME ? 'border-blue-500' : 'border-gray-300'
                } ${country.available == false ? '' : 'py-1' }`}
              onClick={() => handleCountrySelect(country?.SLUG,country?.NAME)}
              style={{ border: selectedCountry?.slug === country.SLUG ? '2px solid #de4300' : '1px solid #d9d9d9' }}
            >
              <span
                className={`flag-icon flag-icon-${country.CODE}  mr-2`}
              ></span>
              {country.NAME}
            </button>
          ))}
        </div>
      </div>
    )}
  </>

  )
}