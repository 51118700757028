import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";
import { Button, Table, Tooltip } from "antd";
import CreditService from "../../../../services/creditService";
import moment from "moment";
import Swal from "sweetalert2";
import {TRANSACTION_TYPE} from "../../../../utils/enums"
export default function NumberReputation({ userId }
) {
    const [NumberReputationPackeges, setNumberReputationPackages] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getNumberReputationPackages(userId);
    }, []);
    const getNumberReputationPackages = async (userId) => {
        setLoading(true);
        try {
            const response = await CreditService.userCredit(userId)
            setNumberReputationPackages(response.data?.data?.userCredits
            )
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
        setLoading(false);
    };
    const handleBuyCredit = async (e, AllRecord) => {
        e.preventDefault();
        if (AllRecord.is_expired == false) {
            Swal.fire({
                title: "Are you sure you want to unsubscribe?",
                text: "Please be aware that upon unsubscribing, all associated credits will be permanently lost and cannot be recovered. This action is irreversible.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, Unsubscribe it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await axios
                            .delete("/credit", { data: { credit_id: AllRecord.credit._id, user_id: userId } })
                            .then(() => {
                                getNumberReputationPackages(userId);
                                toast.success("Package unsubscribed successfully");
                            });
                    } catch (error) {
                        const e = error?.response?.data?.title;
                        toast.error(e || error);
                    }
                }
            });
        }
        else {
            Swal.fire({
                title: "Are you sure you want to Renewal?",
                icon: "success",
                input: 'text', 
                inputPlaceholder: 'Enter transaction id', 
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Renewal it!",
                preConfirm: (inputValue) => {
                    if (!inputValue) {
                        Swal.showValidationMessage('Transactin id is required');
                    } else {
                        return inputValue;
                    }
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const addField = {
                            credit_id: AllRecord.credit._id,
                            type: TRANSACTION_TYPE.MANUAL,
                            user_id: userId,
                            tranication_id: result.value
                        };
                        const response = await axios.post("/credit/renewal", addField);
                        getNumberReputationPackages(userId);
                        toast.success("Package subscribed successfully");
                    } catch (error) {
                        const e = error?.response?.data?.title;
                        toast.error(e || error);
                    }
                }
            });
        }

    };
    const columns = [
        {
            title: "Name",
            render: (_, record) => {
                {
                    const name = record?.credit.name ? `${record?.credit.name}` : "N/A";
                    return <span>{name}</span>;
                }
            },
        },
        {
            title: "Amount",
            render: (_, record) => {
                {
                    const credits = record?.amount ? `${record?.amount}` : "0";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title:<Tooltip title={"Number Reputation "}> Number Reputation Price </Tooltip>,
            render: (_, record) => {
                {
                    const used = record?.credit.number_reputation ? `$${record?.credit.number_reputation}` : "0";
                    return <span>{used}</span>;
                }
            },
        },
        {
            title: "Proxy Price",
            render: (_, record) => {
                {
                    const remaining = record?.credit ? `$${record?.credit?.proxy}` : "0";
                    return <span>{remaining}</span>;
                }
            },
        },
        {
            title: <Tooltip title={"local Number"}> local Number Price </Tooltip>,
            render: (_, record) => {
                {
                    const credits = record?.credit.local_number
                        ? `$${record?.credit.local_number
                        }` : "0";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title: <Tooltip title={"Toll Free Number"}> Toll Free Number Price </Tooltip>,
            render: (_, record) => {
                {
                    const credits = record?.credit.toll_free_number
                        ? `$${record?.credit.toll_free_number
                        }` : "0";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title:  <Tooltip title={"Bring your own carrier"}> BYOC Price </Tooltip>,
            render: (_, record) => {
                {
                    const credits = record?.credit.bring_own_carrier
                        ? `$${record?.credit.bring_own_carrier
                        }` : "0";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title:  "Change IP",
            render: (_, record) => {
                {
                    const credits = record?.credit.auto_change_ip
                        ? `${record?.credit.auto_change_ip
                        }` : "False";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title: "Auto Change IP Time",
             render: (_, record) => {
                {
                    const credits = record?.credit.auto_change_ip_time
                        ? `${record?.credit.auto_change_ip_time
                        }Hours` : "N/A";
                    return <span>{credits}</span>;
                }
            },
          },
        {
            title: "Auto Renewal",
            render: (_, record) => {
                {
                    const credits = record?.is_renewal
                        ? `${record?.is_renewal == true ? "On" : "Off"}` : "Off";
                    return <span>{credits}</span>;
                }
            },
        },
        {
            title: "Subscribe Date",
            render: (_, record) => {
                const dateObj = moment(record.purchasing_date).tz("America/New_York");
                const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

                return <span>{formattedDate || "N/A"}</span>;
            },
        },
        {
            title: "Expiry Date",
            render: (_, record) => {
                const dateObj = moment(record.expiry_date
                ).tz("America/New_York");
                const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

                return <span>{formattedDate || "N/A"}</span>;
            },
        },
        {
            title: "Actions",
            render: (_, record) => {
                {
                    return <Button onClick={(e) => handleBuyCredit(e, record)}
                        className={record.is_expired == false ? 'text-white' : 'bg-primary text-white'}
                        style={

                            record.is_expired == false ?
                                { backgroundColor: "#df4747" } :
                                {}
                        } >{record.is_expired === true ? "Renewal" : "Unsubscribe"}</Button>;
                }
            },
        },

    ]
    return (
        <>
            <Table
            className="custom-table"
                loading={loading}
                columns={columns}
                dataSource={NumberReputationPackeges}
                scroll={{ x: 'max-content' }}

            />
        </>
    )
}
