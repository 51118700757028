import React, { useState, useEffect } from "react";
import {
  Modal,
  Spin,
  Input,
  Row,
  Col,
  Typography,
  Checkbox,
  Select,
  Button,
} from "antd";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import PackageService from "../../services/packageService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
// import axios from "../api/axios";
import axios from "../../api/axios";

const buyCreditSchema = Yup.object().shape({
  amount: Yup.string().required("Transaction Amount is required"),
  transactionId: Yup.string().required("Transaction Id is required"),
});
const initialValues = { amount: "", transactionId: "" };

const Index = ({ open, onClose, onSuccess,makeDefaultHide }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { auth } = useAuth();
  const user_id = auth?._id;
  const [type, setType] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  // useEffect(() => {
  //   getAllUserCards();
  // }, []);
  // const getAllUserCards = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get("/credit/transications");
  //     setCardData(response.data?.data.cards.map((e) => ({ ...e, key: e._id })));
  //   } catch (error) {
  //     const e = error?.response?.data?.title;
  //     toast.error(e || error);
  //   }
  //   setLoading(false);
  // };
  const [loading, setLoading] = useState(false);

  const handleStripeFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // if (addCard) {
    try {
      const cardNumberElement = elements.getElement(CardElement);
      const { token } = await stripe.createToken(cardNumberElement);

      if (!token) {
        setLoading(false);

        toast.error("Error while creating stripe token.");

        return;
      }

      await PackageService.buy({
        // credit_id: open?._id,
        // type: "credit",
        token_id: token?.id,
        is_default: isDefault,
      });
      onSuccess();
      onClose();
      setType("");
      toast.success("Card Added successfully");
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
    // }
    // else {
    //   try {
    //     await PackageService.buy({
    //       card_id: type,
    //       credit_id: open?._id,
    //       type: "credit",
    //     });
    //     onSuccess();
    //     onClose();
    //     setType("");
    //     toast.success("Credit Bought successfully");
    //   } catch (error) {
    //     const e = error?.response?.data?.title;
    //     toast.error(e || error);
    //   }
    //   setLoading(false);
    // }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: buyCreditSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await PackageService.buy({
          // user_id,
          credit_id: open?._id,
          amount: values.amount,
          type: type,
          tranication_id: values.transactionId,
        });
        onSuccess();
        onClose();
        resetForm();
        toast.success("Credit Bought successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });
  const cardElementOptions = {
    style: {
      base: {
        color: 'white',
        backgroundColor: 'black',
        '::placeholder': {
          color: '#ccc',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };
  const getFormContent = () => {
    // if (type !== "manual")
    return (
      <form onSubmit={handleStripeFormSubmit}>
        {/* <Select
          value={type}
          onChange={(value) => setType(value)}
          style={{ width: "100%", marginBottom: "1rem" }}
          placeholder="Select a card"
        >
          <Select.Option value="" disabled>
            Select a card
          </Select.Option>
          {cardData.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.card_number}
            </Select.Option>
          ))}
        </Select>
        <Checkbox onChange={() => setAddCard((prev) => !prev)}>
          Add Card
        </Checkbox> */}
        {/* {addCard &&  */}
        <CardElement options={cardElementOptions} className="StripeElement mt-5" />
          {
            makeDefaultHide &&
            <div className="flex items-center mt-2">
            <Input
              type="checkbox"
              className="mr-2"
              value={isDefault}
              onChange={(e) => setIsDefault(!isDefault)}
              style={{ marginRight: "-14rem", marginLeft: "-14rem" }}
            />{" "}
            <span>Make a default Card?</span>
          </div>
          }
        
        <div className="flex justify-end mt-5 mb-2">
          <button
            type="button"
            className="border-primary border-1 text-primary rounded-md p-1 px-3"
            onClick={onClose}
          >
            Cancel
          </button>{" "}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-1 px-3 custom-ant-btn"
            disabled={!stripe || !elements || loading}
          >
            {loading && <Spin size="small" className="mr-3 custom-spin " />}
            Add
          </button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      title="Add Card"
      open={open}
      onOk={() => {}}
      onCancel={onClose}
      okButtonProps={{ className: "bg-primary" }}
      okText="Save"
      footer={<></>}
      className="custom-modal"
    >
      {/* <br className="custom-modal"/> */}
      {/* <Checkbox onChange={() => setType((e) => (e === "manual" ? "credit" : "manual"))}>
        Transaction Type: <b>{type.toUpperCase()}</b>
      </Checkbox> */}
      <br />
      {getFormContent()}
    </Modal>
  );
};

const BuyCredits = (props) => {
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_TOKEN)
  );

  return (
    <Elements stripe={stripePromise}>
      <Index {...props} />
    </Elements>
  );
};

export default BuyCredits;
