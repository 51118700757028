import { Breadcrumb, Button, Descriptions, Dropdown, Menu, Modal, Spin, Table, Typography } from "antd";
import { EllipsisOutlined, EyeOutlined,CopyOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import PaginationCommon from "../../components/PaginationCommon";
import CardDesignCommon from "../../Common/CardDesignCommon"
import { ApiCall, copyToClipboard } from "../../utils/CommonFunctions";
import { useRowLoading } from "../../context/LoadingContext";
import UserService from "../../services/userService";
import Swal from "sweetalert2";
import Header from "../../components/BreadCrumbs";
export default function SpecificProxyData() {
    const { rowLoadingStates, setRowLoading } = useRowLoading();
    const [hoveredRowKey, setHoveredRowKey] = useState(null);

    const { state } = useLocation();
    const navigate = useNavigate();
    const { Text } = Typography;

    const [loading, setLoading] = useState(false);
    const [specificProxy, setSpecificProxy] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [instanseIp, setInstanseIp] = useState();


    const proxy = state?.proxy;
    const userId=state?.userId;
    useEffect(() => {
        if (proxy)
            getProxyDetail();
        else navigate("/proxy");
    }, [proxy]);
    const getProxyDetail = async () => {
        setLoading(true);
        try {
            let response;

      if (UserService.isAdmin()) {
        response = await axios.get(`/proxy?proxy_id=${proxy?._id}&user_id=${userId}`);
      } else {
        response = await axios.get(`/proxy?proxy_id=${proxy?._id}`);
      }
            setSpecificProxy(
                response?.data?.data?.instances
                    .filter(e => e.is_media)
                    .map(e => ({ ...e, key: e._id }))
            );

        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
        setLoading(false);
    };
    const handleCloseModal = (instanseApi) => {
        setIsModalVisible(!isModalVisible);
        setInstanseIp(instanseApi)

    };
    const getContentForModal = () => {
        return (
            <>
                <div>
                    <Text className="text-3xl font-bold p-4">{instanseIp?.ipv4}</Text>
                    <div className="flex justify-between p-4">
                    <CardDesignCommon title={"Campaign Type"} data={instanseIp?.is_spam == false ? "Spam" : "Scam"} />
                    <CardDesignCommon title={"Call Type"} data={"Live Agent"} />
                    <CardDesignCommon title={"Known FCC Target"} data={instanseIp?.fcc == false ? "No" : "Yes"} />
                    <CardDesignCommon title={"Known Traceback"} data={instanseIp?.traceback==false ? "No Data Available" : "true"} />

                    </div>
                    {/* <Text className="text-1xl font-bold">Details</Text> */}
                    <div className="p-4">
                    <div className="w-1/1 bg-white  rounded-lg p-4 m-2" style={{border:"1px solid lightgrey"}}>
                            <div className="text-sm font-bold" >Description</div>
                            <div className="text-sm text-gray-500">{instanseIp?.transcription == null ? "N/A" : instanseIp?.transcription}</div>
                        </div>
                        </div>
                        {/* <Text className="text-1xl font-bold">Call Recording</Text> */}
                    <div className="p-4">
                    <div className="w-1/1 bg-white  rounded-lg p-4 m-2" style={{border:"1px solid lightgrey"}}>
                            <div className="text-sm font-bold" >call recording </div>
                            <div className="text-sm text-gray-500">listen</div>
                        </div>
                        </div>
                </div>
            </>
        )
    }
    const handleIpChange=async(e,instanseId)=>{

        e.preventDefault();
        Swal.fire({
          title: "Are you sure you want to Change IP?",
          text: "All active live calls will be terminated.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, change it!",
          customClass: {
            popup: 'custom-swal'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
            setRowLoading(instanseId, true);
            const updateField={
                proxy_id:proxy._id,
                instance_id:instanseId,
            }
            
           
    
            await ApiCall(
                "patch",
                "/proxy/ip",
                updateField,
                "Instance"
            ).then((res)=>{
                getProxyDetail();
                setRowLoading(instanseId,false)
    
            })
          }})
        
    }
    const handleIpCheck=async(e,row)=>{
        e.preventDefault();   
        setRowLoading(row?._id, true);    
       const response=await ApiCall(
          "get",
          "/proxy/check-ip",
          {instance_id:row?._id},
          null,
          null,

        )
        if(response!=undefined)
            {
                setRowLoading(row?._id,false)
                getProxyDetail();
                if(response?.is_scam == true || response?.is_spam ==true)
                  {
                      toast.error("IP is spammed")
                  }
                  else{
                      toast.success("IP is clean")
                  }
            }
        

    }
    const isAdmin = UserService.isAdmin();

    const baseColumns = [
      {
        title: "IP",
        render: (_, record) => {
          const IP = record?.ipv4 ? `${record?.ipv4}` : "N/A";
          return <span>{IP} <CopyOutlined onClick={() => copyToClipboard(IP)} /></span>;
        },
      },
      {
        title: "Status",
        render: (_, record) => {
          const IPStatus =  `${record?.is_scam == true || record?.is_spam == true  ? "Spam" : "Clean"}` ;
          const statusClass = IPStatus === 'Clean' ? 'greenBadge' : 'redBadge';
          return (
            <span className={statusClass}>
              {IPStatus}
            </span>
          );
        },
      },
    ];
  
    const adminColumns = [
      // {
      //   title: "Actions",
      //   render: (row) => (
      //     <Dropdown menu={{ items: rowLoadingStates[row?._id] ? [] : getItems(row) }}>
      //       <Button
      //       className="bg-primary"
      //         icon={
      //           rowLoadingStates[row._id] ? (
      //             <Spin />
      //           ) : (
      //             <EllipsisOutlined />
      //           )
      //         }
      //       />
      //     </Dropdown>
      //   ),
      // },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            rowLoadingStates[record?._id] ? (
                <Button
                    className="custom-ant-btn"
                    style={{
                        backgroundColor: '#de4300',
                        color: 'white',
                        padding: '8px',
                        display: 'flex',
                    }}
                >
                    <Spin  className="custom-spin"/>
                </Button>
            ) : (
                <Dropdown overlay={<Menu className="custom-dropdown-menu" items={getItems(record)} />} trigger={['click']}>
                    <Button
                        className="custom-ant-btn"
                        onClick={(e) => e.preventDefault()}
                        style={{
                            backgroundColor: '#de4300',
                            color: 'white',
                            padding: '8px',
                            display: 'flex',
                        }}
                    >
                        <EllipsisOutlined  style={{ fontSize: '20px' }}/>
                    </Button>
                </Dropdown>
            )
        ),
    },
    ];
  
    const columns = isAdmin ? baseColumns : [...baseColumns, ...adminColumns];
    const getItems = (row) => {
        const item = specificProxy.find((item) => item._id == row?._id);
        return [
            // { label: <div onClick={()=>handleCloseModal(row)}><EyeOutlined /> View </div>, key: "0" },
            { label: <div onClick={(e)=>handleIpCheck(e,row)}><EyeOutlined   /> Check Status </div>, key: "1" },

            { label: <div onClick={(e)=>handleIpChange(e,row?._id)}><i className="fas fa-exchange" aria-hidden="true"></i> Change IP </div>, key: "2" },
        ];
    };
    return (
        <>
        <Header mainPageName={'Proxy Server'} subPageName={'Proxy detail'} heading={proxy?.name} />
            <br />
            <div style={{ padding: '10px', backgroundColor: '#333', color: 'white', marginTop: "10px"
}}>
            <Table
             className="custom-table"
                    columns={columns}
                    dataSource={specificProxy}
                    pagination={false}
                    rowClassName={(record) =>
                        hoveredRowKey === record.key ? 'hovered-row' : ''
                    }
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        borderBottom:" red "
                    }}
                    onRow={(record) => ({
                        style: { backgroundColor: 'black', color: 'white', },
                        onMouseEnter: () => setHoveredRowKey(record.key),
                        onMouseLeave: () => setHoveredRowKey(null),
                    })}
                    components={{
                        header: {
                            cell: (props) => (
                                <th
                                    {...props}
                                    style={{
                                        backgroundColor: '#444',
                                        color: 'white',
                                        padding: '15px',
                                        borderBottom:"2px solid #5e5555 "
                                    }}
                                />
                            ),
                        },
                        body: {
                            cell: (props) => (
                                <td
                                    {...props}
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '15px',
                                        borderBottom:"2px solid #5e5555 "
                                    }}
                                />
                            ),
                        },
                    }}
                />

            </div>
            {/* <Table
                columns={columns}
                dataSource={specificProxy}
                loading={loading} className="custom-table" /> */}
                
            {/* <PaginationCommon  handleChange={handleChange} data={specificProxy}/> */}
            <Modal
                styles={{
                    content: { backgroundColor: '#FFFEFA' }, 
                }}
                style={{ top: 20 ,left:80 }}
                visible={isModalVisible}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
                okButtonProps={{ className: "bg-primary" }}
                okText="Save"
                width={1000}
                footer={<></>}
            >
                <br />
                {getContentForModal()}
            </Modal>
        </>
    )
}