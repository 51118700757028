
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButton = ({ amount, onCompete }) => {
  const amountValue = parseFloat(amount)

  const createOrder = async (data, actions) => {
    try {
      return await actions.order.create({
        purchase_units: [
          {
            amount: {
              value: amountValue,
            },
          },
        ],
      });
    } catch (error) {
    }
  };

  const onApprove = async (data, actions) => {
    try {
      onCompete(data);
    } catch (error) {
    }
  };

  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      onError={(error) => {
      }}
    />
  );
};

export default PayPalButton;
