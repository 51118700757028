import React from "react";
import { Breadcrumb, Tabs, Typography } from "antd";
import Profile from "./Tabs/Profile"
import UrlGeneration from "./Tabs/UrlGeneration";
import ChangePassword from "./Tabs/ChangePassword";
const { TabPane } = Tabs;
export default function History() {
  const callback = (key) => {
  };
  return (
    <>
  <div className="flex items-center justify-between">

<Typography.Text className="text-2xl font-bold white-text">Account</Typography.Text>

 <Breadcrumb className="custom-breadcrumb font-bold"
items={[
{
  title: 'Settings',
},
{
  title:<span className="white-text">Account</span>
}
]}
/>
</div>
    <Tabs className="custom-tabs" defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Profile" key="1">
        <Profile />
      </TabPane>
      <TabPane tab="Change Password" key="2"> 
      <ChangePassword />        
      </TabPane>
      {/* <TabPane tab="Url Generation" key="3"> 
      <UrlGeneration />        
      </TabPane> */}
      {/* <TabPane tab="Credit History" key="3"> */}
        {/* <Transactions /> */}
        {/* credit History */}
      {/* </TabPane> */}
      {/* <TabPane tab="Tab 3" key="3">
        Content of Tab Pane 3
      </TabPane> */}
    </Tabs>
    </>
  );
}
