import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Typography, Table, Card, Button, Spin, Input, Modal, Tabs,Pagination } from "antd";
import UserService from "../../services/userService";
import { toast } from "react-toastify";
import TabPane from "antd/es/tabs/TabPane";
import TransactionHistory from "./Tabs/TransactionHistory";
import PaginationCommon from "../../components/PaginationCommon";

const { Text } = Typography;
const Users = () => {
  const updateUserStatus = UserService.getPermission("updateUserStatus");
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [statusUpdating, setStatusUpdating] = useState(null);
  const [total, setTotal] = useState(0);
  const [page,setPage]=useState();
  const [showpagination,setShowPagination]=useState(true);
  const [current, setCurrent] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [hasRun, setHasRun] = useState(false);
  const [offSet,setOffSet]=useState(0);
  const [limit,setLimit]=useState(10);
  const [count,setCount]=useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 20,
  });
  useEffect(() => {
    getAllUsers(offSet,limit);
  }, []);

  const getAllUsers = async (offSet,limit) => {
    setLoading(true);
    try {
      const response = await UserService.all(offSet, limit);
      setUsers(response.data?.data.users.map((e) => ({ ...e, key: e._id })));
      setCount(response.data.data.total_count)

    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleUpdateStatus = async (user_id, status) => {
    try {
      setStatusUpdating(user_id);
      await UserService.updateStatus(user_id, status);
      const user = users?.find((e) => e._id === user_id);
      user.status = status;
      setUsers([...users]);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setStatusUpdating(null);
    }

    setStatusUpdating(null);
  };
  const filterUsers = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = users?.filter((item) => Object.values(item).some((value) => typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())));
    setFilteredUsers(result);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: "Name", dataIndex: "name" },
    {title:"Company",dataIndex:"company"},
    {title:"Country",dataIndex:"country"},
    // {title:"City",dataIndex:"city"},
    // { title: "Status", render: (row) => <div className={`capitalize ${row?.status === "reject" ? "text-red" : "text-primary"}`}>{row?.status}</div> },
    {
      title: "Balance",
      dataIndex: 'balance',
      render: text => {
        // Convert the text to a string
        const balance = text?.toString();
    
        // Format the balance to two decimal places
        const formattedBalance = balance?.includes('.')
          ? balance.replace(/(\.\d{2})\d+$/, '$1') // Truncate to two decimal places
          : balance;
    
        // Return the formatted balance within a styled div
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            ${formattedBalance}
          </div>
        );
      }
    },
    
    {title:"Proxy",dataIndex:"proxyCount"},
    {title:"Order Numbers",dataIndex:"orderNumberCount"},
    {
      title: "",
      render: (row) => {
        return (
          <>
            {statusUpdating === row._id ? (
              <Spin size="small" className="custom-spin" />
            ) : (
              updateUserStatus && (
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  {/* <Button className="bg-primary text-white" onClick={()=>handleUserData(row?._id)}>View</Button> */}
                  <Button className="bg-primary text-white custom-ant-btn" onClick={() => navigate(`/users/${row?._id}`,{ state: { group: row } })}>View</Button>
                  {
                    
                   row?.status !== "approve" && (
                    <>
                     <Button type="primary" className="bg-primary text-white" disabled={row.status === "approve"} onClick={() => handleUpdateStatus(row._id, "approve")} size="small">
                     Accept
                   </Button>{" "}
                   <Button type="danger" className="bg-red text-white" disabled={row.status === "reject"} onClick={() => handleUpdateStatus(row._id, "reject")} size="small">
                     Reject
                   </Button>
                   </>
                  )}
                 
                </div>
              )
            )}
          </>
        );

      },
    },
  ];

  return (
    <>
      <Text className="text-2xl font-bold text-white">Customers</Text>
      <div className="mt-3 bg-card-bg p-3">
        <div className="flex justify-end mb-3">
          <Input value={searchValue} onChange={filterUsers} size="middle" className="custom-input w-52" placeholder="Filter Users" />
        </div>
        <Table className="custom-table" loading={loading} columns={columns} dataSource={searchValue ? filteredUsers : users } pagination={false}  scroll={{ x: true }}/>
        <PaginationCommon total={count}  data={users} handleChange={getAllUsers} />
      </div>
      <Modal
        title="User Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Transaction History" key="1">
            <TransactionHistory
            userId={userId} />
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            Content of Tab Pane 2
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Users;
