import { Breadcrumb, Button, Card, Col, Input, Row, Spin, Typography } from "antd";
import Select from 'react-select';
import { COUNTRIES, AREACODE, FONE_NUMBERS_TYPE, QUANTITYSELECTION, COUNTRY, MANUAL_CSV } from "../../utils/enums"
import CardDesign from "../../components/CardDesign";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FileUpload from "./FileUpload";
import axios from "../../api/axios";
import { VscDebugDisconnect } from "react-icons/vsc";
import GroupService from "../../services/groupService";
import { useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import useAuth from "../../hooks/useAuth";
import Header from "../../components/BreadCrumbs";

const MultipleOrderNumber = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const initialValues = {
        country: "",
        numberType: "",
        state: [],
        areaCode: [],
        searchOption: "",
        searchOptionWith: "",
        quantity: [],
        prefix: [],
        wholeQuantity: "",
        wholeQuantityTollFree: "",
        route: "",
        group: "",
    }
    const [form, setForm] = useState(initialValues)
    const [selectedCardForManualCsv, setSelectedCardForManualCsv] = useState(MANUAL_CSV.MANUAL)
    const [selectedCard, setSelectedCard] = useState(FONE_NUMBERS_TYPE.LOCAL.label);
    const [selectedCardCountry, setSelectedCardCountry] = useState(null);
    const [selectedCardForQuanity, setSelectedCardForQuantity] = useState(null);
    const [hasError, setHasError] = useState([])
    const [hasErrorMore, setHasErrorMore] = useState([])

    const [loading, setLoading] = useState(false)
    const [forOrder, setForOrder] = useState(false)
    const [orderNumber, setOrderNumbers] = useState([])
    const [routeData, setRouteData] = useState([])
    const [groupData, setGroupData] = useState([])
    const handleChange = (selected, fieldName) => {
        // setHasError([])
        setHasErrorMore([])
        setForOrder(false)
        if (fieldName == "country") {
            setForm({ ...form, country: { value: selected.value, label: selected.label }, state: [], areaCode: [] });
        }

        else if (fieldName == "numberType") {
            setForm({ ...form, numberType: { value: selected.value, label: selected.label } });
        }
        else if (fieldName == "state") {
            // setHasError([])
            const updatedState = selected ? selected.map(option => ({ value: option.value, label: option.label, quantity: form.state.find(state => state.value === option.value)?.quantity || '' })) : [];
            const allSelected = selected && selected.some(option => option.value === 'All');
            setForm(prevForm => ({
                ...prevForm,
                state: allSelected ? [{ value: 'All', label: 'All', quantity: '' }] : updatedState,
                areaCode: [],
            }));
        }
        else if (fieldName == "areaCode") {
            // setHasError([])
            const updatedState = selected ? selected.map(option => ({ value: option.value, label: option.label, quantity: form?.areaCode?.find(state => state.value === option.value)?.quantity || '' })) : [];
            const allSelected = selected && selected.some(option => option.value === 'All');
            setForm(prevForm => ({
                ...prevForm,
                areaCode: allSelected ? [{ value: 'All', label: 'All', quantity: '' }] : updatedState,
            }));
        }
        else if (fieldName == "prefix") {
            const updatedPrefix = selected ? selected?.map(option => ({ value: option.value, label: option.label, quantity: form?.prefix?.find(state => state.value === option.value)?.quantity || '' })) : []
            const allSelected = selected && selected?.some(option => option.value === 'All');
            setForm(prevForm => ({
                ...prevForm,
                prefix: allSelected ? [{ value: 'All', label: 'All', quantity: '' }] : updatedPrefix,
            }));
        }
        else {
            setForm(form => ({
                ...form,
                [fieldName]: { value: selected.value, label: selected.label }
            }));
        }
    };
    const handleInputChange = (e) => {
        setForOrder(false)
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,

        }));
    }
    useEffect(() => {
        getStateData()
        getRouteData()
        checkRunningOrder(false)
    }, [])
    const checkRunningOrder=async(param=true)=>{
        const waitForCompletion=async()=>{
            try {
                const response = await axios.get("group/running/orders")
                if(response.data.data.status===true)
                    {
                        setLoading(true)
                        setTimeout(waitForCompletion,1000)
                    }
                    else{
                        setLoading(false)
                        if(param)
                        

                            {
                                toast.success("Number ordered successfully")
                             setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
                                navigate("/ordernumber")
                            }
                          
                    }

            } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error);
            }
        }
        waitForCompletion()
    }
    // const waitForCompletion = async (rowId) => {
    //     setRowLoading(rowId, true); 
      
    //     const checkStatus = async () => {
    //       try {
    //         const response = await GroupService.again(rowId); // Call the service
    //         if (response.data.data.group.state === "Completed") {
    //           toast.success("Numbers checked successfully");
    //           setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
    //           setRowLoading(rowId, false); 
    //           await getAllGroups(); 
    //         } else {
    //           setTimeout(checkStatus, 10000);
    //         }
    //       } catch (error) {
    //         const errorMsg = error?.response?.data?.title || error.message || "An error occurred";
    //         toast.error(errorMsg);
    //       }
    //     };
      
    //     checkStatus();
    //   };
    const getStateData = async () => {
        try {
            const response = await GroupService.all(0, -1);
            setGroupData(response.data?.data.groups.map((e) => ({ ...e, key: e._id })));
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const getRouteData = async () => {
        try {
            const response = await GroupService.allRoute(0, -1);
            setRouteData(response.data?.data.routes.map((e) => ({ ...e, key: e._id })));
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const handleSubmit = async () => {

        if (selectedCard == FONE_NUMBERS_TYPE.LOCAL.label) {
            if (selectedCardCountry == null) {
                toast.error("Country is required")
                return
            }
            if (selectedCardForQuanity == null) {
                toast.error("Quantity selection is required")
                return
            }
            if (form?.state.length == 0 && selectedCardForQuanity == QUANTITYSELECTION.STATE.label) {
                toast.error("State is required")
                return
            }
            if (form?.areaCode.length == 0 && selectedCardForQuanity == QUANTITYSELECTION.AREACODE.label) {
                toast.error("Area Code is required")
                return
            }
            if (selectedCardForQuanity === QUANTITYSELECTION.QUANTITY.label && form?.wholeQuantity == "") {
                toast.error("Quantity is required")
                return
            }
            if (selectedCardForQuanity === QUANTITYSELECTION.AREACODE.label && form?.areaCode?.some(option => option.quantity == "")) {
                const emptyStateIndex = form?.areaCode.findIndex(areaCode => areaCode.quantity === '');
                if (emptyStateIndex !== -1) {
                    const emptyStateLabel = form?.areaCode[emptyStateIndex]?.label;
                    toast.error(`Quantity is required for '${emptyStateLabel}'. `);
                    return;
                }
            }
            if (selectedCardForQuanity === QUANTITYSELECTION.STATE.label && form?.state.some(option => option.quantity == "")) {
                const emptyStateIndex = form?.state.findIndex(state => state.quantity === '');
                if (emptyStateIndex !== -1) {
                    const emptyStateLabel = form?.state[emptyStateIndex]?.label;
                    toast.error(`Quantity is required for '${emptyStateLabel}'. `);
                    return;
                }
            }
            if (form?.route == '') {
                toast.error('Route is required')
                return
            }

            if (forOrder) {
                setLoading(true)
                try {

                    const response = await axios.post("group/bulk/order/numbers", { numbers: orderNumber, country: selectedCardCountry, route_id: form?.route?.value, group_id: form?.group?.value, type: FONE_NUMBERS_TYPE.LOCAL.value })
                    await checkRunningOrder()
                    // toast.success("Number ordered successfully")
                    // setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

                    // navigate("/ordernumber")
                } catch (error) {
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }
            else {
                setLoading(true)

                let addField = {
                    type: FONE_NUMBERS_TYPE.LOCAL.value,
                    country: selectedCardCountry,
                    // quantity_selector: selectedCardForQuanity == QUANTITYSELECTION.AREACODE.label ? QUANTITYSELECTION.AREACODE.value : selectedCardForQuanity == QUANTITYSELECTION.STATE.label ? QUANTITYSELECTION.STATE.value :QUANTITYSELECTION.QUANTITY.value,
                };

                switch (selectedCardForQuanity) {
                    case QUANTITYSELECTION.STATE.label:
                        if (form?.state[0].value == 'All') {
                            const quantityString = form?.state?.map((item) => item.quantity).toString();
                            addField.quantity = quantityString;
                            addField.quantity_selector = QUANTITYSELECTION.QUANTITY.value
                        }
                        else {
                            addField.states = form?.state?.map((item) => ({
                                name: item?.label,
                                quantity: item?.quantity,
                            }));
                            addField.quantity_selector = QUANTITYSELECTION.STATE.value
                        }
                        break;
                    case QUANTITYSELECTION.AREACODE.label:
                        if (form?.areaCode[0].value == 'All') {
                            const quantityString = form?.areaCode?.map((item) => item.quantity).toString();
                            addField.quantity = quantityString;
                            addField.quantity_selector = QUANTITYSELECTION.QUANTITY.value
                        }
                        else {
                            addField.area_codes = form?.areaCode?.map((item) => ({
                                name: item?.value,
                                quantity: item?.quantity,
                            }));
                            addField.quantity_selector = QUANTITYSELECTION.AREACODE.value
                        }

                        break;
                    case QUANTITYSELECTION.QUANTITY.label:
                        addField.quantity = form?.wholeQuantity;
                        addField.quantity_selector = QUANTITYSELECTION.QUANTITY.value

                        break;
                }

                try {
                    const response = await axios.post("/group/bulk/numbers", addField)
                    setOrderNumbers(response.data.data.numbers)
                    setForOrder(true)
                } catch (error) {
                    if (error.response) {
                        const apiError = error?.response?.data?.data?.errors
                        handleApiError(apiError)

                    }
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }


        }
        else {
            if (forOrder) {
                setLoading(true)
                try {

                    const response = await axios.post("group/bulk/order/numbers", { numbers: orderNumber, route_id: form?.route?.value, group_id: form?.group?.value, type: FONE_NUMBERS_TYPE.TOLL_FREE.value })
                    await checkRunningOrder()

                    // toast.success("Number ordered successfully")
                    // setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

                    // navigate("/ordernumber")
                } catch (error) {
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }
            else {
                if (form?.prefix.length == 0) {
                    toast.error("Toll free prefix is required")
                    return
                }
                if (form?.prefix.some(option => option.quantity == "")) {
                    const emptyStateIndex = form?.prefix.findIndex(state => state.quantity === '');
                    if (emptyStateIndex !== -1) {
                        const emptyStateLabel = form?.prefix[emptyStateIndex]?.label;
                        toast.error(`Quantity is required for '${emptyStateLabel}'. `);
                        return;
                    }
                }
                // if (form?.wholeQuantityTollFree == '') {
                //     toast.error("Quantity is required")
                //     return
                // }
                if (form?.route == '') {
                    toast.error("Route is required")
                    return
                }
                if (form?.prefix[0].value == 'All') {
                    const quantityString = form?.prefix?.map((item) => item.quantity).toString();
                    const addField = {
                        type: "toll_free",
                        quantity: quantityString,
                        quantity_selector: 'quantity'
                    }
                    try {
                        setLoading(true)
                        const response = await axios.post("/group/bulk/numbers", addField)
                        setOrderNumbers(response.data.data.numbers)
                        setForOrder(true)
                    } catch (error) {
                        if (error.response) {
                            const apiError = error?.response?.data?.data?.errors
                            handleApiError(apiError)

                        }
                        const e = error?.response?.data?.title;
                        toast.error(e || error);
                    } finally {
                        setLoading(false)
                    }
                }
                else {
                    const addField = {
                        type: "toll_free",
                        toll_free_codes: form?.prefix?.map((item) => ({
                            name: item?.value,
                            quantity: item?.quantity,
                        })),
                        // quantity: form?.wholeQuantityTollFree,
                        quantity_selector: 'toll_free'
                    }
                    try {
                        setLoading(true)
                        const response = await axios.post("/group/bulk/numbers", addField)
                        setOrderNumbers(response.data.data.numbers)
                        setForOrder(true)
                    } catch (error) {
                        if (error.response) {
                            const apiError = error?.response?.data?.data?.errors
                            handleApiError(apiError)

                        }
                        const e = error?.response?.data?.title;
                        toast.error(e || error);
                    } finally {
                        setLoading(false)
                    }
                }

            }

        }
    }
    const handleApiError = (errorFields) => {
        setHasError(errorFields);
        setHasErrorMore(errorFields)
    };
    const handleClickForQuantity = (name) => {
        setForOrder(false)

        setSelectedCardForQuantity(name);
        setForm({
            ...form,
            areaCode: Array.isArray(form?.areaCode) ? form.areaCode.map(item => ({ ...item, quantity: '' })) : [],
            state: Array.isArray(form?.state) ? form.state.map(item => ({ ...item, quantity: '' })) : []

        });
        setHasError([])
    };
    const handleClick = (name) => {
        setForOrder(false)
        setHasError([])
        setSelectedCard(name);
    };
    const handleClickManualOrCsv = (name) => {
        setForOrder(false)

        setSelectedCardForManualCsv(name);
    };
    const handleClickCountry = (name) => {
        setForOrder(false)

        setSelectedCardCountry(name);
        setForm({ ...form, state: [], areaCode: [] })
    };
    const handleChangeQuantity = (areaCode, quantity) => {
        setForOrder(false)

        // setHasError([])
        setForm(prevForm => ({
            ...prevForm,
            areaCode: prevForm.areaCode.map(item =>
                item.value === areaCode ? { ...item, quantity: quantity } : item
            )
        }));
    };
    const handleChangeQuantityTollFree = (prefix, quantity) => {
        setForOrder(false)

        // setHasError([])
        setForm(prevForm => ({
            ...prevForm,
            prefix: prevForm.prefix.map(item =>
                item.value === prefix ? { ...item, quantity: quantity } : item
            )
        }));
    };
    const handleChangeQuantityState = (state, quantity) => {
        // setHasError([])
        setForOrder(false)


        setForm(prevForm => ({
            ...prevForm,
            state: prevForm.state.map(item =>
                item.value === state ? { ...item, quantity: quantity } : item
            )
        }));
    };

    let selectedCountryAreaCodes = [];
    if (selectedCardCountry == 'US') {
        const selectedCountryData = COUNTRIES.find(country => country?.abbreviation === selectedCardCountry);
        selectedCountryAreaCodes = Object.keys(AREACODE)
            .filter(state => selectedCountryData?.states?.some(selectedState => selectedState?.name === state))
            .flatMap(state => AREACODE[state]);
    }
    if (selectedCardCountry == 'Canada') {
        const selectedCountryData = COUNTRIES.find(country => country?.name === selectedCardCountry);
        selectedCountryAreaCodes = Object.keys(AREACODE)
            .filter(state => selectedCountryData?.states?.some(selectedState => selectedState?.name === state))
            .flatMap(state => AREACODE[state]);
    }
    if (selectedCardCountry === 'US/Canada') {
        selectedCountryAreaCodes = Object.values(AREACODE).flat();
    }
    function temporary(fromValue) {
        const allAreaCodes = { value: 'All', label: 'All' };
        return fromValue?.areaCode?.some(option => option.value == 'All') ? [allAreaCodes] : [allAreaCodes, ...selectedCountryAreaCodes?.map(item => ({ value: item, label: item }))];

    }
    const stateOptions = selectedCardCountry === COUNTRY?.US
        ? form?.state?.some(option => option.value === 'All')
            ? [{ value: 'All', label: 'All' }]
            : [
                { value: 'All', label: 'All' },
                ...COUNTRIES.find(country => country.abbreviation === "US").states.map(state => ({ value: state.abbreviation, label: state.name }))
            ]
        : selectedCardCountry === COUNTRY?.CANADA
            ? form?.state?.some(option => option.value === 'All')
                ? [{ value: 'All', label: 'All' }]
                : [
                    { value: 'All', label: 'All' },
                    ...COUNTRIES.find(country => country.abbreviation === "CA").states.map(state => ({ value: state.abbreviation, label: state.name }))
                ]
            : selectedCardCountry === COUNTRY?.BOTH
                ? form?.state?.some(option => option.value === 'All')
                    ? [{ value: 'All', label: 'All' }]
                    : [
                        { value: 'All', label: 'All' },
                        ...COUNTRIES.reduce((acc, curr) => [...acc, ...curr.states.map(state => ({ value: state.abbreviation, label: state.name }))], [])
                    ]
                : [];
    const optionForTollFreePrefix = form?.prefix?.some(option => option.value === 'All') ? [{ value: 'All', label: 'All' }] : [
        { value: 'All', label: "All" },
        { value: "800", label: "800" },
        { value: "833", label: "833" },
        { value: "844", label: "844" },
        { value: "855", label: "855" },
        { value: "866", label: "866" },
        { value: "877", label: "877" },
        { value: "888", label: "888" }




    ]
    const customStyles = {
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#1e1d1d',
            color: 'white',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'white',
        }),
    };
    return (
        <>
           <Header mainPageName={"Number List"} subPageName={"Bulk Order Number"} />

            <div className="flex  justify-center ">
                <Card className=" mt-5 bg-card-bg white-text p-4" style={{ width: "48rem", pointerEvents: loading ? 'none' : 'auto', filter: loading ? 'blur(1px)' : 'none', }}>
                    {loading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                            <Spin size="large" className="custom-spin" />
                        </div>
                    )}
                    <Row gutter={16} className="mb-4 flex items-center">
                        <Col span={8}>
                            <CardDesign
                                name={MANUAL_CSV.MANUAL}
                                selected={selectedCardForManualCsv}
                                handleClick={handleClickManualOrCsv} />
                        </Col>
                        <Col span={8}>
                            <CardDesign
                                name={MANUAL_CSV.UPLOADFILE}
                                selected={selectedCardForManualCsv}
                                handleClick={handleClickManualOrCsv}
                            /></Col>
                    </Row>
                    {
                        selectedCardForManualCsv == MANUAL_CSV.UPLOADFILE ? <div>
                            <FileUpload /></div> :
                            <>
                                <label className="block text-gray-700 text-sm font-bold mb-4">Number Type<span style={{ color: "red" }}>*</span></label>

                                <Row gutter={16} className="mb-4 flex items-center">
                                    <Col span={8}>
                                        <CardDesign
                                            name={FONE_NUMBERS_TYPE.LOCAL.label}
                                            selected={selectedCard}
                                            handleClick={handleClick} />
                                    </Col>
                                    <Col span={8}>
                                        <CardDesign
                                            name={FONE_NUMBERS_TYPE.TOLL_FREE.label}
                                            selected={selectedCard}
                                            handleClick={handleClick}
                                        /></Col>
                                </Row>
                                {
                                    selectedCard == FONE_NUMBERS_TYPE.LOCAL.label ?
                                        <>
                                            <label className="block text-gray-700 text-sm font-bold mb-4">Country<span style={{ color: "red" }}>*</span></label>
                                            <Row gutter={16} className="mb-4">
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={COUNTRY.US}
                                                        selected={selectedCardCountry}
                                                        handleClick={handleClickCountry} />
                                                </Col>
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={COUNTRY.CANADA}
                                                        selected={selectedCardCountry}
                                                        handleClick={handleClickCountry}
                                                    /></Col>
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={COUNTRY.BOTH}
                                                        selected={selectedCardCountry}
                                                        handleClick={handleClickCountry}
                                                    /></Col>
                                            </Row>
                                            <label className="block text-gray-700 text-sm font-bold mb-4">Order By<span style={{ color: "red" }}>*</span></label>

                                            <Row gutter={16} className="mb-4 flex items-center">
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={QUANTITYSELECTION.STATE.label}
                                                        selected={selectedCardForQuanity}
                                                        handleClick={handleClickForQuantity} />
                                                </Col>
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={QUANTITYSELECTION.AREACODE.label}
                                                        selected={selectedCardForQuanity}
                                                        handleClick={handleClickForQuantity}
                                                    /></Col>
                                                <Col span={8}>
                                                    <CardDesign
                                                        name={QUANTITYSELECTION.QUANTITY.label}
                                                        selected={selectedCardForQuanity}
                                                        handleClick={handleClickForQuantity}
                                                    /></Col>
                                            </Row>
                                            {
                                                selectedCardForQuanity == QUANTITYSELECTION.STATE.label ?
                                                    <div>
                                                        <label className="block text-gray-700 text-sm font-bold mb-4">{QUANTITYSELECTION.STATE.label}<span style={{ color: "red" }}>*</span></label>
                                                        <Select
                                                            className=" mb-4 rounded-lg border border-gray-300"
                                                            classNamePrefix="custom-select"
                                                            style={{ width: '100%' }}
                                                            name="state"
                                                            isMulti
                                                            styles={customStyles}
                                                            options={stateOptions}
                                                            value={form.state ? form.state.map(value => ({ value: value.value, label: value.label })) : null}
                                                            onChange={(selected) => handleChange(selected, 'state')}  >

                                                        </Select>
                                                        {hasErrorMore?.length > 0 && <p className="mb-2" style={{ color: "red" }}>Number not available for {hasErrorMore?.map((item) => item.name).join(',')},Please remove from selection</p>}
                                                    </div> : null
                                            }

                                            {
                                                form?.state && selectedCardForQuanity == QUANTITYSELECTION.STATE.label && (
                                                    <div className="flex flex-wrap ">
                                                        {form.state.map(value => (
                                                            <div key={value.value} className="flex items-center   " style={{ border: `1px solid ${value.quantity === '' || hasError?.map((item) => item.name).includes(value.label) ? 'red' : 'white'}`, borderRadius: "3px", marginRight: "5px", marginBottom: "8px" }}>
                                                                <div className={`${hasError?.map((item) => item.name).includes(value.label) ? "bg-red text-white px-2" : "bg-primary text-white   px-2"} `}>{value.label}</div>
                                                                <input
                                                                    type="number"
                                                                    style={{color:"White" ,backgroundColor:"black"}}
                                                                    // min="1"
                                                                    className="w-8 border-none focus:outline-none ps-1"
                                                                    // placeholder="Qty"
                                                                    value={value.quantity}
                                                                    onChange={(e) => handleChangeQuantityState(value.value, e.target.value)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            {
                                                selectedCardForQuanity == QUANTITYSELECTION.AREACODE.label ? (

                                                    <div>
                                                        <label className="block text-gray-700 text-sm font-bold mb-4">{QUANTITYSELECTION.AREACODE.label}<span style={{ color: "red" }}>*</span></label>
                                                        <Select
                                                            className=" mb-2 rounded-lg border border-gray-300"
                                                            classNamePrefix="custom-select"
                                                            style={{ width: '100%' }}
                                                            name="areaCode"
                                                            isMulti
                                                            styles={customStyles}
                                                            options={temporary(form)}
                                                            value={form.areaCode ? form.areaCode.map(areaCode => ({ value: areaCode.value, label: areaCode.label })) : null}
                                                            onChange={(selected) => handleChange(selected, 'areaCode')}  >

                                                        </Select>
                                                        {hasErrorMore?.length > 0 && <p className="mb-2" style={{ color: "red" }}>Number not available for {hasErrorMore?.map((item) => item.name).join(',')},Please remove from selection</p>}

                                                    </div>
                                                ) : null

                                            }
                                            {
                                                form?.areaCode && selectedCardForQuanity == QUANTITYSELECTION.AREACODE.label && (

                                                    <div className="flex flex-wrap ">

                                                        {form.areaCode.map(value => (

                                                            <div key={value.value} className="flex items-center   " style={{ border: `1px solid ${value.quantity === '' || hasError?.map((item) => item.name).includes(value.value) ? 'red' : 'white'}`, borderRadius: "3px", marginRight: "5px", marginBottom: "8px" }}>
                                                                <div className={`${hasError?.map((item) => item.name).includes(value.value) ? "bg-red text-white px-2" : "bg-primary text-white   px-2"} `}>{value.label}</div>

                                                                <input
                                                                    type="number"
                                                                    // min="1"
                                                                    className="w-8 border-none focus:outline-none"
                                                                    style={{color:"white",backgroundColor:"black"}}
                                                                    // placeholder="Qty"
                                                                    value={value.quantity}
                                                                    onChange={(e) => handleChangeQuantity(value.value, e.target.value)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            {
                                                selectedCardForQuanity && selectedCardForQuanity == QUANTITYSELECTION.QUANTITY.label && (
                                                    <div>
                                                        <label className="block text-gray-700 text-sm font-bold mb-4">{QUANTITYSELECTION.QUANTITY.label}<span style={{ color: "red" }}>*</span></label>
                                                        <Input className=" mb-4 ps-3 border border-gray-300 custom-input"
                                                            placeholder={`Enter ${QUANTITYSELECTION.QUANTITY.label}`} style={{ minHeight: "38px", borderRadius: "10px" }} value={form?.wholeQuantity} name="wholeQuantity" onChange={handleInputChange} />
                                                    </div>
                                                )
                                            }
                                        </> :
                                        <>
                                            <div>
                                                <label className="block text-gray-700 text-sm font-bold mb-4">Toll Free Prefix<span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    className="mb-4 rounded-lg border border-gray-300"
                                                    classNamePrefix="custom-select"
                                                    style={{ width: '100%' }}
                                                    name="prefix"
                                                    isMulti
                                                    styles={customStyles}
                                                    options={optionForTollFreePrefix}
                                                    value={form.prefix ? form.prefix.map(prefix => ({ value: prefix.value, label: prefix.label })) : []}
                                                    onChange={(selected) => handleChange(selected, 'prefix')}
                                                >

                                                </Select>
                                                {hasErrorMore?.length > 0 && <p className="mb-2" style={{ color: "red" }}>Number not available for {hasErrorMore?.map((item) => item.name).join(',')},Please remove from selection</p>}
                                            </div>
                                            {
                                                form?.prefix && (

                                                    <div className="flex flex-wrap ">

                                                        {form.prefix.map(value => (

                                                            <div key={value.value} className="flex items-center   " style={{ border: `1px solid ${value.quantity === '' || hasError?.map((item) => item.name).includes(value.value) ? 'red' : 'white'}`, borderRadius: "3px", marginRight: "5px", marginBottom: "8px" }}>
                                                                <div className={`${hasError?.map((item) => item.name).includes(value.value) ? "bg-red text-white px-2" : "bg-primary text-white   px-2"} `}>{value.label}</div>

                                                                <input
                                                                    type="number"
                                                                    // min="1"
                                                                    style={{color:"white",backgroundColor:"black"}}
                                                                    className="w-8 border-none focus:outline-none px-2"
                                                                    // placeholder="Qty"
                                                                    value={value.quantity}
                                                                    onChange={(e) => handleChangeQuantityTollFree(value.value, e.target.value)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            {/* <div>
                                                <label className="block text-gray-700 text-sm font-bold mb-4">{QUANTITYSELECTION.QUANTITY.label}<span style={{ color: "red" }}>*</span></label>
                                                <Input className=" mb-4 ps-3 border border-gray-300"
                                                    placeholder={`Enter ${QUANTITYSELECTION.QUANTITY.label}`} style={{ minHeight: "38px", borderRadius: "10px" }} value={form?.wholeQuantityTollFree} name="wholeQuantityTollFree" onChange={handleInputChange} />
                                            </div> */}

                                        </>
                                }
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-4">Route<span style={{ color: "red" }}>*</span></label>
                                    <Select
                                        className=" mb-4 rounded-lg border border-gray-300"
                                        classNamePrefix="custom-select"
                                        style={{ width: '100%' }}
                                        name="route"
                                        options={routeData.map(item => ({
                                            value: item?._id,
                                            label: item?.name
                                        }))}
                                        value={form.route ? { value: form.route.value, label: form.route.label } : null}
                                        onChange={(selected) => handleChange(selected, 'route')}  >

                                    </Select>
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-4">Group</label>
                                    <Select
                                        className=" mb-4 rounded-lg border border-gray-300"
                                        classNamePrefix="custom-select"
                                        style={{ width: '100%' }}
                                        name="group"
                                        options={groupData.map(item => ({
                                            value: item?._id,
                                            label: item?.name
                                        }))}
                                        value={form.group ? { value: form.group.value, label: form.group.label } : null}
                                        onChange={(selected) => handleChange(selected, 'group')}  >

                                    </Select>
                                </div>
                                <div className="flex items-center justify-end mt-4">
                                    <Button className={forOrder ? "bg-green text-white font-bold  rounded" : "bg-primary text-white flex items-center custom-ant-btn"} onClick={handleSubmit}>{forOrder ? `Order (${orderNumber.length}) Number` : "Check Number"}</Button>
                                </div>
                            </>
                    }




                </Card>
            </div>
        </>
    )
}
export default MultipleOrderNumber;
