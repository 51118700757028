import { Typography, Table, Card, Button, Spin, Input, Modal, Tabs, Pagination } from "antd";
import PaginationCommon from "../../components/PaginationCommon";
import { useEffect, useState } from "react";
import { ApiCall } from "../../utils/CommonFunctions";
import { toast } from "react-toastify";

const { Text } = Typography;

const Vendor = () => {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nameFieldValue, setNameFieldValue] = useState('')
    const [price, setPrice] = useState('')
    const [monthlyCommitment, setMonthlyCommitment] = useState('')
    const [priority, setPriority] = useState('')

    const [id, setId] = useState('')


    const [data, setData] = useState([])
    const columns = [
        { title: "Name", dataIndex: "name" },
        { title: "Price", dataIndex: "price" },
        { title: "Locked in Period", dataIndex: "monthly_commitment" },
        { title: "Priority", dataIndex: "prioirity" },

        { title: "Action", render: (text, record) => (<Button className="custom-ant-btn" onClick={() => handleModal(record)}>Edit</Button>), }
    ];
    const handleModal = (rowData) => {
        setIsModalVisible(!isModalVisible);
        setId(rowData?._id)
        setNameFieldValue(rowData?.name)
        setPrice(rowData?.price)
        setMonthlyCommitment(rowData?.monthly_commitment)
        setPriority(rowData?.prioirity)
    };
    const handleEdit = async () => {
        try {
            const updateField = {
                vendor_id: id,
                // name: nameFieldValue,
                price: price,
                monthly_commitment: monthlyCommitment,
                prioirity:parseFloat(priority) ,

            }
            const response = await ApiCall(
                "patch",
                "/vendor",
                updateField,
                "Vendor",
            )
            if (response !== undefined) {
                setIsModalVisible(false)
                const response1 = await ApiCall("get", "/vendor", null);
                setData(response1)
            }
        }
        catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error)
        }
    }
    const getContentForModal = () => {
        return (
            <>
                {/* <div className="mt-2 mb-2">
                    <label className="font-bold" >Name</label>
                </div>

                <Input className="custom-input" placeholder="Enter Name" name="name"
                    value={nameFieldValue} onChange={(e) => setNameFieldValue(e.target.value)}
                /> */}
                <div className="mt-2 mb-2">
                    <label className="font-bold" >Price</label>
                </div>

                <Input className="custom-input" placeholder="Enter price" name="price"
                    value={price} onChange={(e) => setPrice(e.target.value)}
                />
                <div className="mt-2 mb-2">
                    <label className="font-bold" >Locked in Period</label>
                </div>

                <Input className="custom-input" placeholder="Enter Locked in Period" name="commitment"
                    value={monthlyCommitment} onChange={(e) => setMonthlyCommitment(e.target.value)}
                />
                 <div className="mt-2 mb-2">
                    <label className="font-bold" >Priority</label>
                </div>
                   <Input className="custom-input" placeholder="Enter Priority" type="number" name="commitment"
                    value={priority} onChange={(e) => setPriority(e.target.value)}
                />


                <div className="flex justify-end mt-5 mb-2">
                    <Button onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisible(false)

                    }} className="border-primary border-1 text-primary rounded-md p-1 px-3 mr-3"
                    >
                        Cancel
                    </Button>{" "}
                    <Button type="submit" onClick={handleEdit} className=" custom-ant-btn p-1 px-3" >
                        {/* {loading && <Spin size="small" className="mr-3 custom-spin" />} */}
                        Update
                    </Button>
                </div>
            </>
        )
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await ApiCall("get", "/vendor", null);
                setData(response)
                setLoading(false)
            } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error)
            }
        };

        fetchData();
    }, []);
    return (
        <>
            {loading && (
                <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <Spin size="large" className="custom-spin" />
                </div>
            )}
            <Text className="text-2xl font-bold text-white">Vendor</Text>
            <div className="mt-3 bg-card-bg p-3">
                <div className="flex justify-end mb-3">
                    <Input size="middle" className="custom-input w-52" placeholder="Filter Vendor" />
                </div>
                <Table className="custom-table" columns={columns} dataSource={data} pagination={false} />
            </div>



            <Modal
                className="custom-modal"
                title="Edit Vendor"
                open={isModalVisible}
                onOk={handleModal}
                onCancel={handleModal}
                okButtonProps={{ className: "bg-primary" }}
                okText="Save"
                footer={<></>}
            >
                {getContentForModal()}
            </Modal>
        </>
    )
}
export default Vendor;