import axios from "../api/axios";
import { storedKeys } from "../utils/storedKeys";

const AuthService = {
  login: (email, password) => axios.post("/auth/login", { email, password }),
  register: (rest) => axios.post("/auth/register", { ...rest }),
  email:(email)=> axios.post("/auth/forgot-password", { email}),
  changePassword:(rest)=>axios.patch("/user/password", { ...rest }),
  adminChangePassword:(rest,user_id)=>axios.patch("/user/password", { ...rest,user_id }),

  resetPassword:(rest)=>axios.patch("/auth/reset-password", { ...rest }),
  isUserAuthentic: () => {
    let user = AuthService.activeUser();
    if (!user || !user.email || !user._id) return false;

    if (!localStorage.getItem(storedKeys.accessToken)) return false;

    return user;
  },
  activeUser: () => JSON.parse(localStorage.getItem(storedKeys.user)),
  logout: () => localStorage.clear(),
};

export default AuthService;
