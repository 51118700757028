import { Breadcrumb, Button, Card, Col, Input, Row, Slider, Typography, Flex, Progress, Spin, } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CloseOutlined } from '@ant-design/icons';
import axios from "../../api/axios"
import { toast } from "react-toastify";
import RegionCard from "./RegionCard";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ApiCall } from "../../utils/CommonFunctions";
import useAuth from "../../hooks/useAuth";
import CardDesign from "../../components/CardDesign";
import Select from 'react-select';
import { FaPlus, FaMinus } from "react-icons/fa";
import Header from "../../components/BreadCrumbs";

export default function DeployProxy() {
    const { auth, setAuth } = useAuth();
    const [selectedCard, setSelectedCard] = useState('');

    const [progress, setProgress] = useState(0)
    const [sliderValue, setSliderValue] = useState(1)
    const [regions, setRegions] = useState([])
    const [prize, setPrize] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([{ name: "", slug: "" }])
    const initialValues = {
        name: '',
        dialerIps: "",
    }
    const navigate = useNavigate();
    const [form, setForm] = useState(initialValues);
    const [inputValue, setInputValue] = useState('');
    const [carrierIpValue, setCarrierIpValue] = useState('');
    const [carrierIpValueError, setCarrierIpValueError] = useState(false);
    const [contactivityCarrierIp, setContactivityCarrierIP] = useState('')
    const [loading, setLoading] = useState(false);
    const [dialerIpValueError, setDialerIpValueError] = useState(false);
    const [values1, setValues1] = useState([]);
    const [carrieIpValues, setCarrieIpValues] = useState([]);

    const marks = {
        0: 0,
        10: sliderValue[1] === 0 ? 0 : sliderValue[1]
    }
    const handleOnChange = (newValue) => {
        setSliderValue(newValue)
    }
    const handleIncrease = () => {
        if (sliderValue < 10) {
            setSliderValue(sliderValue + 1);
        }
    };
    const handleDecrease = () => {
        if (sliderValue > 1) {
            setSliderValue(sliderValue - 1);
        }
    };
    const [tickPosition, setTickPosition] = useState(0);
    const handleChangeCarrieIp = (value) => {
        setContactivityCarrierIP(value)

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));

    }
    const handleInputChangeMultipe = (e) => {
        setInputValue(e.target.value);

    };
    const handleInputChangeCarrierMultipe = (e) => {
        setCarrierIpValue(e.target.value);
        setTickPosition(e.target.selectionStart)

    };
    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            processInput();
        }
    };

    const handleTickClick = () => {
        processInput();
    };

    const processInput = () => {
        if (inputValue.trim() && validateIPv4(inputValue.trim())) {
            setValues1([...values1, inputValue.trim()]);
            setInputValue('');
            setDialerIpValueError(false);
        } else {
            setDialerIpValueError(true);
        }
    };

    const handleInputKeyDownForCarrier = (e) => {
        if (e.key === 'Enter') {
            processCarrieInput();


        }
    }
    const processCarrieInput = () => {

        if (carrierIpValue.trim() && validateIPv4(carrierIpValue.trim()) && carrieIpValues.length === 0) {
            setCarrieIpValues([...carrieIpValues, carrierIpValue.trim()]);
            setCarrierIpValue('');
            setCarrierIpValueError(false)

        }
        else {
            setCarrierIpValueError(true);

        }
    }
    const handleTickCarrier = () => {
        processCarrieInput();
    }
    const validateIPv4 = (ip) => {
        const ipRegex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
        if (!ipRegex.test(ip)) {
            return false;
        }
        const parts = ip.split('.').map(Number);
        if (parts.some(part => part < 0 || part > 255)) {
            return false;
        }
        return true;
    };
    const handleRemoveValue = (index) => {
        setValues1(values1.filter((_, i) => i !== index));
    };
    const handleRemoveValueForCarrier = (index) => {
        setCarrieIpValues(carrieIpValues.filter((_, i) => i !== index))
    };
    useEffect(() => {
        getRegion();
        getPrize();
    }, []);
    const handleClick = (name) => {

        setSelectedCard(name);
    };
    const getRegion = async () => {
        try {
            const response = await axios.get("/proxy/regions");
            setRegions(
                response.data?.data.regions.map((e) => ({ ...e, key: e.SLUG }))
            );
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const getPrize = async () => {
        try {
            const response = await axios.get("/proxy/package");
            const packages = response.data.data.credit
            setPrize(packages)
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const [selectedOption, setSelectedOption] = useState('STANDARD');

    const handleButtonClick = (option) => {
        setSelectedOption(option);

    };
    const DeplOySchema = Yup.object({
        name: Yup.string().min(2).required("Please enter your name ")
    })
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: DeplOySchema,
        onSubmit: async (values) => {
            if (sliderValue == undefined || sliderValue == 0) {
                toast.error("Quantity is Required")
            }
            if (selectedCountry.slug == "" || selectedCountry.slug == undefined) {
                toast.error("Region is required")
                return
            }
            if (values1.length == "0") {
                toast.error("Atleat One dialer IP is required")
                return
            }
            if (selectedCard == 'BYOC' && carrieIpValues.length == "0") {
                toast.error("Carrier IP is required")
                return
            }
            if (selectedCard == 'Contactivity' && contactivityCarrierIp == "") {
                toast.error("Carrier IP is required")
                return
            }

            const addField = {
                name: values?.name,
                bring_own_carrier: selectedCard == 'Contactivity' ? false : true,
                slug: selectedCountry?.slug,
                quantity: sliderValue,
                carrier_ips: selectedCard == 'Contactivity' ? [contactivityCarrierIp.value] : carrieIpValues,
                dialer_ips: values1,
            }
            try {
                setLoading(true);
                const response = await ApiCall(
                    "post",
                    "/proxy",
                    addField,
                    "Proxy server"
                )
                if (response != undefined) {

                    setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
                    navigate("/proxy");
                }



            } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error);
            } finally {
                setLoading(false);
            }
        }
    })
    return (
        <>
        <Header mainPageName={"Proxy Server"}  subPageName={"Deploy Proxy"}/>
            <Row>
                {loading && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                        <Spin size="large" className="custom-spin" />
                    </div>
                )}
                <Col span={17} style={{ overflowY: 'auto', maxHeight: '80vh', paddingRight: '10px', scrollbarWidth: 'none', pointerEvents: loading ? 'none' : 'auto', filter: loading ? 'blur(2px)' : 'none', }}>
                    <div className="mt-7  p-7 me-2 bg-card-bg rounded " style={{ color: "white" }}>

                        <div className="mt-2">
                            <label className="font-bold" >Quantity</label>
                        </div>
                        <div className="flex items-center">
                            <span className="bg-primary"
                                style={{
                                    color: 'white',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <FaMinus onClick={handleDecrease} />
                            </span>
                            <Slider value={sliderValue} min={1} max={10} onChange={handleOnChange} style={{ width: "100%" }} className="custom-slider ml-3 mt-3 mr-3" />
                            <span className="bg-primary"
                                style={{
                                    color: 'white',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <FaPlus onClick={handleIncrease} />
                            </span>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>{sliderValue}</p>
                            <p>10</p>
                        </div>
                        <div className="mt-2 mb-2">
                            <label className="font-bold" >Name</label>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <Input className={`${touched.name && errors.name ? "border-red" : ""} custom-input`} placeholder="Enter Name" name="name" value={values.name} onChange={handleChange} />
                            {errors.name && touched.name && <div className="text-red text-[12px] pt-1"> {errors.name}</div>}
                        </form>
                        {/* <div className="mt-2">
                            <label className="font-bold" >Plan</label>
                        </div> */}
                        {/* <Row gutter={16} style={{ marginTop: "10px" }}>
                            <Col span={12}>
                                <Card
                                    style={{
                                        border: selectedOption === 'STANDARD' ? '2px solid blue' : '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        //   padding: 16,
                                        cursor: 'pointer',
                                        transition: 'border 0.2s',
                                    }}
                                    onClick={() => handleButtonClick('STANDARD')}
                                >
                                    <p>Standard</p>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card
                                    style={{
                                        border: selectedOption === 'PREMIUM' ? '2px solid blue' : '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                        transition: 'border 0.2s',
                                    }}
                                    onClick={() => handleButtonClick('PREMIUM')}
                                >
                                    <p>Premium</p>
                                </Card>
                            </Col>
                        </Row> */}
                        <div className="mt-2">
                            <label className="font-bold" >Region</label>
                        </div>
                        <div>
                            <RegionCard regions={regions}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <label className="font-bold" >Dialer IPs</label>
                        </div>
                        <div>
                            <div className="input-container">
                                <Input
                                    className="custom-input"
                                    placeholder="Enter multiple values and press Enter"
                                    value={inputValue}
                                    onChange={handleInputChangeMultipe}
                                    onKeyDown={handleInputKeyDown}
                                    style={{
                                        padding: '5px',
                                        borderRadius: '4px',
                                        marginBottom: '5px',
                                        border: dialerIpValueError ? '1px solid red !important' : '1px solid #d9d9d9 !important'
                                    }}
                                />
                                <button
                                    onClick={handleTickClick}
                                    className=""
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: "#de4300",
                                        border: "none",
                                        borderRadius: "0 8px 8px 0",
                                        width: "6rem",
                                        height: "2rem",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Add IP
                                </button>
                            </div>
                            <div>
                                {values1.map((value, index) => (
                                    <div key={index} className="tag" style={{ backgroundColor: 'black', padding: '2px 6px', borderRadius: '4px', display: 'inline-block', marginRight: '5px' }}>
                                        {value}
                                        <button onClick={() => handleRemoveValue(index)} style={{ marginLeft: '5px', cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}><CloseOutlined /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-2 mb-2">
                                    <label className="font-bold" >Carrier IP</label>
                                </div>
                        <Row gutter={16} className="mb-4 mt-4 flex items-center">
                            <Col span={8}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        border: selectedCard === 'Contactivity' ? '2px solid #de4300' : '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                        transition: 'border 0.2s',
                                        padding: '8px',
                                    }}
                                    onClick={() => handleClick("Contactivity")}
                                >
                                    <div style={{ flex: 1 }}>
                                        Contactivity
                                    </div>
                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                        <span className="bg-primary"
                                            style={{
                                                color: 'white',
                                                padding: '4px 8px',
                                                borderRadius: '4px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Free
                                        </span>
                                    </div>
                                </div>

                            </Col>
                            <Col span={8}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        border: selectedCard === 'BYOC' ? '2px solid #de4300' : '1px solid #d9d9d9',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                        transition: 'border 0.2s',
                                        padding: '8px',
                                    }}
                                    onClick={() => handleClick("BYOC")}
                                >
                                    <div style={{ flex: 1 }}>
                                        Other Carrier
                                    </div>
                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                        {prize?.bring_own_carrier === 0 ? (
                                            <span className="bg-primary"
                                                style={{
                                                    color: 'white',
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Free
                                            </span>
                                        ) : (
                                            <span className="bg-primary"
                                                style={{
                                                    color: 'white',
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                ${prize?.bring_own_carrier}
                                            </span>
                                        )}
                                    </div>
                                </div>


                            </Col>
                        </Row>
                        {
                            selectedCard == "Contactivity" &&
                            <>
                             
                                <Select
                                    classNamePrefix="custom-select"
                                    menuPlacement="top"
                                    style={{ width: "100%" }}
                                    placeholder="Please select Carrier IP"
                                    onChange={handleChangeCarrieIp}
                                    options={[
                                        {
                                            value: '88.151.128.95',
                                            label: '88.151.128.95',
                                        },
                                        {
                                            value: '88.151.130.103',
                                            label: '88.151.130.103'
                                        },
                                        {
                                            value: '88.151.132.98',
                                            label: '88.151.132.98'
                                        }
                                    ]}
                                >
                                </Select>
                            </>
                        }
                        {
                            selectedCard == "BYOC" &&
                            <>

                                <div>
                                    <div className="input-container">
                                        <Input
                                            className="custom-input"
                                            placeholder="Enter one values and press Enter"
                                            value={carrierIpValue}
                                            disabled={carrieIpValues.length === 1}
                                            onChange={handleInputChangeCarrierMultipe}
                                            onKeyDown={handleInputKeyDownForCarrier}
                                            style={{
                                                padding: '5px',
                                                borderRadius: '4px',
                                                marginBottom: '5px',
                                                border: carrierIpValueError ? '1px solid red' : '1px solid #d9d9d9'

                                            }}
                                        />
                                        <button
                                            onClick={handleTickCarrier}
                                            className=""
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: "#de4300",
                                                border: "none",
                                                borderRadius: "0 8px 8px 0",
                                                width: "6rem",
                                                height: "2rem",
                                                marginBottom: "5px"
                                            }}
                                        >
                                            Add IP
                                        </button>
                                    </div>
                                    <div>
                                        {carrieIpValues.map((value, index) => (
                                            <div key={index} className="tag" style={{ backgroundColor: 'black', padding: '2px 6px', borderRadius: '4px', display: 'inline-block', marginRight: '5px' }}>
                                                {value}
                                                <button onClick={() => handleRemoveValueForCarrier(index)} style={{ marginLeft: '5px', cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}><CloseOutlined /></button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Col>
                <Col span={7} style={{ position: 'sticky', top: '0', maxHeight: '80vh' }}>
                    <div className="mt-7 bg-card-bg p-7 rounded ms-5 white-text">
                        <div className="flex justify-between">
                            <p className="font-bold">Quantity</p>
                            <p>{sliderValue}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-bold">Name</p>
                            <p>{values.name}</p>
                        </div>
                        <div className="flex justify-between ">
                            <p className="font-bold">Region</p>
                            <p>{selectedCountry?.name}</p>
                        </div>
                        {
                            selectedCard &&
                            <div className="flex justify-between mb-2">
                                <p className="font-bold">{selectedCard == 'Contactivity' ? selectedCard : "Other Carrier"}</p>
                                <p>{selectedCard == 'Contactivity' ? "Free" : prize?.bring_own_carrier === 0 ? "Free" : `$${prize.bring_own_carrier}`}</p>
                            </div>
                        }

                        <hr />
                        <div className="flex justify-between mt-2">
                            <p className="font-bold">Total</p>
                            {/* <p>{selectedCard == "BYOC" ? (sliderValue  && (prize?.proxy * sliderValue )+ prize?.bring_own_carrier  ).toFixed(2) : (sliderValue && prize?.proxy * sliderValue).toFixed(2)}$/Month</p> */}
                            <p>
                                {selectedCard === "BYOC"
                                    ? (sliderValue && ((prize?.proxy * sliderValue) + prize?.bring_own_carrier) % 1 === 0
                                        ? (prize?.proxy * sliderValue) + prize?.bring_own_carrier
                                        : ((prize?.proxy * sliderValue) + prize?.bring_own_carrier).toFixed(2))
                                    : (sliderValue && (prize?.proxy * sliderValue) % 1 === 0
                                        ? prize?.proxy * sliderValue
                                        : (prize?.proxy * sliderValue).toFixed(2))
                                }
                                $/Month
                            </p>
                        </div>
                    </div>
                    <div className="mt-2 ms-5 rounded ">

                        <Button onClick={handleSubmit} type="submit" className="bg-primary text-white " style={{ width: "100%" }}>{loading ? <Spin size="smAll" className="custom-spin"></Spin> : 'Deploy'}</Button>
                    </div>
                </Col>
            </Row>


        </>
    )
}






