import React, { createContext, useState } from "react";

const initialState = { auth: {}, setAuth: () => {} };

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
