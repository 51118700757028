import Swal from "sweetalert2";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { message } from "antd";

export const  handleDeleteCommon= async (apiUrl,paramKey,otherData={},toastMessage,onSuccess)=>
  {
      Swal.fire({
          title: "Are you sure you want to delete?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            popup: 'custom-swal'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const requestData = otherData ? { ...paramKey, ...otherData } : paramKey;
              const response = await axios.delete(apiUrl, {  data: requestData })
              const errors=response.data.data.errors
              const success=response.data.data.success;
              const commonSuccess=response.data.data;
              if(commonSuccess === true)
                {
                  toast.success(`${toastMessage} deleted successfully`)
                  onSuccess();
                }
              if(errors.length>=1)
                  {
                      const allError=errors.map((item)=>
                      {
                      toast.error(item?.error)
  
                      })
                  }
                  if(success.length>=1)
                      {
                          toast.success(`${success.length}${toastMessage} deleted successfully`)
                      }
                      else{
                       return
                      }
                     
                  if (onSuccess) {
                      onSuccess();
                  }
            } catch (error) {
              const e = error?.response?.data?.title;
              toast.error(e || error);
              // onSuccess();
            }
        
          }
        });
      };








export const validateIPv4 = (ip) => {
  const ipRegex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
  if (!ipRegex.test(ip)) {
    return false;
  }
  const parts = ip.split('.').map(Number);
  if (parts.some(part => part < 0 || part > 255)) {
    return false;
  }
  return true;
};


    export const ApiCall = async(method,apiUrl,data= null,toastMessage=null,setLoading) => {
      try {
        switch(method)
        {
          case "post" :
            // setLoading(true)
              const response= await axios.post(apiUrl,data)
                if(toastMessage)
                  {
                toast.success(`${toastMessage} added succesfully`)
    
                  }
                  // setLoading(false)
              // })
              return response.data.data
            break; 
          case "patch" :
            try{
              // setLoading(true)
    
              const response = await axios.patch(apiUrl,data)
                toast.success(`${toastMessage} updated succesfully`)
                // setLoading(false)

                return response.data.data
            }
            catch(error)
            {
              const e = error?.response?.data?.title;
                toast.error(e || error);
            }
            break;
           
            case "get" : 
            try {
              // setLoading(true)
              const response= await axios.get(apiUrl,{params:data})
              // setLoading(false)
              return response.data.data
            }  catch(error)
            {
              const e = error?.response?.data?.title;
                toast.error(e || error);
            }
            break;
         }
      }
      catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
      }
    }




    export const scoreBadge=(risk_level)=>{
      if (risk_level === undefined || risk_level === null) {
        return {};
      } else if (risk_level <= 40) {
        return { display: 'inline-block', backgroundColor: '#28a745', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
      } else if (risk_level > 40 && risk_level <= 59) {
        return { display: 'inline-block', backgroundColor: '#f59e0b', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
      } else if (risk_level >= 60) {
        return { display: 'inline-block', backgroundColor: '#dc3545', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
      } else {
        return { display: 'inline-block', padding: '5px 10px', borderRadius: '5px' };
      }
    };



    export const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(
        () => {
          message.success('Copied to clipboard!');
        },
        (err) => {
          message.error('Failed to copy!');
        }
      );
    };