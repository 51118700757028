import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spin, Typography } from "antd";
import CreditService from "../../services/creditService";
import { toast } from "react-toastify";
import BuyCredits from "./buyCredit";
import UserService from "../../services/userService";
import useAuth from "../../hooks/useAuth";
const { Text } = Typography;

const Packages = () => {
  const buyCreditPermission = UserService.getPermission("buyCredit");
  const { auth, setAuth } = useAuth();
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buyCredit, setBuyCredit] = useState(false);
  const [userCredits, setUserCredits] = useState(null);
  const user_id = auth?._id;

  useEffect(() => {
    getAllCredits();
  }, []);

  useEffect(() => {
    if (auth?._id) getUserCredits(auth?._id);
  }, [auth?._id]);

  const getAllCredits = async () => {
    setLoading(true);
    try {
      const response = await CreditService.all(0, 100);
      setCredits(
        response.data?.data.credits.map((e) => ({ ...e, key: e._id }))
      );
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      setUserCredits(response.data?.data);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  if (loading) return <Spin  className="custom-spin"/>;

  return (
    <>
      <Text className="text-2xl font-bold">Packages</Text>
      <br />
      <br />
      <Text className="text-2xl font-bold flex justify-center">
        {" "}
        You're Off to a Great Start!
      </Text>
      {/* {userCredits?.credit && (
        <Text className="text-xl flex justify-center">
          You have remaining credits of{" "}
          <span className="font-bold ml-1 mr-1 ">
            {" "}
            {userCredits?.remaining}{" "}
          </span>{" "}
          and you have used{" "}
          <span className="font-bold ml-1 "> {userCredits?.used}</span>.
        </Text>
      )} */}

      <br />
      <Row gutter={[16, 16]}>
        {credits.map((credit) => {
          const isCreditPurchased = UserService?.isAdmin()
            ? false
            : credit?._id === userCredits?.credit?._id;
          return (
            <Col key={credit._id} sm={24} lg={8}>
              <Card
                className={`bg-card-bg flex items-center justify-center flex-col ${
                  isCreditPurchased && "border-2 border-primary"
                }`}
              >
                <div>
                  <Text className="font-bold">
                    Credit Name :{" "}
                    <Text className="font-normal">{credit?.name}</Text>
                  </Text>
                </div>
                <div className="mt-3">
                  <Text className="">Total Credit : {credit?.credits}</Text>
                </div>
                {buyCreditPermission && (
                  <Button
                    className="bg-primary text-white w-full mt-5"
                    onClick={() => setBuyCredit(credit)}
                  >
                    {`Buy ${credit?.amount}$`}
                  </Button>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
      <BuyCredits
        open={buyCredit}
        onClose={() => setBuyCredit(false)}
        onSuccess={() => {
          setAuth((e) => ({
            ...e,
            refreshCredits: e?.refreshCredits ? false : true,
          }));
          getUserCredits(user_id);
        }}
      />
    </>
  );
};

export default Packages;
