import NotFound from "./components/Notfound";
import PrivateLayout from "./components/PrivateLayout";
import PublicLayout from "./components/PublicLayout";
import SignIn from "./pages/auth/SignIn";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Signup from "./pages/auth/SignUp";
import Credits from "./pages/credits/credit";
import Dashboard from "./pages/dashboard/dashboard";
import Random from "./pages/dashboard/random";
import ViewGroupNumbers from "./pages/groups/viewGroupNumbers";
import ViewUserHistory from "./pages/users/ViewUserHistory";
import Groups from "./pages/groups/group";
import OrderNumber from "./pages/OrderNumbers";
import Route from "./pages/Route";
import SingleOrderNumber from "./pages/OrderNumbers/SingleOrderNumber";
import MultipleOrderNumber from "./pages/OrderNumbers/MultipleOrderNumber";
import CheckSingleNumber from "./pages/groups/checkSingleNumber"
import Packages from "./pages/packages/package";
import Users from "./pages/users/users";
import OrderHistory from "./pages/OrderHistory/orderHistory";
import Wallet from "./pages/Wallet/Wallet";
import History from "./pages/Settings/History/index";
import BillingSetting from "./pages/Settings/BillingSetting";
import AccountSetting from "./pages/Settings/AccountSetting"
import Proxy from "./pages/proxy";
import DeployProxy from "./pages/proxy/DeployProxy";
import SpecificProxyData from "./pages/proxy/SpecificProxyData";
import VerifyEmail from "./pages/auth/VerifyEmail";
import { elements } from "chart.js";
import ViewRouteNumbers from "./pages/Route/ViewRouteNumbers";
import UrlGeneration from "./pages/Settings/AccountSetting/Tabs/UrlGeneration";
import Vendor from "./pages/Vendor/Vendor";
const routes = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "/", element: <SignIn /> },
      { path: "/register", element: <Signup /> },
      { path: "/forgotpassword", element: <ForgotPassword /> },
      { path: "/reset-password/:token", element: <VerifyEmail /> }

    ],
  },
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/dashboard/random", element: <Random /> },
      { path: "/users", element: <Users /> },
      { path: "/users/:userId", element: <ViewUserHistory /> },
      { path: "/groups", element: <Groups /> },
      { path: "/ordernumber", element: <OrderNumber /> },
      { path: "/ordernumber/single", element: <SingleOrderNumber /> },
      { path: "/ordernumber/bulk", element: <MultipleOrderNumber /> },
      { path: "/packages", element: <Packages /> },
      { path: "/credit", element: <Credits /> },
      { path: "/vendor", element: <Vendor />},

      { path: "/groups/:groupId", element: <ViewGroupNumbers /> },
      { path: "/orderHistory", element: <OrderHistory /> },
      { path: "/route", element: <Route /> },
      { path: "/route/:routeId", element: <ViewRouteNumbers /> },

      { path: "/wallet", element: <Wallet /> },
      { path: "/settings/history", element: <History /> },
      { path: "/settings/billingSetting", element: <BillingSetting /> },
      { path: "/settings/accountSetting", element: <AccountSetting /> },
      { path: "/proxy", element: <Proxy /> },
      { path: "/proxy/deploy", element: <DeployProxy /> },
      { path: "/proxy/:proxyId", element: <SpecificProxyData /> },
      { path: "/calleridgeneration", element: <UrlGeneration /> },


      

      { path: "/number/status", element: <CheckSingleNumber /> },


    ],
  },
  {
    path: "*",
    element: <PublicLayout />,
    children: [{ path: "*", element: <NotFound /> }],
  },
];

export default routes;
