import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import DashboardService from "../../services/dashboardService";

const Random = () => {
  const [randomNum, setRandomNum] = useState(10);

  useEffect(() => {
    getRandomNum();
  }, []);

  const getRandomNum = async () => {
    try {
        const response = await DashboardService.randomNumber();
        setRandomNum(response.data);
    } catch (e) {}
  };

  return (
    <div className="h-full ">
      <Typography.Text className="text-2xl font-bold">Random Number</Typography.Text>
      <div className="flex items-center justify-center h-full mt-[-100px]">
        <h1 className="text-[3rem]">{randomNum}</h1>
      </div>
    </div>
  );
};

export default Random;
