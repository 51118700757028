import { Modal, Spin, Input, Row, Col, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CreditService from "../../services/creditService";
import CheckableTag from "antd/es/tag/CheckableTag";

const addGroupSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  amount: Yup.string().required("Amount is required").matches(/^\d*\.?\d*$/, 'Must be a valid number'),
  credits: Yup.string().required('Number Reputation price is required')
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
    // robo_killer: Yup.string().required('Robo Killer price is required')
    // .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    // .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
  additional_cost: Yup.string()
    .required("Proxy price is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
  local_number: Yup.string()
    .required("Local number price is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
    incoming_cost_local:Yup.string()
    .required("Incoming price per minute for local number is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
  toll_free_number: Yup.string()
    .required("Toll free number price is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
    commitment: Yup.string()
    .required("Locked in period is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-integer', 'Must be a valid number', (value) => Number.isInteger(parseFloat(value))) // Validates integer
    .test('is-in-range', 'Value must be between 1 and 12', (value) => {
      const num = parseInt(value, 10);
      return num >= 1 && num <= 12;
    }),
    incoming_cost:Yup.string()
    .required("Incoming price per minute for toll free is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
  byoc: Yup.string()
    .required("BYOC price is required")
    .matches(/^\d*\.?\d*$/, 'Must be a valid number')
    // .test('is-greater-than-zero', 'Price must be greater than 0', (value) => value !== undefined && parseFloat(value) > 0),
  // change_ip_time:Yup.string()
  // .required("Auto Change ip time is required")
});

const initialValues = {
  name: "",
  amount: '',
  credits: '',
  // robo_killer:"",
  additional_cost: '',
  local_number: '',
  incoming_cost_local:'',
  toll_free_number: '',
  commitment:'',
  incoming_cost:'',
  byoc: '',
  change_ip_time: '',
};

const AddPackage = ({ open, onClose, onSuccess, isEdit, data, setIsEdit }) => {
  const [isDefault, setIsDefault] = useState(true)
  const [changeIp, setChangeIp] = useState(false)
  const [custom, setIsCustom] = useState(false)
  const [callerIdGeneration, setCallerIdGeneration] = useState(false)




  const formik = useFormik({
    initialValues,
    validationSchema: addGroupSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      if (changeIp && values.change_ip_time == '') {
        toast.error("Auto change IP time is required ")
        return
      }
      setSubmitting(true);
      try {
        if (isEdit) {
          await CreditService.edit(callerIdGeneration,values.amount, values.name, values.credits, values.additional_cost, values.local_number,values.incoming_cost_local, values.toll_free_number, values.commitment,values.incoming_cost,values.byoc, changeIp, values.change_ip_time.toString(), isDefault, data[0]?._id,custom);


        }
        else {
          await CreditService.create(callerIdGeneration,values.amount, values.name, values.credits, values.additional_cost, values.local_number,values.incoming_cost_local, values.toll_free_number,values.commitment,values.incoming_cost ,values.byoc, changeIp, values.change_ip_time.toString(), isDefault,custom);

        }
        onSuccess();
        onClose();
        resetForm();
        setIsDefault(true)
        toast.success(isEdit ? "Package updated successfully " : "Package created successfully");
        setIsEdit(false)
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (data && isEdit) {
      formik.setFieldValue("name", data[0]?.name);
      formik.setFieldValue("amount", data[0]?.amount);
      formik.setFieldValue("credits", data[0]?.number_reputation);
      // formik.setFieldValue("robo_killer", data[0]?.robo_killer);
      formik.setFieldValue("additional_cost", data[0]?.proxy);
      formik.setFieldValue("local_number", data[0]?.local_number);
      formik.setFieldValue("incoming_cost_local", data[0]?.incoming_cost_per_minute_for_local);

      formik.setFieldValue("toll_free_number", data[0]?.toll_free_number);
      formik.setFieldValue("commitment", data[0]?.monthly_commitment);
      formik.setFieldValue("incoming_cost", data[0]?.incoming_cost_per_minute_for_toll_free);

      formik.setFieldValue("byoc", data[0]?.bring_own_carrier);
      formik.setFieldValue("change_ip_time", data[0]?.auto_change_ip_time);
      setChangeIp(data[0]?.auto_change_ip)
      setIsDefault(data[0]?.state);
      setIsCustom(data[0]?.custom)
      setCallerIdGeneration(data[0]?.caller_id_generation)
    }
  }, [data]);
  const handleChangeIsDefault = async (checked) => {
    setIsDefault(checked)
  }
  const handleChangeCallerIdGenration = async (checked) => {
    setCallerIdGeneration(checked)
  }
  const handleChangeCustom = async (checked) => {
    setIsCustom(checked)
  }
  const handleChangeIp = async (checked) => {
    if (checked) {
      setChangeIp(checked)

    }
    else {
      setChangeIp(checked)
      formik.setFieldValue("change_ip_time", "");
    }
  }

  return (
    <Modal className="custom-modal" title="Add Package" open={open} onOk={() => { }} onCancel={(e) => {
      e.preventDefault();
      onClose();
      formik.resetForm();
      setIsDefault(true)
      setIsEdit(false)
      setChangeIp(false)
      setIsCustom(false)

    }} okButtonProps={{ className: "bg-primary" }} okText="Save" footer={<></>}>
      <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <Row gutter={[16, 16]}>
          <Col className="mb-1" sm={24}>
            <label className="">Name</label>
            <Input type="name" name="name" placeholder="Name" {...formik.getFieldProps("name")} className={`${formik.touched.name && formik.errors.name ? "border-red" : "" } custom-input`} />
            {formik.touched.name && formik.errors.name && <div className="text-red text-[12px] pt-1"> {formik.errors.name}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Amount</label>
            <Input type="text" name="amount" placeholder="Amount" {...formik.getFieldProps("amount")} className={`${formik.touched.amount && formik.errors.amount ? "border-red" : "" } custom-input`} />
            {formik.touched.amount && formik.errors.amount && <div className="text-red text-[12px] pt-1"> {formik.errors.amount}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Number Reputation Price</label>
            <Input type="text" name="credits" placeholder="Number Reputation Price" {...formik.getFieldProps("credits")} className={`${formik.touched.credits && formik.errors.credits ? "border-red" : "" } custom-input`} />
            {formik.touched.credits && formik.errors.credits && <div className="text-red text-[12px] pt-1"> {formik.errors.credits}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            {/* <label className="">Robo Killer Price</label>
            <Input type="text" name="robo_killer" placeholder="Robo Killer Price" {...formik.getFieldProps("robo_killer")} className={`${formik.touched.robo_killer && formik.errors.robo_killer ? "border-red" : "" } custom-input`} />
            {formik.touched.robo_killer && formik.errors.robo_killer && <div className="text-red text-[12px] pt-1"> {formik.errors.robo_killer}</div>} */}
            <label className="">Caller ID Rotator</label>
            <Switch checked={callerIdGeneration}
              onChange={handleChangeCallerIdGenration} style={{ border: "1px solid lightgrey", marginLeft: "1rem" }} />
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Proxy Price</label>
            <Input name="additional_cost" step="0.01" placeholder="Proxy Price" {...formik.getFieldProps("additional_cost")} className={`${formik.touched.additional_cost && formik.errors.additional_cost ? "border-red" : "" } custom-input`} />
            {formik.touched.additional_cost && formik.errors.additional_cost && <div className="text-red text-[12px] pt-1"> {formik.errors.additional_cost}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Local Number Price</label>
            <Input name="local_number" step="0.01" placeholder="Local Number Price" {...formik.getFieldProps("local_number")} className={`${formik.touched.local_number && formik.errors.local_number ? "border-red" : "" } custom-input`} />
            {formik.touched.local_number && formik.errors.local_number && <div className="text-red text-[12px] pt-1"> {formik.errors.local_number}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Incoming Price Per Minute For Local Number</label>
            <Input name="incoming_cost_local" type="text" placeholder="Incoming Price Per Minute" {...formik.getFieldProps("incoming_cost_local")} className={`${formik.touched.incoming_cost_local && formik.errors.incoming_cost_local ? "border-red" : "" } custom-input`} />
            {formik.touched.incoming_cost_local && formik.errors.incoming_cost_local && <div className="text-red text-[12px] pt-1"> {formik.errors.incoming_cost_local}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Toll Free Number Price</label>
            <Input name="toll_free_number" step="0.01" placeholder="Toll Free Number Price" {...formik.getFieldProps("toll_free_number")} className={`${formik.touched.toll_free_number && formik.errors.toll_free_number ? "border-red" : "" } custom-input`} />
            {formik.touched.toll_free_number && formik.errors.toll_free_number && <div className="text-red text-[12px] pt-1"> {formik.errors.toll_free_number}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Incoming Price Per Minute For Toll Free</label>
            <Input name="incoming_cost" type="text" placeholder="Incoming Price Per Minute" {...formik.getFieldProps("incoming_cost")} className={`${formik.touched.incoming_cost && formik.errors.incoming_cost ? "border-red" : "" } custom-input`} />
            {formik.touched.incoming_cost && formik.errors.incoming_cost && <div className="text-red text-[12px] pt-1"> {formik.errors.incoming_cost}</div>}
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Locked In Period</label>
            <Input name="commitment" type="number" placeholder="Locked In Period" {...formik.getFieldProps("commitment")} className={`${formik.touched.commitment && formik.errors.commitment ? "border-red" : "" } custom-input`} />
            {formik.touched.commitment && formik.errors.commitment && <div className="text-red text-[12px] pt-1"> {formik.errors.commitment}</div>}
          </Col>
        
          <Col className="mb-1" sm={24}>
            <label className="">BYOC Price</label>
            <Input name="byoc" step="0.01" placeholder="BYOC Price" {...formik.getFieldProps("byoc")} className={`${formik.touched.byoc && formik.errors.byoc ? "border-red" : "" } custom-input`} />
            {formik.touched.byoc && formik.errors.byoc && <div className="text-red text-[12px] pt-1"> {formik.errors.byoc}</div>}
          </Col>

          <Col className="mb-1" sm={24}>
            <label className="">Change IP</label>
            <Switch checked={changeIp}
              onChange={handleChangeIp} style={{ border: "1px solid lightgrey", marginLeft: "1rem" }} />
          </Col>
          {
            changeIp &&
            <Col className="mb-1" sm={24}>
              <label className="">Auto Change IP Time</label>
              <Input name="change_ip_time" type="number" placeholder="Auto Change IP Time" {...formik.getFieldProps("change_ip_time")} className={`${formik.touched.change_ip_time && formik.errors.change_ip_time ? "border-red" : "" } custom-input`} />
              {formik.touched.change_ip_time && formik.errors.change_ip_time && <div className="text-red text-[12px] pt-1"> {formik.errors.change_ip_time}</div>}
            </Col>
          }
          <Col className="mb-1" sm={24}>
            <label className="">Status</label>
            <Switch checked={isDefault}
              onChange={handleChangeIsDefault} style={{ border: "1px solid lightgrey", marginLeft: "1rem" }} />
          </Col>
          <Col className="mb-1" sm={24}>
            <label className="">Custom</label>
            <Switch checked={custom}
              onChange={handleChangeCustom} style={{ border: "1px solid lightgrey", marginLeft: "1rem" }} />
          </Col>
        </Row>
        <div className="flex justify-end mt-5 mb-2">
          <button className="border-primary border-1 text-primary rounded-md p-1 px-3" disabled={formik.isSubmitting}
            // onClick={onClose}
            onClick={(e) => {
              e.preventDefault();
              onClose();
              setChangeIp(false)
              formik.resetForm();
              setIsEdit(false)
              setIsCustom(false)

            }}
          >
            Cancel
          </button>{" "}
          <button type="submit" className="bg-primary text-white rounded-md p-1 px-3" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small custom-spin" className="mr-3" />}
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddPackage;
