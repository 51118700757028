import React, { useEffect, useState } from "react";
import { Typography, Table, Card, Button, Spin, Input, Pagination } from "antd";
import OrderHistoryService from "../../../services/orderHistoryService";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import PaginationCommon from "../../../components/PaginationCommon";

const { Text } = Typography;

export default function UserTransactionHistory({ userId }) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offSet,setOffSet]=useState(0);
  const [limit,setLimit]=useState(10);
  const [count,setCount]=useState('');

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState();
  const [showpagination, setShowPagination] = useState(true);
  const [current, setCurrent] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [hasRun, setHasRun] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 20,
  });
  const filterUsers = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = orderHistory?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredUsers(result);
  };
  useEffect(() => {
    getOrderHistory(offSet, limit,userId);
  }, []);

  const getOrderHistory = async (offset, limit,userId) => {
    setLoading(true);
    try {
      const response = await OrderHistoryService.user(offset, limit, userId);
      setOrderHistory(
        response.data?.data.transications.map((e) => ({ ...e, key: e._id }))
      );
      setCount(response.data.data.total_count)

    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const columns = [
    {
      title: "Transaction Date",
      render: (_, record) => {
        const dateObj = moment(record.purchasing_date).tz("America/New_York");
        const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

        return <span>{formattedDate || "N/A"}</span>;
      },
    },
    {
      title: "Expiry Date",
      render: (_, record) => {
        // const expiryDate = record.expiry_date
        //   ? `0${record?.card_id?.expiration_month}/${record?.card_id?.expiration_year}`
        //   : "N/A";
        // return <span>{expiryDate || "N/A"}</span>;
        const dateObj = moment(record.expiry_date).tz("America/New_York");
        const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

        return <span>{formattedDate == "Invalid date" ? "N/A" : formattedDate || "N/A"}</span>;
      },
    },{
      title: "Service Type",
      render: (_, record) => {
        {
          const serviceType = record?.service_type ? `${record?.service_type}` : "N/A";
          return <span>{serviceType}</span>;
        }
      },
    },
    {
      title: "Count",
      render: (_, record) => {
        {
          const count = record?.count ? `${record?.count}` : "N/A";
          return <span>{count}</span>;
        }
      },
    },
    { title: "Description", dataIndex: "description" },
    {
      title: "Amount",
      render: (_, record) => {
        {
          const amount = record?.amount ? `$${record?.amount}` : "N/A";
          return <span>{amount}</span>;
        }
      },
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    getOrderHistory(offset, pageSize);
    setPagination({ ...pagination, current });
  };
  const handleOnChange = (page, pageSize) => {
    getOrderHistory(page - 1, pageSize)
    setPage(page)
    setPagination({ ...pagination, current: page });

  };
  return (
    <>
      <div className="mt-3 bg-card-bg p-3">
        <div className="flex justify-end mb-3">
          <Input
          className="custom-input w-52"
            value={searchValue}
            onChange={filterUsers}
            size="middle"
            placeholder="Filter Users"
          />
        </div>
        <Table
        className="custom-table"
          loading={loading}
          columns={columns}
          dataSource={searchValue ? filteredUsers : orderHistory}
          pagination={false} onChange={handleTableChange}
          scroll={{ x: true }}

        />
       <PaginationCommon total={count}  data={orderHistory} handleChange={getOrderHistory} id={userId} />
      </div>
    </>
  );
}
