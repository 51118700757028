import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Spin } from 'antd';
import { UserOutlined, MailOutlined, BankOutlined } from "@ant-design/icons";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

export default function Profile() {
    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState();
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState([])

    useEffect(() => {
        getUserProfile();
    }, []);
    const getUserProfile = async () => {
        setLoading(true);
        try {
            const response = await axios.get("/user/profile");
            setProfileData(
                response.data?.data
            );
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
        setLoading(false);
    };
    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {

        try {
            const response = await axios.patch("/user/profile", editedUser);
            toast.success("Profile updated successfull")
            getUserProfile()
            setIsEditing(false);

        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    };

    const handleChange = (key, value) => {
        setEditedUser((prevUser) => ({ ...prevUser, [key]: value }));
        setProfileData((prevData) => ({ ...prevData, [key]: value }));
    };
    if (loading) return <Spin  className="custom-spin"/>;

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Name</label>
                        <Input
                        className="custom-input"
                            prefix={<UserOutlined />}
                            value={profileData?.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}
                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Company</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.company}
                            onChange={(e) => handleChange("company", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Email</label>
                        <Input
                        className="custom-input"
                            prefix={<MailOutlined />}
                            value={profileData?.email}
                            onChange={(e) => handleChange("email", e.target.value)}
                            disabled
                            style={{ fontWeight: "bold" }}
                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Phone</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.phone}
                            onChange={(e) => handleChange("phone", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                {/* Add more fields in the same way */}
            </Row>
            {/* Add an edit button */}
            <Row gutter={16}>
                <Col span={24}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Address</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.address}
                            onChange={(e) => handleChange("address", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                {/* <Col span={12}> 

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            balance
                        </label>
                        <Input
                            prefix={<BankOutlined />}
                            value={`$${profileData?.balance}`}
                            onChange={(e) => handleChange("compan", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col> */}
            </Row>
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">City</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.city}
                            onChange={(e) => handleChange("city", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">State</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.state}
                            onChange={(e) => handleChange("state", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>



            </Row>
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Zip</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.zip}
                            onChange={(e) => handleChange("zip", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block white-text text-gray-700 text-sm font-bold mb-2">Country</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.country}
                            onChange={(e) => handleChange("country", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <Col >
                    {!isEditing && (
                        <Button className="bg-primary text-white rounded-md p-1 px-3 custom-ant-btn" onClick={handleEdit}>
                            Edit
                        </Button>
                    )}
                    {isEditing && (
                        <Button className="bg-primary text-white rounded-md p-1 px-3 custom-ant-btn" onClick={handleSave}>
                            Save
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
}
