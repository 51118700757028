import React, { createContext, useContext, useState } from "react";

const RowLoadingContext = createContext();

export const useRowLoading = () => {
  const context = useContext(RowLoadingContext);
  if (!context) {
    throw new Error("useRowLoading must be used within a RowLoadingProvider");
  }
  return context;
};

export const RowLoadingProvider = ({ children }) => {
  const [rowLoadingStates, setRowLoadingStates] = useState({});

  const setRowLoading = (rowId, isLoading) => {
    setRowLoadingStates((prev) => ({
      ...prev,
      [rowId]: isLoading,
    }));
  };

  return (
    <RowLoadingContext.Provider value={{ rowLoadingStates, setRowLoading }}>
      {children}
    </RowLoadingContext.Provider>
  );
};
