import React, { useEffect, useState } from "react";
import { Breadcrumb, Modal, Table, Tabs, Typography } from "antd";
import GroupService from "../../services/groupService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TabPane from "antd/es/tabs/TabPane";
import UserProfile from "./Tabs/UserProfile";
import UserCidGroup from "./Tabs/UserCidGroup";
import UserTransactionHistory from "./Tabs/UserTransactionHistory";
import UserCreditHistory from "./Tabs/UserCreditHistory";
import PackegesHistory from "./Tabs/PackageHistory";
import ChangePassword from "../Settings/AccountSetting/Tabs/ChangePassword"
import OrderNumber from "../OrderNumbers";
import Route from "../Route";
import Proxy from "../proxy"

import { LeftOutlined } from "@ant-design/icons";
import { act } from "react";
const ViewUserHistory = () => {
  const navigate = useNavigate();
  // const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const { state } = useLocation();
  const userId = state?.group?._id;
  const userName = state?.group?.name;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialTab = query.get('tab') || '1';
  const [activeTab, setActiveTab] = useState(initialTab);
  useEffect(() => {
    const queryTab = query.get('tab');
    if (queryTab) {
      setActiveTab(queryTab);
    }
  }, [location.search]);
  return (
    <>
      <div className="flex items-center justify-between">
      <div className="flex item-center">
                    <button
                        onClick={() => navigate(-1)}
                        className="bg-primary text-white rounded-md p-1 ms-1 me-3"
                    >
                        <LeftOutlined />
                    </button>
        <Typography.Text className="text-2xl font-bold text-white">{userName} </Typography.Text></div>
        <Breadcrumb className="custom-breadcrumb text-white font-bold"
    items={[
      {
        title: 'Customer',
      },
      {
        title: activeTab==1 ? <span className="text-white">Profile</span> : activeTab==2 ? <span className="text-white">Number Reputation</span> : activeTab==3 ? <span className="text-white">Number List</span> : activeTab ==4 ? <span className="text-white">Route</span>  : activeTab == 5 ? <span className="text-white">Proxy</span> : activeTab == 6 ? <span className="text-white">Transaction History</span> : activeTab == 7 ? <span className="text-white">Packages History</span> : <span className="text-white">Change Password</span>,
      },
    ]} />
      </div>

      <br />
      <Tabs className="custom-tabs" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Profile" key="1">
          <UserProfile userId={userId} />
        </TabPane>
        <TabPane tab="Number Reputation" key="2">
          <UserCidGroup userId={userId} activeKey={activeTab} userStateData={state}/>
        </TabPane>
        <TabPane tab="Number List" key="3">
          <OrderNumber userId={userId} />
        </TabPane>
        <TabPane tab="Route" key="4">
          <Route userId={userId} />
        </TabPane>
        <TabPane tab="Proxy" key="5">
          <Proxy userId={userId} />
        </TabPane>
        <TabPane tab="Transaction History" key="6">
          <UserTransactionHistory userId={userId} />
        </TabPane>
         
        {/* <TabPane tab="Credit History" key="4">
          <UserCreditHistory userId={userId} />
        </TabPane> */}
        <TabPane tab="Packages History" key="7">
          <PackegesHistory userId={userId} />
        </TabPane>
        <TabPane tab="Change Password" key="8">
          <ChangePassword userId={userId} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ViewUserHistory;
