import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useState } from "react";
import NumberReputation from "./PackageHistoryTabs/NumberReputation";
export default function PackegesHistory(
    { userId }
){
    const [activeTab, setActiveTab] = useState("1");
    const handleTabChange = (key) => {
      setActiveTab(key);
    };
    return(
        <>
          <NumberReputation userId={userId} />
        {/* <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Number Reputation Packages" key="1">
          <NumberReputation userId={userId} />
        </TabPane>
        <TabPane tab="Proxy Packages" key="2">
          <UserProfile userId={userId} />
        </TabPane>
        <TabPane tab="Order Number Packages" key="3">
          <UserProfile userId={userId} />
        </TabPane>
      </Tabs> */}
        </>
    )
}