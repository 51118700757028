import axios from "../api/axios";

const PackageService = {
  buy: (data) => axios.post("/credit/card", data),
  purchase: (data) => axios.post("/credit/purchase", data),
  balance:(data)=>axios.post("/credit/balance",data)

};

export default PackageService;
