import { Card, Input, Spin, Typography } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LogoForOutside from "../../images/logo.png"
const { Text } = Typography;

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
});

const initialValues = {
  email: "",
};
const ForgotPassword = () => {
    
    const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await AuthService.email(values.email);
        const user = response.data?.data;
        toast.success("Email sent! please check your email.")
        navigate("/")
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });
    return(
      <>
  
        <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",  height: "100vh", backgroundColor:"black" }}>
        <div >
      <img style={{height: "10rem" ,marginBottom: "-3rem"}} src={LogoForOutside}></img>
      </div>
        <Card className="mx-auto p-8 w-90 md:w-[600px] bg-card-bg text-white border border-solid border-border">
          <div className="mb-8">
            <h1 className="text-3xl font-bold">Forgot Password</h1>
            {/* <Text className="text-gray-500">Welcome! Web Portal</Text> */}
          </div>
          <form
            className=""
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            <div className="mb-3">
              <label className="">Email</label>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                {...formik.getFieldProps("email")}
                className={`${
                  formik.touched.email && formik.errors.email ? "border-red":""} custom-input`}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-red text-[12px] pt-1">
                  {" "}
                  {formik.errors.email}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-[100%] bg-primary text-white rounded-md p-1 hover-none"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting && <Spin size="small" className=" custom-spin mr-3" />}
              Reset password
            </button>
            <div onClick={() => navigate("/")} className="font-bold text-primary mb-8 cursor-pointer text-right mt-3">
            Signin
          </div>
          </form>
        </Card>
        <div className="mt-4 " >
      <p style={{ color:"#6a7684" ,fontSize:"x-small", marginBottom:"2rem"}}>© Copyright 2024 | All Rights Reserved | v 1.0</p>
    </div>
      </section>
      </>
    );
    
}
export default ForgotPassword;
