import { Button, Input, Pagination, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { DeleteOutlined,CopyOutlined } from "@ant-design/icons";
import { copyToClipboard, handleDeleteCommon } from "../../utils/CommonFunctions";
import ChangeRoute from "./ChangeRoute";
import ChangeGroup from "./ChangeGroup";
import UserService from "../../services/userService";
import useAuth from "../../hooks/useAuth";
import CreditService from "../../services/creditService";
import PaginationCommon from "../../components/PaginationCommon";
import { scoreBadge } from "../../utils/CommonFunctions";
const { Text } = Typography;
const OrderNumber = ({ userId }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  const [allOrderNumbers, setAllOrderNumbers] = useState([])
  const [loading, setLoading] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10);
  const [changeRoute, setChangeRoute] = useState(false);
  const [changeGroup, setChangeGroup] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [userCredits, setUserCredits] = useState(null);



  useEffect(() => {
    if (auth?._id) {
      getUserCredits(auth?._id)

    }
    // getAllOrderNumbers(offset, 10)
  }, [auth?._id])
  const getUserCredits = async (id) => {
    try {
      const response = await CreditService.userCredit(id);
      getAllOrderNumbers(offset, limit, response)
      setUserCredits(response.data?.data.userCredits);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };

  const getAllOrderNumbers = async (offset, limit, userCredits = null) => {
    try {
      let response;
      setLoading(true)

      if (UserService.isAdmin()) {
        response = await axios.get(`/group/ordered/numbers?offset=${offset}&limit=${limit}&user_id=${userId}`);
      } else {
        response = await axios.get(`/group/ordered/numbers?offset=${offset}&limit=${limit}`);
        if (userCredits && userCredits.data.data.userCredits?.length === 0 && response.data.data.numbers.length === 0) {
          navigate("/settings/history");
        }
      }

      setAllOrderNumbers(response.data?.data.numbers.map((e) => ({ ...e, key: e._id })));
      setCount(response.data?.data.total_count);
      setLoading(false)

    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
      setLoading(false)

    }
  }
  const [selectAll, setSelectAll] = useState(false);
  const [wholeSelect, setWholeSelect] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [count, setCount] = useState("")
  const handleCheckboxChange = (key) => {
    let updatedSelectedKeys;
    if (selectedKeys.includes(key)) {
      updatedSelectedKeys = selectedKeys.filter((k) => k !== key);
    } else {
      updatedSelectedKeys = [...selectedKeys, key];
    }

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(updatedSelectedKeys.length === allOrderNumbers.length);
    if (selectedKeys.length < 10) {
      setWholeSelect(false)
    }
  };
  const handleSelectAllChange = (checked) => {
    const updatedSelectedKeys = selectAll ? [] : allOrderNumbers.map((record) => record._id);

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(!selectAll);
  };
  const wholeSelectAllChange = () => {
    if (selectAll) {
      setWholeSelect(!wholeSelect)
    }
    else {
      const updatedSelectedKeys = allOrderNumbers.map((record) => record._id);
      setSelectedKeys(updatedSelectedKeys);
      setWholeSelect(!wholeSelect)
      setSelectAll(!selectAll);

    }

  }
  const columns = [

    {
      title: (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {
              allOrderNumbers.length > 0 && !UserService.isAdmin() ?
                <div
                  className="checkBoxDesign"
                  onClick={(e) => handleSelectAllChange(e.target.value)}
                  style={{
                    backgroundColor: selectedKeys?.length >= 1 ? '#de4300' : 'transparent',
                    color: selectedKeys?.length >= 1 ? '#fff' : '#000',
                  }}
                >
                  {selectAll ? "✓" : selectedKeys?.length >= 1 ? "-" : ""}
                </div> : null
            }


          </div>

        </>
      ),
      dataIndex: 'checkbox',
      render: (_, record) =>
        !UserService.isAdmin() && (
          <input type="checkbox"
            className="checkbox-custom"
            checked={selectedKeys.includes(record._id)}
            style={{ marginLeft: "1rem", backgroundColor: "#de4300 !important", color: "#de4300" }}
            onChange={(e) => handleCheckboxChange(record._id)}

          />
        ),
    },
    {
      title: "Order Date",
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => {
        const timestamp = record?.createdAt;
        const dateObj = new Date(timestamp);
        const dateString = dateObj.toISOString().split('T')[0];
        return <span>{record?.createdAt == "Invalid date" ? "N/A" : dateString}</span>;
      },
    },
    //   {
    //     title: "Expiry Date",
    //     render: (_, record) => {
    //         const timestamp = record?.createdAt;
    //         const dateObj = new Date(timestamp);

    //         if (isNaN(dateObj.getTime())) {
    //             return <span>N/A</span>;
    //         }
    //         dateObj.setMonth(dateObj.getMonth() + 3);
    //         const dateString = dateObj.toISOString().split('T')[0];

    //         return <span>{dateString}</span>;
    //     },
    // },
    { title: "Phone Number", dataIndex: "phone_number", sorter: (a, b) => (a.phone_number || '').localeCompare(b.phone_number || ''),render: (text) => (
      <span>
        {text || 'N/A'}  <CopyOutlined
          onClick={() => copyToClipboard(text || '')}
        />
      </span>
    ), },
    {
      title: "State/Country",
      dataIndex: "state",
      sorter: (a, b) => (a.state || '').localeCompare(b.state || ''),
      render: (_, record) => {
        {
          const state = record?.state ? `${record?.state}` : "N/A";
          return <span>{state}</span>;
        }
      },
    },
    {
      title: 'Score',
      dataIndex: 'risk_level',
      sorter: (a, b) => (a.risk_level || '').localeCompare(b.risk_level || ''),
      render: (_, record) => {
        const badgeStyle = scoreBadge(record?.risk_level);
        return (
          <span>
            <div style={badgeStyle}>
              {record?.risk_level ? record?.risk_level : 'N/A'}
            </div>
          </span>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "",
      // sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
      render: (_, record) => {
        {
          const type = record?.type == 'toll_free' ? `${record?.credit_id ? `$${record?.credit_id?.toll_free_number}` :'N/A'}` : `${record?.credit_id ? `$${record?.credit_id?.local_number}` :'N/A'}`;
          return <span>{type}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
      render: (_, record) => {
        {
          const type = record?.type == 'toll_free' ? 'Toll Free' : 'Local';
          return <span>{type}</span>;
        }
      },
    },
    {
      title: "Route", dataIndex: "route_id?.name",
      sorter: (a, b) => (a.route_id?.name || '').localeCompare(b.route_id?.name || ''), render: (_, record) => {
        {
          const route = record?.route_id?.name ? `${record?.route_id?.name}` : "N/A";
          return <span>{route}</span>;
        }
      },
    },
    {
      title:
        <Tooltip title={"Number Reputation Group "}>
          Group
        </Tooltip>
      , dataIndex: "group_id?.name",
      sorter: (a, b) => (a.group_id?.name || '').localeCompare(b.group_id?.name || ''), render: (_, record) => {
        {
          const group = record?.group_id?.name ? `${record?.group_id?.name}` : "N/A";
          return <span>{group}</span>;
        }
      },
    },

  ]
  const handleDelete = () => {
    handleDeleteCommon(
      "group/ordered/numbers",
      { "numbers": selectedKeys },
      { "all": wholeSelect },
      selectedKeys?.length == 1 ? "Number" : "Numbers",
      () => getAllOrderNumbers(offset, 10)
    )
  }
  return (
    <>

      {
        !UserService.isAdmin() &&
        <div className="flex items-center justify-between">
          <Text className="text-2xl font-bold  white-text">Number List</Text>
          <div className="flex items-center gap-4">
            <Button
              onClick={() => navigate("/ordernumber/single")}
              className="custom-ant-btn"
              disabled={userCredits?.length == 0}
              style={{
                backgroundColor:  userCredits?.length==0 ? '#ccc' : '',
                color:   userCredits?.length==0 ? '#666' : '',
              }}
            >
              Single Order
            </Button>
            <Button
              onClick={() => navigate("/ordernumber/bulk")}
              className="custom-ant-btn"
              disabled={userCredits?.length == 0}
              style={{
                backgroundColor:  userCredits?.length==0 ? '#ccc' : '',
                color:   userCredits?.length==0 ? '#666' : '',
              }}
            >
              Bulk Order
            </Button>
          </div>
        </div>

      }

      <div className="mt-3 bg-card-bg p-3">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start" >
            {
              selectedKeys.length >= 1 &&
              <>
                <div className="flex items-center ms-4">
                  {selectedKeys?.length >= 1 && (
                    <>
                      <div
                        className="checkBoxDesign mr-3"
                        onClick={(e) => wholeSelectAllChange(e.target.value)}
                        style={{
                          backgroundColor: selectAll ? '#de4300' : 'transparent',
                          color: selectedKeys?.length >= 1 ? '#fff' : '#000',
                        }}

                      >
                        {wholeSelect ? "✓" : selectAll ? "-" : ""}

                      </div>
                      <div className="white-text">{` Select All ${count}`}</div>
                    </>

                  )}
                  <Button className="custom-ant-btn text-white font-bold  rounded ms-3 me-3" style={{ backgroundColor: "red" }} icon={<DeleteOutlined />} onClick={handleDelete}>  Cancel Number</Button>
                  <Button className="custom-ant-btn ms-2 me-3" icon={<i className="fas fa-exchange" aria-hidden="true"></i>} onClick={() => setChangeRoute(true)} >  Change Route</Button>
                  <Button className="custom-ant-btn ms-2 me-3" icon={<i className="fas fa-exclamation-triangle" aria-hidden="true"></i>} onClick={() => setChangeGroup(true)} >  Change Reputation Group</Button>
                </div>
              </>
            }
          </div>

          <div className="flex flex-grow justify-end mb-3">
            {loading && (
              <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                <Spin size="large" className="custom-spin" />
              </div>
            )}

            <Input
              // value={searchValue}
              // onChange={filterUsers}
              size="middle"
              className="w-52 custom-input"
              placeholder="Filter Users"
            />
          </div>
        </div>
        <Table
          className="custom-table"
          // loading={loading}
          columns={columns}
          dataSource={allOrderNumbers}
          pagination={false}
        />
      </div>
      <PaginationCommon total={count} data={allOrderNumbers} handleChange={getAllOrderNumbers} />
      <ChangeRoute wholeSelect={wholeSelect} selectedKeys={selectedKeys} open={changeRoute} onClose={() => setChangeRoute(false)} onSuccess={() => { getAllOrderNumbers(offset, 10); setSelectedKeys([]); setWholeSelect(false); setSelectAll(false); }} />
      <ChangeGroup wholeSelect={wholeSelect} selectedKeys={selectedKeys} open={changeGroup} onClose={() => setChangeGroup(false)} onSuccess={() => { getAllOrderNumbers(offset, 10); setSelectedKeys([]); setWholeSelect(false); setSelectAll(false); }} />


    </>
  )
}
export default OrderNumber;