import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Layout, Menu, Button, Dropdown } from "antd";
import {
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HistoryOutlined,
  CreditCardOutlined,
  SettingOutlined,
  DollarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "use-media-antd-query";
import { FaUserAlt } from "react-icons/fa";
import AuthService from "../services/authService";
import { RiLogoutBoxLine } from "react-icons/ri";
import useAuth from "../hooks/useAuth";
import { MdGroupWork } from "react-icons/md";
import { ImUsers } from "react-icons/im";
import { TbPackages } from "react-icons/tb";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import UserService from "../services/userService";
import CreditService from "../services/creditService";
import { toast } from "react-toastify";
import OrderHistoryService from "../services/orderHistoryService";
import axios from "../api/axios";  
import logo from "../images/logo.png" 
const { Header, Content, Sider } = Layout;

const PrivateLayout = ({ children }) => {
  const getCredits = UserService.getPermission("getCredits"),
    getAllGroups = UserService.getPermission("getAllGroups"),
    getUsers = UserService.getPermission("getUsers"),
    getOrderHistory = OrderHistoryService.getPermission("orderHistory");

  const colSize = useMediaQuery();
  const navigate = useNavigate();
  const user = AuthService.activeUser();
  const { auth, setAuth } = useAuth();
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [userCredits, setUserCredits] = useState(null);
  const [userCardAndCreditData,setUserCardAndCredit]=useState([])
  const [openKeys, setOpenKeys] = useState([]);
  useEffect(() => {
    const user = AuthService.isUserAuthentic();
    setIsUserVerified(user);
    setAuth(user);
  }, []);
  useEffect(() => {
    if (auth?._id) getUserCredits(auth?._id);
  }, []);
  useEffect(() => {
    if (auth && auth?._id && !UserService.isAdmin()) getUserCredits(auth?._id);
  }, [auth, auth?.refreshCredits]);
 useEffect(()=>{
  getCreditCount()
 },[auth,setAuth])
 const getCreditCount=async()=>{
  try{
    const response = await axios.get(`/credit/credits-count`)
    setUserCardAndCredit(
      response.data?.data
    );
  }catch(error){
    const e = error?.response?.data?.title;
      toast.error(e || error);
  }

 }
const balance = userCardAndCreditData?.balance?.toString();
  const formattedBalance = balance?.includes('.')
  ? balance?.replace(/(\.\d{2})\d+$/, '$1')
  : balance;
  const getUserCredits = async (id) => {
    if (!id) return;
    try {
      const response = await CreditService.userCredit(id);
      setUserCredits(response.data?.data.userCredits);
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  };
  useEffect(() => {
    if (colSize === "sm" || colSize === "xs") setCollapsed(true);
    else setCollapsed(false);
  }, [colSize]);

  if (!isUserVerified) return <Navigate to="/" />;
  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };
  const items = [
    {
      label: "Dashboard",
      key: 1,
      icon: <DashboardOutlined />,
      onClick: () =>{navigate("/dashboard");
      setOpenKeys([]);
      },
      route: "/dashboard",
    },
  ];
  const isAdmin = UserService.isAdmin();
  // if (!isAdmin)
  //   items.push({
  //     label: "Packages",
  //     key: 4,
  //     icon: <TbPackages />,
  //     onClick: () => navigate("/packages"),
  //     route: "/packages",
  //   });
  if (getUsers)
    items.push({
      label: "Customers",
      key: 3,
      icon: <ImUsers />,
      onClick: () => navigate("/users"),
      route: "/users",
    });
  if (isAdmin)
    items.push({
      label: isAdmin ? "Packages" : "Credit",
      key: 5,
      icon: <BsCreditCard2FrontFill />,
      onClick: () => navigate("/credit"),
      route: "/credit",
    },
    {
      label:"Vendor",
      key:16,
      icon:<BsCreditCard2FrontFill />,
      onClick: () => navigate("/vendor"),
      route: "/vendor",

    }
  );
    if (!isAdmin)
    items.push({
      label: "Numbers",
      key: 12,
      icon: <i className="fas fa-phone" aria-hidden="true" style={{ color: 'white' }}></i>,
      children:[{
        label:"Number List",
        key:13,
        icon:<i className="fas fa-list-alt" aria-hidden="true"></i>,
        onClick: () => {
          navigate("/ordernumber");
      },
      route: "/ordernumber",
      },
      {
        label:"Route",
        key:14,
        icon:<i className="fas fa-exchange" aria-hidden="true"></i>,
        onClick: () => {
          navigate("/route");
      },
      route: "/route",
      }]
    });
  if (getAllGroups)
    items.push({
      label: "Number Reputation",
      key: 2,
      icon:  <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>,
      onClick: () => {
        // if (userCredits.length > 0) {
          navigate("/groups");
          setOpenKeys([]);
        // } else {
          // navigate("/settings/history")
        // }
      },
      route: "/groups",
    });
    if (!isAdmin)
{
  items.push({
    label: "Proxy Server",
      key: 18,
      icon: <ImUsers />,
      onClick: () =>{ navigate("/proxy");
      setOpenKeys([]);

      },
      route: "/proxy",
  })
}
  if (!isAdmin)
{
  items.push({
    label: "Settings",
    key: 8,
    icon: <SettingOutlined />,
    children: [
      {
        label: "Billing",
        key: 9,
        icon: <CreditCardOutlined />,
        onClick: () =>{
          navigate("/settings/billingSetting");
        } ,
        route: "/settings/billingSetting",
       
      },
      {
        label: "Pricing",
        key: 10,
        icon: <DollarOutlined />,
        onClick: () => {
          navigate("/settings/history");
        },
        route: "setting/history",
      },
      {
        label: "Account",
        key: 11,
        icon: <UserOutlined />,
        onClick: () => {navigate("/settings/accountSetting");
        },
        route: "setting/accountSetting",


      },
    ],
    // onClick: () => navigate("/wallet"),
    // route: "/wallet",
    
  });

}

  const selectedKey = items.find(
    (e) => e?.route == window.location.pathname
  )?.key;

  const handleLogout = () => {
    setAuth(null);
    AuthService.logout();
    navigate("/");
  };
  const profileDD = (
    <Dropdown
      menu={{
        items: [
          {
            label: "Logout",
            key: 1,
            icon: <RiLogoutBoxLine style={{ width: 15, height: 15 }} />,
            onClick: handleLogout,
          },
        ],
      }}
      className="mr-5"
      overlayStyle={{ marginTop: -50 }}
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <FaUserAlt style={{ width: 20, height: 20 }} />
        <div className="font-semibold mt-1 text-lg">{user?.name}</div>
      </div>
    </Dropdown>
  );

  return (
    <Layout className="flex min-h-[100vh]">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: "#0D0E12" ,
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
        overflow: "auto",
      }}
      >
        {/* <div className="bg-gray h-20 ml-1 mr-1 mt-2 mb-5 rounded-md"  /> */}
        <img src={logo} style={{ paddingLeft:"1rem" ,paddingRight:"1rem"}}></img>
        <Menu
          theme="dark"
          openKeys={openKeys}
          mode="inline"
          defaultSelectedKeys={[selectedKey?.toString()]}
          style={{ backgroundColor: "#0D0E12" }}
          items={items}
          onOpenChange={handleOpenChange}

        />
      </Sider>
      <Layout>
        <Header
          className="flex items-center justify-between white-text"
          style={{ padding: 0, background: "#1e1d1d" }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ fontSize: "16px", width: 44, height: 44, marginLeft: 20 }}
          />
          <div className="flex items-center gap-5">
            {userCredits && !isAdmin && (
              <h1>
                <span className="text-1xl font-semibold white-text">
                  {/* Balance ${auth?.balance}&nbsp; &nbsp;|&nbsp;  */}
                  {/* Balance ${formattedBalance}&nbsp; &nbsp; */}
                  {formattedBalance < 0 ? `Balance -$${formattedBalance*-1}`:`Balance $${formattedBalance}`}&nbsp; &nbsp;
                  
                  {/* Credits {userCardAndCreditData?.remainingCredits} */}
                  {/* {userCredits[0]?.used ? userCredits[0]?.used :"0"} / {userCredits[0]?.remaining ? userCredits[0]?.remaining :"0"}{" "} */}
                </span>
                {/* Credits */}
              </h1>
            )}
            {profileDD}
          </div>
        </Header>
        <Content style={{ margin: "0px 0px 0px 200px", padding: 30, minHeight: 280 ,backgroundColor:"black"}}>
          {children}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
