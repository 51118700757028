import React, { useEffect, useState } from "react";
import { Breadcrumb, Checkbox, Modal, Pagination, Spin, Table, Typography } from "antd";
import GroupService from "../../services/groupService";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteOutlined, CopyOutlined, LeftOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import PaginationCommon from "../../components/PaginationCommon";
import Header from "../../components/BreadCrumbs"
import { copyToClipboard, scoreBadge } from "../../utils/CommonFunctions";
import UserService from "../../services/userService";
const { Text } = Typography;

const ViewGroupNumbers = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const group = state?.group;
  const [loading, setLoading] = useState(false);
  const [nums, setNums] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [wholeSelect, setWholeSelect] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [count, setCount] = useState("")
  const handleCheckboxChange = (key) => {
    let updatedSelectedKeys;
    if (selectedKeys.includes(key)) {
      updatedSelectedKeys = selectedKeys.filter((k) => k !== key);
    } else {
      updatedSelectedKeys = [...selectedKeys, key];
    }

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(updatedSelectedKeys.length === nums.length);
    if (selectedKeys.length < 10) {
      setWholeSelect(false)
    }
  };
  const handleDelete = () => {
    if (selectedKeys.length == 0) {
      toast.error("Atleast check one record to delete")
    }
    else {
      Swal.fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: 'custom-swal'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (wholeSelect) {
            try {
              const response = await axios
                .delete("/group/numbers", { data: { group_id: group?._id, numbers: [] } })
              await getGroupNumbers(offSet, limit, group?._id);
              toast.success("Numbers deleted successfully");
              setSelectedKeys([])
              setSelectAll(false)
            } catch (error) {
              const e = error?.response?.data?.title;
              toast.error(e || error);
            }
          }
          else {
            if (selectedKeys.length == 1) {
              try {
                const response = await axios
                  .delete("/group/numbers", { data: { numbers: selectedKeys, group_id: null } })
                await getGroupNumbers(offSet, limit, group?._id);
                toast.success("Number deleted successfully");
                setSelectedKeys([])
                setSelectAll(false)
              } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error);
              }
            }
            else {
              try {
                const response = await axios
                  .delete("/group/numbers", { data: { numbers: selectedKeys, group_id: null } })
                await getGroupNumbers(offSet, limit, group?._id);
                toast.success("Numbers deleted successfully");
                setSelectedKeys([])
                setSelectAll(false)
              } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error);
              }
            }

          }

        }
      });
    }
  }
  const singleDelete = (_id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const response = await axios
            .delete("/group/numbers", { data: { numbers: [_id], group_id: null } })
          await getGroupNumbers(offSet, limit, group?._id);
          toast.success("Number deleted successfully");
          setSelectedKeys([])
          setSelectAll(false)
        } catch (error) {
          const e = error?.response?.data?.title;
          toast.error(e || error);
        }
      }
    });
  }
  useEffect(() => {
    if (group) getGroupNumbers(offSet, limit, group?._id,);
    else navigate("/groups");
  }, [group]);

  const getGroupNumbers = async (offset, limit, groupId = null) => {
    setLoading(true);
    try {
      const response = await GroupService.groupNumbers(groupId, offset, limit);
      setNums(response.data?.data?.numbers?.map((e, _) => ({ ...e, key: _ })));
      setCount(response.data.data.total_count)
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleSelectAllChange = (checked) => {
    const updatedSelectedKeys = selectAll ? [] : nums.map((record) => record._id);

    setSelectedKeys(updatedSelectedKeys);
    setSelectAll(!selectAll);
  };
  const wholeSelectAllChange = () => {
    if (selectAll) {
      setWholeSelect(!wholeSelect)
    }
    else {
      const updatedSelectedKeys = nums.map((record) => record._id);
      setSelectedKeys(updatedSelectedKeys);
      setWholeSelect(!wholeSelect)
      setSelectAll(!selectAll);

    }

  }
  const columns = [
    {
      title: (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >

            <div
              onClick={(e) => handleSelectAllChange(e.target.value)}
              style={{
                borderRadius: "3px",
                width: '13px',
                height: '13px',
                border: '1px solid #000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: "1rem",
                backgroundColor: selectedKeys?.length >= 1 ? '#de4300' : 'transparent',
                color: selectedKeys?.length >= 1 ? '#fff' : '#000',
              }}
            >
              {selectAll ? "✓" : selectedKeys?.length >= 1 ? "-" : ""}
            </div>
            {selectedKeys?.length >= 1 && (
              <>
                <div onClick={(e) => wholeSelectAllChange(e.target.value)}
                  style={{
                    borderRadius: "3px",
                    width: '13px',
                    height: '13px',
                    border: '1px solid #000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: "1rem",
                    marginRight: "4px",

                    backgroundColor: selectAll ? '#de4300' : 'transparent',
                    color: selectedKeys?.length >= 1 ? '#fff' : '#000',
                  }}

                >
                  {wholeSelect ? "✓" : selectAll ? "-" : ""}

                </div>
                {` Select All ${count}`}
              </>

            )}
            {selectedKeys?.length >= 1 && (
              <>
                <div> <DeleteOutlined onClick={handleDelete} style={{ color: 'red', marginLeft: "1rem" }} /></div>
              </>
            )}
          </div>

        </>
      ),
      dataIndex: 'checkbox',
      render: (_, record) => (
        <input type="checkbox" className="checkbox-custom" checked={selectedKeys.includes(record._id)} style={{ marginLeft: "1rem" }}
          onChange={(e) => handleCheckboxChange(record._id)}

        />
      ),
    },
    {
      title: "Phone Number", dataIndex: "phone_number", sorter: (a, b) => (a.phone_number || '').localeCompare(b.phone_number || ''), render: (text) => (
        <span>
          {text || 'N/A'}  <CopyOutlined
            onClick={() => copyToClipboard(text || '')}
          />
        </span>
      ),
    },
    {
      title: "Country", dataIndex: "country",
      sorter: (a, b) => (a.country || '').localeCompare(b.country || ''),

      render: (_, record) => {
        {
          const country = record?.country ? `${record?.country}` : "N/A";
          return <span>{country}</span>;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "is_black_list",
      sorter: (a, b) => {
        const statusA = String(a.is_black_list);
        const statusB = String(b.is_black_list);
        return statusA.localeCompare(statusB);
      },
      render: (_, record) => {
        const isBlackList = record?.is_black_list ? 'Spam' : 'Clean';
        const textColor = isBlackList === 'Spam' ? '#dc3545' : '#28a745';
        const borderColor = isBlackList === 'Spam' ? 'red' : 'green';
        const style = {
          // color: textColor,
          backgroundColor: textColor, color: 'white',
          border: `1px solid ${borderColor}`,
          padding: '2px 8px',
          borderRadius: '3px',
          display: 'inline-block',
        };
        return <span style={style}>{isBlackList}</span>;
      },
    },
    {
      title: 'Score',
      dataIndex: 'risk_level',
      sorter: (a, b) => (a.risk_level || '').localeCompare(b.risk_level || ''),
      render: (_, record) => {
        const badgeStyle = scoreBadge(record?.risk_level);
        return (
          <span>
            <div style={badgeStyle}>
              {record?.risk_level ? record?.risk_level : 'N/A'}
            </div>
          </span>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        {
          // const country = record?.country ? `${record?.country}` : "N/A";
          return <span><div> <DeleteOutlined onClick={() => singleDelete(record._id)} style={{ color: 'red', marginLeft: "1rem" }} /></div></span>;
        }
      },
    },
  ];
  return (
    <>
      {loading && (
        <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <Spin size="large" className="custom-spin" />
        </div>
      )}
      <div className="flex items-center justify-between white-text">
        <div className="flex item-center">
          {
            UserService.isAdmin() ? (
              <button
                onClick={() => navigate(`/users/${state?.userId}?tab=2`, { state: { group: state?.userStateData?.group } })}
                className="bg-primary text-white rounded-md p-1 ms-1 me-3"
              >
                <LeftOutlined />
              </button>
            ) : (
              <button
              onClick={() => navigate(-1)}
              className="bg-primary text-white rounded-md p-1 ms-1 me-3">
                <LeftOutlined />

                </button>
            )
          }
          <Text className="text-2xl font-bold white-text">{group?.name}</Text></div>

        <Breadcrumb className="custom-breadcrumb font-bold"
          items={[
            {
              title: 'Number Reputation',
            },
            {
              title: <span style={{ color: 'white' }}>Groups Numbers</span>
            }
          ]}
        />
      </div>
      {/* <Header mainPageName={'Number Reputation'} subPageName={'Groups Numbers'} heading={group?.name} /> */}
      <br />
      <Table columns={columns} dataSource={nums} pagination={false} className="custom-table" />
      <PaginationCommon total={count} data={nums} handleChange={getGroupNumbers} id={group?._id} />
    </>
  );
};

export default ViewGroupNumbers;
