import React, { useState, useEffect } from "react";
import {
  //   DollarCircleOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Table,
  Card,
  Button,
  Spin,
  Input,
  Modal,
  Select,
  Checkbox,
} from "antd";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import AddFund from "./addFund";
const { Text } = Typography;

export default function Wallet() {
  const { auth, setAuth } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [cardData, setCardData] = useState([]);
  const [addCard, setAddCard] = useState(false);
  const [buyCredit, setBuyCredit] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllUserCards();
  }, []);
  const getAllUserCards = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/credit/cards");
      setCardData(response.data?.data.cards.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const getFormContent = () => {
    // if (type !== "manual")
    return (
      <form
      //   onSubmit={handleStripeFormSubmit}
      >
        <p className="ms-20 mb-4">
          Current Balance <QuestionCircleOutlined />{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${auth?.balance}
        </p>
        {/* <label className="">Amount</label> */}
        <div>
          <Input
            type="number"
            name="amount"
            placeholder="Amount"
            style={{
              width: "50%",
              marginBottom: "1rem",
              // margin: "2rem",
              marginLeft: "5rem",
            }}
          />
        </div>
        <div>
          <Select
            value={type}
            onChange={(value) => setType(value)}
            style={{
              width: "50%",
              marginBottom: "1rem",
              // margin: "2rem",
              marginLeft: "5rem",
            }}
            placeholder="Select a card"
          >
            <Select.Option value="" disabled>
              Select a card
            </Select.Option>
            {cardData.map((item) => (
              <Select.Option key={item._id} value={item._id}>
                {item.card_number}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Checkbox
            style={{
              marginLeft: "5rem",
            }}
            onChange={() => setAddCard((prev) => !prev)}
          >
            Add Card
          </Checkbox>
        </div>
        {addCard && <CardElement className="StripeElement mt-5" />}
        <div className="flex justify-end mt-5 mb-2">
          <button
            type="button"
            className="border-primary border-1 text-primary rounded-md p-1 px-3"
            onClick={handleCloseModal}
          >
            Cancel
          </button>{" "}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-1 px-3"
            // disabled={!stripe || !elements || loading}
          >
            {loading && <Spin size="small" className="custom-spin mr-3" />}
            Purchase
          </button>
        </div>
      </form>
    );
  };
  return (
    <>
      <Text className="text-2xl font-bold">Wallet</Text>
      <div className="mt-3 bg-white p-3 " style={{ height: "17rem" }}>
        <p className="font-bold mb-2">
          <CreditCardOutlined />
          &nbsp; Account Balance
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            height: "1px",
            border: "none",
          }}
        ></hr>
        <p className="p-20 " style={{ paddingLeft: "17rem" }}>
          Balance &nbsp;
          <QuestionCircleOutlined />
          &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $
          {auth?.balance}
        </p>
        <Button
          className="bg-primary text-white "
          style={{ left: "34rem", position: "absolute", bottom: "15rem" }}
          onClick={handleOpenModal}
          //   onClick={() => setBuyCredit()}
        >
          Add Fund
        </Button>
      </div>
      <div className="flex justify-end mb-3"></div>
      <Modal
        title="Add Fund"
        visible={isModalVisible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        okButtonProps={{ className: "bg-primary" }}
        okText="Save"
        footer={<></>}
      >
        <br />
        {getFormContent()}
      </Modal>
      {/* <AddFund
        open={buyCredit}
        onClose={() => setBuyCredit(false)}
        onSuccess={() => {
          setAuth((e) => ({
            ...e,
            refreshCredits: e?.refreshCredits ? false : true,
          }));
          //   getUserCredits(user_id);
        }}
      /> */}
    </>
  );
}
