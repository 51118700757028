import { useParams } from 'react-router-dom';
import React from "react";
import { Card, Col, Input, Row, Spin, Typography } from "antd";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import AuthService from '../../services/authService';
import { toast } from "react-toastify";
// import useAuth from "../../../../hooks/useAuth";
// import { storedKeys } from "../../../../utils/storedKeys";
import { useNavigate } from "react-router-dom";
import LogoForOutside from "../../images/logo.png"

const { Text, } = Typography;
const { TextArea } = Input;

const initialValues = {  password: "",rePassword:"" };
const registrationSchema = Yup.object().shape({
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  rePassword: Yup.string().required("Re-Password is required").oneOf([Yup.ref("password"), null], "Passwords must match"),
  
});
const VerifyEmail = () => {
    let  { token } = useParams();
    // const tokenWithoutColon = token.substring(1);
    const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      const { rePassword, ...formData } = values;
      formData.token = token;
      setSubmitting(true);
      try {
        await AuthService.resetPassword(formData);
        toast.success("Password changed successfully");
        navigate("/")
        resetForm();
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

    return(
        <>
      {/* <div style={{ display: "flex", justifyContent: "center" , height:"9rem",marginBottom:"-10.5rem"}}>
      <img src={LogoForOutside}></img>
      </div> */}
        {/* <div className='flex justify-center items-center h-screen'> */}
        <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",   height: "100vh", backgroundColor:"black"  }}>
        <div >
      <img style={{height: "10rem" ,marginBottom: "-3rem"}} src={LogoForOutside}></img>
      </div>
      <Card className="mx-auto p-8 w-90 md:w-[600px] bg-card-bg text-white border border-solid border-border">
      <div className="mb-8">
            <h1 className="text-3xl font-bold">Forgot Password</h1>
            {/* <Text className="text-gray-500">Welcome! Web Portal</Text> */}
          </div>
        <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
          <Row gutter={[16, 16]}>
            <Col  sm={24}>
              <label className="capitalize">Password</label>
              <Input type="password" name="password" placeholder="Password" {...formik.getFieldProps("password")} className={`${formik.touched.password && formik.errors.password ? "border-red":""} custom-input`} />
              {formik.touched.password && formik.errors.password && <div className="text-red text-[12px] pt-1"> {formik.errors.password}</div>}
            </Col>
            <Col  sm={24}>
              <label className="capitalize">Re-enter Password</label>
              <Input type="password" name="rePassword" placeholder="Re-Password" {...formik.getFieldProps("rePassword")} className={`${formik.touched.rePassword && formik.errors.rePassword ? "border-red":""} custom-input`} />
              {formik.touched.rePassword && formik.errors.rePassword && <div className="text-red text-[12px] pt-1"> {formik.errors.rePassword}</div>}
            </Col>

           
          </Row>

          <button type="submit" className="w-[100%] bg-primary text-white rounded-md p-1 mt-6 hover-none" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small" className="mr-3 custom-spin " />}
            Change Password
          </button>

        
        </form>
      </Card>
      <div className="mt-4 " >
      <p style={{ color:"#6a7684" ,fontSize:"x-small", marginBottom:"2rem"}}>© Copyright 2024 | All Rights Reserved | v 1.0</p>
    </div>
    </section>
       {/* </div> */}
        </>
    )
}
export default VerifyEmail;