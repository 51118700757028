import { Modal, Spin, Input, } from "antd";
import React from "react";
import { toast } from "react-toastify";
import GroupService from "../../services/groupService";
import * as Yup from "yup";
import { useFormik } from "formik";

const addRouteSchema = Yup.object().shape({
  name: Yup.string().required("Route name is required"),
});

const initialValues = {
  name: "",
};

const AddRoute = ({ open, onClose, onSuccess }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: addRouteSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        await GroupService.addRoute(values.name);
        onSuccess();
        onClose();
        resetForm()
        toast.success("Route created successfully");
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal className="custom-modal" title="Add Route" open={open} onOk={() => {}} onCancel={() => {
      onClose();
      formik.resetForm();
    }} okButtonProps={{ className: "bg-primary" }} okText="Save" footer={<></>}>
      <form className="" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <div className="mb-3">
          <label className="">Name</label>
          <Input type="name" name="name" placeholder="Name" {...formik.getFieldProps("name")} className={`${formik.touched.name && formik.errors.name ? "border-red" : ""} custom-input`} />
          {formik.touched.name && formik.errors.name && <div className="text-red text-[12px] pt-1"> {formik.errors.name}</div>}
        </div>
        <div className="flex justify-end mt-5 mb-2">
          <button className="border-primary border-1 text-primary rounded-md p-1 px-3" disabled={formik.isSubmitting}
          //  onClick={onClose}
          onClick={(e) => {
            e.preventDefault(); 
            onClose(); 
            formik.resetForm();
          }}
           >
            Cancel
          </button>{" "}
          <button type="submit" className="bg-primary text-white rounded-md p-1 px-3 custom-ant-btn" disabled={formik.isSubmitting}>
            {formik.isSubmitting && <Spin size="small" className="mr-3 custom-spin" />}
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddRoute;
