import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Card } from 'antd';
import { UserOutlined, MailOutlined, BankOutlined,DollarOutlined } from "@ant-design/icons";
// import axios from "../../../../api/axios";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

export default function UserProfile({userId}) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState();
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState([])

    useEffect(() => {
        getUserProfile();
    }, []);
    const getUserProfile = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`user/profile?user_id=${userId}`);
            setProfileData(
                response.data?.data
                // .map((e) => ({ ...e, key: e._id }))
            );
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
        setLoading(false);
    };
    const handleEdit = () => {
        setIsEditing(true);
    };
    const balance = profileData?.balance?.toString();
    const formattedBalance = balance?.includes('.')
    ? balance?.replace(/(\.\d{2})\d+$/, '$1')
    : balance;
    const handleSave = () => {
        setIsEditing(false);
   
        const addField={
            name:editedUser?.name,
            email:editedUser?.email,
            company:editedUser?.company,
            phone:editedUser?.phone,
            country:editedUser?.country,
            city:editedUser?.city,
            address:editedUser?.address,

        }
        // Implement logic to save changes
        // e.g., call an API to update user data
    };

    const handleChange = (key, value) => {
        setEditedUser((prevUser) => ({ ...prevUser, [key]: value }));
        setProfileData((prevData) => ({ ...prevData, [key]: value }));
    };

    return (
        <Form layout="vertical" className="text-white">
            <Row gutter={16}>
                <Col span={12}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                        <Input
                        className="custom-input"
                            prefix={<UserOutlined />}
                            value={profileData?.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}
                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                        <Input
                        className="custom-input"
                            prefix={<MailOutlined />}
                            value={profileData?.email}
                            onChange={(e) => handleChange("email", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Company</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.company}
                            onChange={(e) => handleChange("company", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.phone}
                            onChange={(e) => handleChange("phone", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                {/* Add more fields in the same way */}
            </Row>
            {/* Add an edit button */}
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Country</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.country}
                            onChange={(e) => handleChange("country", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">City</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.city}
                            onChange={(e) => handleChange("city", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Address</label>
                        <Input
                        className="custom-input"
                            prefix={<BankOutlined />}
                            value={profileData?.address}
                            onChange={(e) => handleChange("address", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col>
                {/* <Col span={12}> 

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            balance
                        </label>
                        <Input
                            prefix={<BankOutlined />}
                            value={`$${profileData?.balance}`}
                            onChange={(e) => handleChange("compan", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                </Col> */}
            </Row>
            {/* <Row>
                <Col>
                    {!isEditing && (
                        <Button type="primary" onClick={handleEdit}>
                            Edit
                        </Button>
                    )}
                    {isEditing && (
                        <Button type="primary" onClick={handleSave}>
                            Save
                        </Button>
                    )}
                </Col>
            </Row> */}
            <hr></hr>
            <div className="mb-4">
                        <label className="block text-gray-700 text-sm mt-2 font-bold mb-2">
                            balance
                        </label>
                        <Input
                        className="custom-input"
                            value={`$${formattedBalance}`}
                            onChange={(e) => handleChange("compan", e.target.value)}
                            disabled={!isEditing}
                            style={{ fontWeight: "bold" }}

                        />
                    </div>
                         {/* <Row gutter={16}>
                    <Col span={12} >
                        <div className="m-5 d-flex justify-content-between align-items-center">
                        <h3>Account Balance</h3>
                        <h2>{profileData?.balance}</h2>
                        </div>
                    </Col>
                </Row> */}
        </Form>
    );
}
