import { Option } from "@material-tailwind/react";
import { Breadcrumb, Button, Card, Col, Pagination, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { SelectCommon } from "../../components/Commons";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import { COUNTRIES, AREACODE, FONE_NUMBERS_TYPE, COUNTRY } from "../../utils/enums"
import axios from "../../api/axios";
import CardDesign from "../../components/CardDesign";
import GroupService from "../../services/groupService";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Header from "../../components/BreadCrumbs";

const SingleOrderNumber = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();

    const initialValues = {
        country: "",
        numberType: "",
        state: "",
        areaCode: "",
        searchOption: "",
        searchOptionWith: "",
        prefix: "",
        route: "",
        group: "",
    }
    const [form, setForm] = useState(initialValues)
    const [label, setLabel] = useState(initialValues)
    const [selectedCard, setSelectedCard] = useState(FONE_NUMBERS_TYPE.LOCAL.label);
    const [selectedCardCountry, setSelectedCardCountry] = useState(null);
    const [numbers, setNumbers] = useState([])
    const [routeData, setRouteData] = useState([])
    const [groupData, setGroupData] = useState([])
    const [forOrder, setForOrder] = useState(false)
    const [orderNumber, setOrderNumbers] = useState([])
    const [hasError, setHasError] = useState([])
    const [loading, setLoading] = useState(false)
    const [isClearable, setIsClearable] = useState(true);
    const [selectedNumber, setSelectedNumber] = useState();
    useEffect(() => {
        getStateData()
        getRouteData()
    }, [])
    const getStateData = async () => {
        try {
            const response = await GroupService.all(0, -1);
            setGroupData(response.data?.data.groups.map((e) => ({ ...e, key: e._id })));
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const getRouteData = async () => {
        try {
            const response = await GroupService.allRoute(0, -1);
            setRouteData(response.data?.data.routes.map((e) => ({ ...e, key: e._id })));
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }

    const GroupDataOptions = groupData.map(item => ({
        value: item?._id,
        label: item?.name
    }))
    const RouteDataOptions = routeData.map(item => ({
        value: item?._id,
        label: item?.name
    }))
    const stateOptions = selectedCardCountry === COUNTRY.US
        ? COUNTRIES.find(country => country.abbreviation === "US").states.map(state => ({ value: state.abbreviation, label: state.name }))
        : selectedCardCountry === COUNTRY.CANADA
            ? COUNTRIES.find(country => country.abbreviation === "CA").states.map(state => ({ value: state.abbreviation, label: state.name }))
            : selectedCardCountry === COUNTRY.BOTH
                ? COUNTRIES.reduce((acc, curr) => [...acc, ...curr.states.map(state => ({ value: state.abbreviation, label: state.name }))], [])
                : [];
    const handleInputChange = (e) => {
        setOrderNumbers([])
        setSelectedNumber('')
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,

        }));
    };

    const handleChange = (selected, fieldName) => {
        setOrderNumbers([])
        setSelectedNumber('')
        setForOrder(false)
        if (fieldName == "state") {
            setForm({ ...form, state: { value: selected.value, label: selected.label }, areaCode: '' });
        }
        if (fieldName == "searchOption") {
            // setForm({ ...form, searchOption: selected.value, searchOptionWith: '' });
            // setLabel({ ...label, searchOption: selected.label, searchOptionWith: '' })
            if (selected === null) {
                setForm(prevForm => ({
                    ...prevForm,
                    searchOption: '',
                    searchOptionWith: ''  // assuming you want to reset this as well
                }));
                setLabel(prevLabel => ({
                    ...prevLabel,
                    searchOption: '',
                    searchOptionWith: ''  // assuming you want to reset this as well
                }));
            } else {
                setForm(prevForm => ({
                    ...prevForm,
                    searchOption: selected.value,
                    searchOptionWith: ''  // reset or update as needed
                }));
                setLabel(prevLabel => ({
                    ...prevLabel,
                    searchOption: selected.label,
                    searchOptionWith: ''  // reset or update as needed
                }));
            }
        }
        else {
            setForm(form => ({
                ...form,
                [fieldName]: { value: selected.value, label: selected.label }
            }));
        }
    };
    const handleSubmit = async () => {
        if (selectedCard == null) {
            toast.error("Number type is required")
            return
        }

        // if (form?.searchOption == '') {
        //     toast.error("Search option is required")
        //     return
        // }
        // if (form?.searchOptionWith == '') {
        //     toast.error(`${form?.searchOption} is required`);
        //     return
        // }
        if (selectedCard == FONE_NUMBERS_TYPE.LOCAL.label) {
            if (selectedCardCountry == null) {
                toast.error("Country is required")
                return
            }
            if (form?.state == '') {
                toast.error("State is required")
                return
            }
            if (form?.areaCode == '') {
                toast.error("Area code is required")
                return
            }
            if (form?.route == '') {
                toast.error("Route is required")
                return
            }
            if (form?.searchOption != '' && form?.searchOptionWith == '') {
                toast.error(`${form?.searchOption} is required`);
                return
            }
            setLoading(true)
            if (forOrder) {
                if (form?.route == '') {
                    toast.error("Route is required")
                    return
                }
                setLoading(true)
                try {

                    const response = await axios.post("group/order/number", { numbers: [formatSelection(selectedNumber)], route_id: form?.route?.value, group_id: form?.group?.value, type: FONE_NUMBERS_TYPE.LOCAL.value })
                    toast.success("Number ordered successfully")
                    setOrderNumbers([])
                    setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

                    setSelectedNumber('')

                    navigate("/ordernumber")
                } catch (error) {
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }
            else {
                const addField = {
                    type: selectedCard == FONE_NUMBERS_TYPE.LOCAL.label ? FONE_NUMBERS_TYPE.LOCAL.value : FONE_NUMBERS_TYPE.TOLL_FREE.value,
                    country: selectedCardCountry,
                    state: form?.state?.label,
                    name: form?.areaCode?.value,

                }
                if (form?.searchOption !== "") {
                    addField.search_option = form.searchOption;
                }
                if (form?.searchOptionWith !== "") {
                    addField.search_pattern = form.searchOptionWith;
                }
                try {
                    const response = await axios.post("group/search/number", addField)
                    setOrderNumbers(response.data.data.numbers)
                    setForOrder(true)
                } catch (error) {
                    if (error.response) {
                        const apiError = error?.response?.data?.data?.errors[0].name
                        toast.error("No available numbers against selected area code")
                        // handleApiError(apiError)

                    }
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }

        }
        else {
            if (forOrder) {
                if (form?.route == '') {
                    toast.error("Route is required")
                    return
                }
                setLoading(true)
                try {

                    const response = await axios.post("group/order/number", { numbers: [formatSelection(selectedNumber)], route_id: form?.route?.value, group_id: form?.group?.value, type: FONE_NUMBERS_TYPE.TOLL_FREE.value })
                    toast.success("Number ordered successfully")
                    setOrderNumbers([])
                    setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

                    setSelectedNumber('')

                    navigate("/ordernumber")
                } catch (error) {
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }
            }
            else {
                if (form?.prefix == ''
                ) {
                    toast.error("Toll free prefix is required")
                    return
                }
                if (form?.route == '') {
                    toast.error("Route is required")
                    return
                }
                setLoading(true)
                const addField = {
                    type: FONE_NUMBERS_TYPE.TOLL_FREE.value,
                    name: form?.prefix?.value,

                }
                try {
                    const response = await axios.post("group/search/number", addField)
                    setOrderNumbers(response.data.data.numbers)
                    setForOrder(true)
                } catch (error) {
                    if (error.response) {
                        toast.error("No available numbers against selected toll free code")
                        const apiError = error?.response?.data?.data?.errors
                        handleApiError(apiError)

                    }
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                } finally {
                    setLoading(false)
                }

            }

        }
    }
    function formatNumber(number) {
        // Remove all non-numeric characters from the number
        const cleaned = ('' + number).replace(/\D/g, '');
    
        // Check if the number length equals to 10
        if (cleaned.length === 10) {
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]}-${match[3]}`;
            }
        }
    
        return null; // return null if the number is not valid
    }
    function formatSelection(number) {
        // Assume all numbers are valid and need to be formatted to the + format when selected
        const cleaned = ('' + number).replace(/\D/g, '');
        if (cleaned.length === 10) {
            return cleaned;
        }
        return number;
    }
    const handleApiError = (errorFields) => {
        setHasError(errorFields);
    };
    const handleClick = (name) => {
        setForOrder(false)
        setSelectedNumber('')
        setOrderNumbers([])
        setForm({ ...form, route: "", group: "" })
        setSelectedCard(name);
    };
    const handleClickCountry = (name) => {
        setOrderNumbers([])
        setSelectedNumber('')

        setSelectedCardCountry(name);
        setForm({ ...form, state: '', areaCode: '' })
    };
    const handleSelectedNumber = (name) => {
        // const formattedForSelection = formatSelection(name);
        // setSelectedNumber(formattedForSelection);
        setSelectedNumber(name)
    }
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = orderNumber.slice(indexOfFirstItem, indexOfLastItem);
    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    return (
        <>
        <Header mainPageName={"Number List"} subPageName={"Single Order Number"} />
            <div className="flex justify-center ">

                <Card className=" mt-5 bg-card-bg white-text p-4" style={{ width: "48rem", pointerEvents: loading ? 'none' : 'auto', filter: loading ? 'blur(1px)' : 'none', }}>
                    {loading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                            <Spin size="large" className="custom-spin" />
                        </div>
                    )}
                    <div >
                        <label className="block text-gray-700 text-sm font-bold mb-4">Number Type<span style={{ color: "red" }}>*</span></label>

                        <Row gutter={16} className="mb-4 flex items-center">
                            <Col span={8}>
                                <CardDesign
                                    name={FONE_NUMBERS_TYPE.LOCAL.label}
                                    selected={selectedCard}
                                    handleClick={handleClick} />
                            </Col>
                            <Col span={8}>
                                <CardDesign
                                    name={FONE_NUMBERS_TYPE.TOLL_FREE.label}
                                    selected={selectedCard}
                                    handleClick={handleClick}
                                /></Col>
                        </Row>
                        {
                            selectedCard == FONE_NUMBERS_TYPE.LOCAL.label ?
                                <>
                                    <label className="block text-gray-700 text-sm font-bold mb-4">Country<span style={{ color: "red" }}>*</span></label>
                                    <Row gutter={16} className="mb-4">
                                        <Col span={8}>
                                            <CardDesign
                                                name={COUNTRY.US}
                                                selected={selectedCardCountry}
                                                handleClick={handleClickCountry} />
                                        </Col>
                                        <Col span={8}>
                                            <CardDesign
                                                name={COUNTRY.CANADA}
                                                selected={selectedCardCountry}
                                                handleClick={handleClickCountry}
                                            /></Col>
                                        <Col span={8}>
                                            <CardDesign
                                                name={COUNTRY.BOTH}
                                                selected={selectedCardCountry}
                                                handleClick={handleClickCountry}
                                            /></Col>
                                    </Row>
                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-4">State<span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            className="mb-4 rounded-lg border border-gray-300 "
                                            classNamePrefix="custom-select"
                                            style={{ width: '100%' }}
                                            name="state"
                                            options={stateOptions}
                                            value={form.state ? { value: form?.state?.value, label: form?.state?.label } : null}
                                            onChange={(selected) => handleChange(selected, 'state')}  >

                                        </Select>
                                    </div>

                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-4">Area Code<span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            className=" mb-4 rounded-lg border border-gray-300"
                                            classNamePrefix="custom-select"
                                            style={{ width: '100%' }}
                                            name="areaCode"
                                            options={AREACODE[form?.state.label]?.map(areaCode => ({ value: areaCode, label: areaCode }))}
                                            value={form.areaCode ? { value: form.areaCode.value, label: form.areaCode.label } : null}
                                            onChange={(selected) => handleChange(selected, 'areaCode')}  >

                                        </Select>
                                    </div>

                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-4">Search Option</label>
                                        <Select
                                            className=" mb-4 rounded-lg border border-gray-300"
                                            classNamePrefix="custom-select"
                                            style={{ width: '100%' }}
                                            name="searchOption"
                                            options={[
                                                { value: "Contains", label: "Contains" },
                                                { value: "Starts with", label: "Starts with" },
                                                { value: "Ends with", label: "Ends with" }

                                            ]}
                                            value={form.searchOption ? { value: form.searchOption, label: label.searchOption } : null}
                                            onChange={(selected) => handleChange(selected, 'searchOption')}
                                            isClearable={true}
                                        >

                                        </Select>
                                    </div>
                                    {form?.searchOption &&
                                        <div>
                                            <label className="block text-gray-700 text-sm font-bold mb-4">{form?.searchOption}<span style={{ color: "red" }}>*</span></label>
                                            <Input  className=" mb-4 ps-3 border border-gray-300  custom-input"
                                                placeholder={`Enter ${form?.searchOption}`} style={{ width: "100%", minHeight: "38px", borderRadius: "8px" }} value={form?.searchOptionWith} name="searchOptionWith" onChange={handleInputChange} />
                                        </div>
                                    }
                                </> : <>
                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-4">Toll Free Prefix<span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            className=" mb-4 rounded-lg border border-gray-300"
                                            classNamePrefix="custom-select"
                                            style={{ width: '100%' }}
                                            name="prefix"
                                            options={[
                                                { value: "800", label: "800" },
                                                { value: "833", label: "833" },
                                                { value: "844", label: "844" },
                                                { value: "855", label: "855" },
                                                { value: "866", label: "866" },
                                                { value: "877", label: "877" },
                                                { value: "888", label: "888" }



                                            ]}
                                            value={form.prefix ? { value: form.prefix.value, label: form.prefix.label } : null}
                                            onChange={(selected) => handleChange(selected, 'prefix')}  >

                                        </Select>
                                    </div>
                                </>
                        }
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-4">Route<span style={{ color: "red" }}>*</span></label>
                            <Select
                                className=" mb-4 rounded-lg border border-gray-300"
                                classNamePrefix="custom-select"
                                style={{ width: '100%' }}
                                name="route"
                                options={RouteDataOptions}
                                value={form.route ? { value: form.route.value, label: form.route.label } : null}
                                onChange={(selected) => handleChange(selected, 'route')}  >

                            </Select>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-4">Group</label>
                            <Select
                                className=" mb-4 rounded-lg border border-gray-300"
                                classNamePrefix="custom-select"
                                style={{ width: '100%' }}
                                name="group"
                                options={GroupDataOptions}
                                value={form.group ? { value: form.group.value, label: form.group.label } : null}
                                onChange={(selected) => handleChange(selected, 'group')}  >

                            </Select>
                        </div>

                    </div>
                    <div>
                        <Row gutter={16}>
                            {currentItems.map((item, index) => (
                                <Col key={index} span={6} className="mb-4">
                                    <CardDesign
                                        name={formatNumber(item)}
                                        selected={selectedNumber}
                                        handleClick={handleSelectedNumber}
                                    />
                                </Col>
                            ))}
                        </Row>

                    </div>
                    <div className="flex justify-start">
                        {
                            orderNumber.length > 0 && (

                                <Pagination
                                className="custom-pagination"
                                    defaultCurrent={1}
                                    current={currentPage}
                                    onChange={handleChangePage}
                                    total={orderNumber.length}
                                    pageSize={pageSize}
                                    showSizeChanger={false}
                                />
                            )
                        }

                        <div className="flex flex-grow justify-end">
                            <Button onClick={handleSubmit} className={forOrder ? "bg-green text-white font-bold  rounded" : "bg-primary text-white flex items-center custom-ant-btn"}>{forOrder ? `Order Number` : "Check Number"}</Button>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}
export default SingleOrderNumber;