import axios from "../api/axios";

const GroupService = {
  all: (offset = 0, limit = 10) => axios.get("/group", { params: { offset, limit } }),
  add: (name) => axios.post("/group", { name }),
  status: (group_id,) => axios.get("group/status", { params: { group_id } }),
  createBulkNumbers: (group_id, user_id, numbers) => axios.post("/group/numbers", { group_id, user_id, numbers }),
  groupNumbers: (group_id,offset,limit) => axios.get("group/numbers", { params: { group_id ,offset,limit} }),
  downloadNumers: (type) => axios.get("group/download", { params: { type } }),
  user:(offset = 0, limit = 10,userId) => axios.get(`/group?user_id=${userId}`,{ params: { offset, limit }}),
  allRoute: (offset = 0, limit = 10) => axios.get("/route", { params: { offset, limit } }),
  addRoute: (name) => axios.post("/route", { name }),
  routeNumbers: (offset,limit,route_id) => axios.get("/group/ordered/numbers", { params: { offset,limit,route_id} }),
  again:(group_id)=> axios.get("group/state", { params: { group_id } }),
};

export default GroupService;
