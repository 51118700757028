import { Breadcrumb, Card, Col, Input, Row, Spin, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { DiWebplatform } from "react-icons/di";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import Header from "../../components/BreadCrumbs";

const { Text } = Typography;


const CheckSingleNumber = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [phoneInputField, setPhoneInputField] = useState("")
    const [responseData, setResponseData] = useState(null);
    const [isPolling, setIsPolling] = useState(false);
    const [singleCheckData, setSingleCheckData] = useState([])
    const [showTable, setShowTable] = useState(false)
    const handlePhoneChange = (event) => {
        const newNumber = event.target.value.replace(/[^\d]/g, "");
        setPhoneInputField(newNumber);
    }

    const handleCheck = async () => {
        if (phoneInputField == null || phoneInputField == undefined || phoneInputField == "") {
            toast.error("Phone number is required")
            return
        }
        try {
            setLoading(true)
            const response = await axios.post("/group/number/status", { number: `+1${phoneInputField}` })
            await performGetPolling();
        }
        catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
            setLoading(false)

        }
    }
    const performGetPolling = async () => {
        let conditionMet = false;
        let getData = true;
        while (!conditionMet && getData) {
            try {
                const phoneNumberWithPlus = `+1${phoneInputField}`;
                const encodedNumber = encodeURIComponent(phoneNumberWithPlus);
                const getResponse = await axios.get(`/group/number/status?number=${encodedNumber}`);
                if (getResponse.data.data.status == 'spam' || getResponse.data.data.status == 'clean' || getResponse.data.data.status == 'invalid') {
                    conditionMet = true
                    setLoading(false)
                    setSingleCheckData([getResponse.data.data])
                    setPhoneInputField("")
                    setShowTable(true)
                    setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));

                    // setSingleCheckData(prevState => [...prevState, ...getResponse.data.data]);
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }
            } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e | error)
                setLoading(false)

            }
        }
    };
    const columns = [
        {
            title: "Phone Number",
            render: (_, record) => {
                {
                    const numbers = record?.number ? `${record?.number}` : "N/A";
                    return <span>{numbers}</span>;
                }
            },

        },
        {
            title: "Status",
            render: (_, record) => {
                {
                    const status = record?.status == 'spam' ? "Spam" : record?.status == 'clean' ? "Clean" : "Invalid";
                    return <span className={record?.status == 'spam' ? 'redBadge' : record?.status == 'clean' ? 'greenBadge' : 'yellowBadge'} >{status}</span>;
                }
            },
        },
        {
            title: "Score",
            dataIndex: 'risk_level',
            render: (_, record) => {
                const getBadgeStyle = (risk_level) => {
                    if (risk_level === undefined || risk_level === null) {
                        return {};
                    } else if (risk_level <= 40) {
                        return { display: 'inline-block', backgroundColor: '#28a745', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
                    } else if (risk_level > 40 && risk_level <= 59) {
                        return { display: 'inline-block', backgroundColor: '#f59e0b', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
                    } else if (risk_level >= 60) {
                        return { display: 'inline-block', backgroundColor: '#dc3545', color: 'white', minWidth: '40px', textAlign: 'center', padding: '5px 10px', borderRadius: '5px' };
                    } else {
                        return { display: 'inline-block', padding: '5px 10px', borderRadius: '5px' };
                    }
                };

                const badgeStyle = getBadgeStyle(record?.risk_level);

                return (
                    <span>
                        <div style={badgeStyle}>
                            {record?.risk_level ? record?.risk_level : 'N/A'}
                        </div>
                    </span>
                );
            },
        },
        // { },
        // { title: "Clean", dataIndex: "nonBlackListed" },
        // { title: "Total Count", dataIndex: "totalCount" },


    ];
    const inputStyle = {
        backgroundColor: 'red',
        color: 'white'
    };
    return (
        <>
        <Header mainPageName={"Number Reputation"} subPageName={"Check Single Number"} />
            <section className="flex items-center mt-9 white-text">
                <Card className="mx-auto  w-90 md:w-[600px] bg-card-bg white-text border border-solid border-border">
                    {loading && (
                        <div className="absolute inset-0 bg-gray-200 opacity-75 z-50 flex justify-center items-center">
                            <Spin className="custom-spin" />
                        </div>
                    )}
                    <Row>
                        <Col span={20}>
                            {/* <label className="capitalize">Phone#</label> */}
                            {/* <div className=""> */}
                            <Col span={20}>
                                <Input
                                    addonBefore={<span className="custom-addon">+1</span>}
                                    className="custom-input"
                                    type="number"
                                    name="phone"
                                    placeholder="Enter phone number"
                                    value={phoneInputField}
                                    onChange={handlePhoneChange}
                                    style={inputStyle}
                                />
                            </Col>
                            {/* </div> */}
                        </Col>
                        <Col span={4}>
                            <button onClick={handleCheck} className="w-[100%] bg-primary text-white rounded-md p-1 ms-1">Check</button>
                        </Col>
                    </Row>
                    {
                        showTable && (
                            <div className="mt-5">
                                <Table className="custom-table" dataSource={singleCheckData} columns={columns} pagination={false} style={{
                                    border: '1px solid #ddd',
                                }} />
                            </div>
                        )
                    }

                </Card>
            </section>
        </>)
}
export default CheckSingleNumber;
