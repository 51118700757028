import React, { useEffect, useState } from "react";
import { Typography, Table, Card, Button, Spin, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
// import OrderHistoryService from "../../../../services/orderHistoryService";
import CardService from "../../../../services/cardService";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import useAuth from "../../../../hooks/useAuth";
import BuyCredits from "../../../packages/buyCredit";
import axios from "../../../../api/axios";
import Swal from "sweetalert2";

const { Text } = Typography;

export default function Cards() {
  const { auth, setAuth } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [cardHistory, setCardHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openRowId, setOpenRowId] = useState(null);
  const [addNewCard, setAddNewCard] = useState(false);
  const [makeDefaultHide,setMakeDefaultHide]=useState(true)
  const user_id = auth?._id;
  const handleTogglePanel = async (key, isDefault) => {
    setLoading(true);
    if (isDefault) {
      return;
    } else {
      const addField = {
        is_default: true,
        card_id: key,
      };
      try {
        const response = await axios
          .patch("/credit/card", addField)
          .then(() => {
            getOrderHistory();
            toast.success("Default card updated successfully");
            setLoading(false);
          });

        // setCardData(response.data?.data.cards.map((e) => ({ ...e, key: e._id })));
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
      }
      // setOpenRowId(openRowId === key ? null : key);
    }
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: 'custom-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios
            .delete("/credit/card", { data: { card_id: id } })
            .then(() => {
              getOrderHistory();
              toast.success("Card deleted successfully");
            });
        } catch (error) {
          const e = error?.response?.data?.title;
          toast.error(e || error);
        }
        // try {
        //   const response = await axios.delete("/credit/card", { data: addField });
        //   getOrderHistory();
        //   toast.success("Card Deleted Successfully");
        // } catch (error) {
        //   const e = error?.response?.data?.title;
        //   toast.error(e || error);
        // }
      }
    });
  };
  const filterUsers = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = cardHistory?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredUsers(result);
  };
  useEffect(() => {
    getOrderHistory();
  }, []);
  useEffect(() => {
    if (cardHistory.length >= 0) {
      checkMakedefaultHide();
    }
  }, [cardHistory]);
  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const response = await CardService.all(0, 100);
      setCardHistory(
        response.data?.data.cards.map((e) => ({ ...e, key: e._id }))
      );
      //  checkMakedefaultHide();
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
const checkMakedefaultHide=()=>{
  if(cardHistory?.length === 0)
  {
    setMakeDefaultHide(false)
  }
  else{
    setMakeDefaultHide(true)
  }
}
  const columns = [
    {
      title: "Card Number",
      render: (_, record) => <span>{record?.card_number || "N/A"}</span>,
    },
    {
      title: "Expiry Date",
      render: (_, record) => {
        const expiryDate = record
          ? `0${record?.expiration_month}/${record?.expiration_year}`
          : "N/A";
        return <span>{expiryDate || "N/A"}</span>;
      },
    },
    {
      title: "Actions",
      render: (_, record) => (
        <span>
          {record?.is_default==false ?  
          
          <Button
            className="ms-2 me-2"
            onClick={() => handleDelete(record._id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button> :""}
        
          <Button
            className={record?.is_default ? "bg-primary text-white me-2 " : "hover-none"}
            style={{
              width: record?.is_default ? "6.3rem" : "auto",
              backgroundColor: record?.is_default ? "" : "#de4300",
              border:record?.is_default ? "" : "none"

              // textCol,
            }}
            disabled={record?.is_default}
            onClick={() => handleTogglePanel(record._id, record?.is_default)}
          >
            {/* {openRowId === record.key ? "Close" : "Set Default"} */}
            {/* {record?.is_default(
              <>
              ?  <CheckCircleOutlined /> "Default"
              </>)
              : openRowId === record.key
              ? "Close"
              : "Set Default"} */}
            {record?.is_default ? (
              <>
                {/* <CheckCircleOutlined style={{ color: "green" }} />{" "} */}
                <span className="" style={{ width: "6.3rem", color:"white" }}>
                  Default
                </span>
              </>
            ) : openRowId === record.key ? (
              "Close"
            ) : (
              <span  style={{color:"white",}}>Set Default
              </span>
              
            )}
          </Button>
        </span>
      ),
    },
  ];

  return (
    <>
    {loading && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                        <Spin size="large" className="custom-spin" />
                    </div>
                )}
      <div className="mt-3  p-3">
        <div className="flex justify-end mb-3">
          <Button
            className="bg-primary text-white me-2 custom-ant-btn" style={{border:"none"}}
            onClick={() => setAddNewCard("true")}
          >
            Add Card
          </Button>
          <Input
          className="w-52 custom-input"
            value={searchValue}
            onChange={filterUsers}
            size="middle"
      
            placeholder="Filter Users"
          />
        </div>
        <Table
        className="custom-table"
          loading={loading}
          columns={columns}
          dataSource={searchValue ? filteredUsers : cardHistory}
          pagination={false}
        />
      </div>
      <BuyCredits
        open={addNewCard}
        makeDefaultHide={makeDefaultHide}
        onClose={() => setAddNewCard(false)}
        onSuccess={() => {
          setAuth((e) => ({
            ...e,
            refreshCredits: e?.refreshCredits ? false : true,
          }));
          getOrderHistory();
        }}
      />
    </>
  );
}
