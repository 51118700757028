import React, { useEffect, useState } from "react";
import { Typography, Table, Card, Button, Spin, Input, Pagination } from "antd";
import OrderHistoryService from "../../../../services/orderHistoryService";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import PaginationCommon from "../../../../components/PaginationCommon";
const { Text } = Typography;

export default function Transactions({ handleTabChange }) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offSet,setOffSet]=useState(0);
  const [limit,setLimit]=useState(10);
  const [showpagination, setShowPagination] = useState(true);
  const [count ,setCount]=useState();
  const filterUsers = (e) => {
    const search = e.target.value;
    setSearchValue(search);
    let result = orderHistory?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredUsers(result);
  };
  useEffect(() => {
    getOrderHistory(offSet, limit);
  }, []);
  // useEffect(() => {
  //   if (!hasRun && orderHistory && orderHistory.length > 0) {
  //     debugger

  //     if (orderHistory.length >= 9) {
  //       debugger
  //       setTotal(orderHistory?.length + 1);
  //     } else {
  //       setTotal(orderHistory?.length);
  //     }
  //     setHasRun(true);

  //   }
    // if(orderHistory?.length=== 0)
    // {
    //   setShowPagination(false)
    // }
    // else{
    //   setShowPagination(true)
    // }
  // }, [orderHistory, hasRun]);
  // useEffect(() => {
  //   if (hasRun && orderHistory && page) {
  //     let newTotal = 0;
  //     if (orderHistory.length >= 9) {
  //       newTotal = page * 10 + 1;
  //     }
  //     else {
  //       newTotal = total - 1;
  //     }
  //     if (orderHistory?.length === 0) {
  //       newTotal = total - 1;
  //     }
  //     setTotal(newTotal);
  //   }
  // }, [orderHistory, hasRun, page]);
  const getOrderHistory = async (offset, limit) => {
    setLoading(true);
    try {
      const response = await OrderHistoryService.all(offset, limit);
      setOrderHistory(
        response.data?.data.transications.map((e) => ({ ...e, key: e._id }))
      );
      setCount(response.data?.data.total_count)
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
    setLoading(false);
  };
  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "purchasing_date",
            sorter: (a, b) => (a.purchasing_date || '').localeCompare(b.purchasing_date || ''),
      render: (_, record) => {
        const timestamp = record?.purchasing_date;
        const dateObj = new Date(timestamp);

        if (isNaN(dateObj.getTime())) {
          return <span>N/A</span>;
        }

        // Extract the date and time in YYYY-MM-DD HH:MM:SS format
        const dateString = dateObj.toISOString().split('T')[0];
        const timeString = dateObj.toTimeString().split(' ')[0]; // Extract the time part

        return <span>{`${dateString},${timeString}`}</span>;
      },
    },
    // {
    //   title: "Expiry Date",
    //   render: (_, record) => {
    //     // const expiryDate = record.expiry_date
    //     //   ? `0${record?.card_id?.expiration_month}/${record?.card_id?.expiration_year}`
    //     //   : "N/A";
    //     // return <span>{expiryDate || "N/A"}</span>;
    //     const dateObj = moment(record.expiry_date).tz("America/New_York");
    //     const formattedDate = dateObj.format("YYYY-MM-DD, HH:mm:ss");

    //     return <span>{formattedDate == "Invalid date" ? "N/A" : formattedDate || "N/A"}</span>;
    //   },
    // },
    {
      title: "Service Type",
      dataIndex: "service_type",
            sorter: (a, b) => (a.service_type || '').localeCompare(b.service_type || ''),
      render: (_, record) => {
        {
          const serviceType = record?.service_type ? `${record?.service_type}` : "N/A";
          return <span>{serviceType}</span>;
        }
      },
    },
    {
      title: "Count",
      dataIndex: "count",
            sorter: (a, b) => (a.count || '').localeCompare(b.count || ''),
      render: (_, record) => {
        {
          const count = record?.count ? `${record?.count}` : "N/A";
          return <span>{count}</span>;
        }
      },
    },
    { title: "Description", dataIndex: "description" },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      render: (_, record) => {
        {
          const amount = record?.amount ?
            `$${parseFloat(record?.amount) % 1 !== 0 ? parseFloat(record?.amount).toFixed(4) : parseFloat(record?.amount)}` :
            "N/A";
          return <span>{amount}</span>;
        }
      },
    },

    // {
    //   title: "Card Number",
    //   render: (_, record) => (
    //     <span>{record.card_id?.card_number || "N/A"}</span>
    //   ),
    // },

    // {
    //   title: "Brand",
    //   render: (_, record) => {
    //     const brand = `${record?.credit_id?.name}`;
    //     return <span>{brand == "undefined" ? "N/A" : brand || "N/A"}</span>;
    //   },
    // },
  ];
  return (
    <>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <Spin size="large" className="custom-spin" />
        </div>
      )}
      <div className="mt-3  p-3">
        <div className="flex justify-end mb-3">
          <Input
            value={searchValue}
            onChange={filterUsers}
            size="middle"
            className="w-52 custom-input"
            placeholder="Filter Users"
          />
        </div>
        <Table
          className="custom-table"
          // loading={loading}
          columns={columns}
          dataSource={searchValue ? filteredUsers : orderHistory}
          pagination={false}
        />
          <PaginationCommon total={count}  data={orderHistory} handleChange={getOrderHistory} />
      </div>
    </>
  );
}
