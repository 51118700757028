import React, { useEffect, useState } from "react";
import { Card, Input, Spin, Typography } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { storedKeys } from "../../utils/storedKeys";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/userService";
import LogoForOutside from "../../images/logo.png"
const { Text } = Typography;

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem(storedKeys.user) && auth) {
      toast.success("User login successfully");
      return navigate("/dashboard");
    }
  }, [auth]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      try {
        localStorage.clear();
        const response = await AuthService.login(values.email, values.password);
        const user = response.data?.data;
        localStorage.setItem(
          storedKeys.accessToken,
          JSON.stringify(user?.access_token)
        );
        localStorage.setItem(
          storedKeys.refreshToken,
          JSON.stringify(user?.refresh_token)
        );
        if (localStorage.getItem(storedKeys.accessToken)) {
          const permissionResponse = await UserService.userPermissions();
          const permissions = permissionResponse.data?.data?.map((e) => ({
            name: e.name,
            method: e.method,
            access: e.access,
          }));
          localStorage.setItem(
            storedKeys.user,
            JSON.stringify({ ...user, permissions })
          );

          setAuth({ ...user, permissions });
        }
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
    
    <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", backgroundColor:"black" }} >
    <div >
      <img style={{height: "10rem" ,marginBottom: "-3rem"}} src={LogoForOutside}></img>
      </div>
      <Card className="mx-auto p-8 w-90 md:w-[600px] bg-card-bg white-text border border-solid border-border">
        <div className="mb-8">
          <h1 className="text-3xl font-bold">Signin</h1>
          {/* <Text className="text-gray-500">Welcome! Web Portal</Text> */}
        </div>
        <form
          className=""
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_signin_form"
        >
          <div className="mb-3">
            <label className="">Email</label>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              {...formik.getFieldProps("email")}
              className={`${
                formik.touched.email && formik.errors.email ?  "border-red" : ""
              } custom-input`}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red text-[12px] pt-1">
                {" "}
                {formik.errors.email}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="">Password</label>
            <Input
              type="password"
              name="password"
              placeholder="Password"
              {...formik.getFieldProps("password")}
              className={`${
                formik.touched.password &&
                formik.errors.password ?
                "border-red" : ""
              } custom-input`}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red text-[12px] pt-1">
                {" "}
                {formik.errors.password}
              </div>
            )}
          </div>
          <div onClick={() => navigate("/forgotpassword")} className="font-bold text-primary mb-8 cursor-pointer text-right">
            Forgot Password 
          </div>
          <button
            type="submit"
            className="w-[100%] bg-primary text-white rounded-md p-1 hover-none "
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting && <Spin size="small" className="custom-spin mr-3" />}
            Signin
          </button>
          <div className="text-[#99A1B7] font-semibold text-center flex items-center justify-center gap-1 mt-4">
            Not a Member yet?
            <div
              className="text-primary font-bold cursor-pointer"
              onClick={() => navigate("/register")}
            >
              {" "}
              Signup
            </div>
          </div>
        </form>
      </Card>
      <div className="mt-4 " >
      <p style={{ color:"#6a7684" ,fontSize:"x-small", marginBottom:"2rem"}}>© Copyright 2024 | All Rights Reserved | v 1.0</p>
    </div>
    </section>
   
    
    </>

  );
};

export default SignIn;
