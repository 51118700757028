import { Button, Spin } from "antd";
import { useEffect, useState ,useRef } from "react";
import { IoCloudUpload } from "react-icons/io5";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { COUNTRIES,FONE_NUMBERS_TYPE, QUANTITYSELECTION  ,AREACODE} from "../../utils/enums";
import axios from "../../api/axios";
import Select from 'react-select';
import GroupService from "../../services/groupService";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const FileUpload = () => {
  const { auth, setAuth } = useAuth();

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const [csvFile, setCsvFile] = useState(null);
  const [fileUploading, setFileUplaoding] = useState(false);
  const [csvData, setCsvData] = useState([])
  const [headerName, setHeaderName] = useState([])
  const [hasError, setHasError] = useState([])
  const [loading, setLoading] = useState(false)
  const [forOrder, setForOrder] = useState(false)
  const [orderNumber, setOrderNumbers] = useState([])
  const [routeData, setRouteData] = useState([])
  const [groupData, setGroupData] = useState([])
  const initialValues = {
    route: "",
    group: "",
  }
  const [form, setForm] = useState(initialValues)
  const handleDownloadTemplateState = (e) => {
    e.preventDefault();
    let csvContent = "state,quantity,abbreviation,country\n";
  
    COUNTRIES.forEach(country => {  
      country.states.forEach(state => {
        csvContent += `${state.name},${0},${state.abbreviation},${country.name}\n`;
      });
    });

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "state_template.csv";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
}
  const handleDownloadTemplateAreaCode = (e) => {
    let csvContent = "areacode,quantity,state,abbreviation,country\n";
  
    COUNTRIES.forEach((country) => {
      country.states.forEach((state) => {
        const stateName = state.name;
        const abbreviation = state.abbreviation;
        const countryName = country.name;
        
        if (AREACODE[stateName]) {
          AREACODE[stateName].forEach((areacode) => {
            csvContent += `${areacode},${0},${stateName},${abbreviation},${countryName}\n`;
          });
        }
      });
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Areacode template.csv";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    // setCsvFile(new File([blob], "Areacode template.csv"));
  }
  const handleDownloadTemplatetollFree = (e) => {
    e.preventDefault();
    const csvContent =
      "tollfree,quantity\n" +
      "800,0\n" +
      "833,0\n" +
      "844,0\n" +
      "855,0\n" +
      "866,0\n" +
      "877,0\n" +
      "888,0";
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Toll Free template.csv";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    // setCsvFile(new File([blob], "Areacode template.csv"));
  }
  const handleUploadFile = (e) => {
    setHasError([])
    setForOrder(false)
    setFileUplaoding(true);
    setCsvData([]);
    setHeaderName([])
    setCsvFile(null)
    try {
      let file = e.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (results.data.length > 0) {
            setCsvFile(file);
            const keys = Object.keys(results.data[0]);
            setHeaderName(keys)

            const parsedData = results.data.map((row) =>
              keys.reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {})
            );
            const formatted = parsedData.filter((obj) => obj.state && obj.state.trim() !== "" && obj.quantity.trim() !== "" && obj.quantity !== '0' || obj.areacode && obj.areacode.trim() !== "" && obj.quantity.trim() !== "" && obj.quantity !== '0'|| obj.tollfree && obj.tollfree.trim() !== "" && obj.quantity.trim() !== "" && obj.quantity !== '0');
            const cleanedData = formatted?.map((obj) => {
              const newObj = {};
              Object.keys(obj).forEach((key) => {
                if (obj[key].trim() !== "") {
                  newObj[key] = obj[key];
                }
              });
              return newObj;
            });
           
            if (cleanedData.length === 0) {
              toast.error("No data found, Please try a different file");
              setCsvFile(null);
              setFileUplaoding(false);
            } else {
              setFileUplaoding(false);
              setCsvData(cleanedData);
            }
            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          }
        
        },
      });
    } catch (e) {
      toast.error("Invalid file");
      setFileUplaoding(false);
    }
    setFileUplaoding(false);
    setCsvFile(null)
  };

  const handleSubmit = async () => {
    if(form?.route == '')
      {
          toast.error('Route is required')
          return
      }
    if (csvData.length == 0) {
      toast.error("State or Area code is required")
      return
    }
    if (forOrder) {
      setLoading(true)
      try {
        const response = await axios.post("group/bulk/order/numbers", { numbers: orderNumber, country: "US" ,type:FONE_NUMBERS_TYPE.LOCAL.value ,route_id:form?.route.value,group_id:form?.group.value})
        await checkRunningOrder()
      
        // toast.success("Number ordered successfully")
        //                navigate("/ordernumber")
      } catch (error) {
        const e = error?.response?.data?.title;
        toast.error(e || error);
      } finally {
        setLoading(false)
      }
    }

    else {
      if (headerName[0] == 'state') {
        const addField = {
          country: 'US',
          type: FONE_NUMBERS_TYPE.LOCAL.value,
          states: csvData?.map((item) => ({
            name: item?.state,
            quantity: item?.quantity,
          })),
          quantity_selector: QUANTITYSELECTION.STATE.value
        }
        try {
          setLoading(true)

          const response = await axios.post("/group/bulk/numbers", addField)
          setOrderNumbers(response.data.data.numbers)
          setForOrder(true)
        } catch (error) {
          if (error.response) {
            const apiError = error?.response?.data?.data?.errors
            handleApiError(apiError)

          }
          const e = error?.response?.data?.title;
          toast.error(e || error);
        } finally {
          setLoading(false)
        }
      }
      else if(headerName[0] == 'areacode') {
        const addField = {
          country: 'US',
          type: FONE_NUMBERS_TYPE.LOCAL.value,
          area_codes: csvData?.map((item) => ({
            name: item?.areacode,
            quantity: item?.quantity,
          })),
          quantity_selector: QUANTITYSELECTION.AREACODE.value
        }
        try {
          setLoading(true)

          const response = await axios.post("/group/bulk/numbers", addField)
          setOrderNumbers(response.data.data.numbers)
          setForOrder(true)
        } catch (error) {
          if (error.response) {
            const apiError = error?.response?.data?.data?.errors
            handleApiError(apiError)

          }
          const e = error?.response?.data?.title;
          toast.error(e || error);
        } finally {
          setLoading(false)
        }
      }
      else{
        const addField = {
          country: 'US',
          type: FONE_NUMBERS_TYPE.TOLL_FREE.value,
          toll_free_codes: csvData?.map((item) => ({
            name: item?.tollfree,
            quantity: item?.quantity,
          })),
          quantity_selector: 'toll_free'
        }
        try {
          setLoading(true)

          const response = await axios.post("/group/bulk/numbers", addField)
          setOrderNumbers(response.data.data.numbers)
          setForOrder(true)
        } catch (error) {
          if (error.response) {
            const apiError = error?.response?.data?.data?.errors
            handleApiError(apiError)

          }
          const e = error?.response?.data?.title;
          toast.error(e || error);
        } finally {
          setLoading(false)
        }
      }
    }
  }
  const handleApiError = (errorFields) => {
    setHasError(errorFields);
  };
  const handleChange = (selected, fieldName) => {
    setForm(form => ({
      ...form,
      [fieldName]: { value: selected.value, label: selected.label }
    }));
  }
  useEffect(() => {
    getStateData()
    getRouteData()
    checkRunningOrder(false)

  }, [])
  const checkRunningOrder=async(param=true)=>{
    const waitForCompletion=async()=>{
        try {
            const response = await axios.get("group/running/orders")
            if(response.data.data.status===true)
                {
                    setLoading(true)
                    setTimeout(waitForCompletion,1000)
                }
                else{
                    setLoading(false)
                    if(param)
                    

                        {
                            toast.success("Number ordered successfully")
                         setAuth((e) => ({ ...e, refreshCredits: e?.refreshCredits ? false : true }));
                            navigate("/ordernumber")
                        }
                      
                }

        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    waitForCompletion()
}
  const getStateData = async () => {
    try {
      const response = await GroupService.all(0, -1);
      setGroupData(response.data?.data.groups.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  }
  const getRouteData = async () => {
    try {
      const response = await GroupService.allRoute(0, -1);
      setRouteData(response.data?.data.routes.map((e) => ({ ...e, key: e._id })));
    } catch (error) {
      const e = error?.response?.data?.title;
      toast.error(e || error);
    }
  }
  const GroupDataOptions = groupData.map(item => ({
    value: item?._id,
    label: item?.name
  }))
  const RouteDataOptions = routeData.map(item => ({
    value: item?._id,
    label: item?.name
  }))
  return (
    <>
      <div style={{ pointerEvents: loading ? 'none' : 'auto', filter: loading ? 'blur(1px)' : 'none', }}>
        {loading && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <Spin size="large"  className="custom-spin"/>
          </div>
        )}
      
        <div className="flex items-center justify-center w-full">
          <label htmlFor="dropzone-file" className={`flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 
          }`
          }
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <IoCloudUpload style={{ width: 75, height: 75 }} />
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs font-semibold text-gray-500 dark:text-gray-400">
                {csvFile ? csvFile?.name : "CSV File"}
              </p>
            </div>
            <input ref={fileInputRef} id="dropzone-file" type="file" accept=".csv" className="hidden"
              onChange={handleUploadFile}
            />
          </label>

        </div>
        <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">{headerName[0] == 'state' || headerName[0] == 'areacode' ||  headerName[0] == 'tollfree' ? headerName[0]  : null}</label>
        {hasError?.length > 0 && <p className="mb-2" style={{ color: "red" }}>Number not available for {hasError?.map((item) => item.name).join(',')},Please remove and upload file again</p>}
        <div className="flex flex-wrap ">
          {csvData.map(value => (
            <div className="flex items-center   " style={{ border: `1px solid ${value.quantity === '' || hasError?.map((item) => item.name).includes(value.state) || hasError?.map((item) => item.name).includes(value.areacode) ||  hasError?.map((item) => item.name).includes(value.tollfree) ? 'red' : 'white'}`, borderRadius: "3px", marginRight: "5px", marginBottom: "8px" }}>
              <div className={`${hasError?.map((item) => item.name).includes(value.areacode)  || hasError?.map((item) => item.name).includes(value.state) || hasError?.map((item) => item.name).includes(value.tollfree) ? "bg-red text-white px-2" : "bg-primary text-white   px-2"} `}>{headerName[0] == 'state' ? value.state : headerName[0] == 'areacode' ? value.areacode : value.tollfree}</div>
              <input
                type="text"
                style={{color:"white",backgroundColor:"black"}}
                className="w-8 border-none focus:outline-none ps-1"
                value={value.quantity}
              // onChange={(e) => handleChangeQuantityState(value.value, e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-start mt-5 mb-2">
          <button className=" rounded-md p-1 px-3 me-3" style={{ border: "2px solid #de4300" }} onClick={(e) => handleDownloadTemplateState(e)}> Download State Template</button>
          <button className="  rounded-md p-1 px-3 me-3" style={{ border: "2px solid #de4300" }} onClick={(e) => handleDownloadTemplateAreaCode(e)} > Download Area Code Template</button>
          <button className="  rounded-md p-1 px-3" style={{ border: "2px solid #de4300" }} onClick={(e) => handleDownloadTemplatetollFree(e)} > Download Toll Free Template</button>

          
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-4">Route<span style={{color:"red"}}>*</span></label>
          <Select
            className=" mb-4 rounded-lg border border-gray-300"
            classNamePrefix="custom-select"
            style={{ width: '100%' }}
            name="route"
            options={RouteDataOptions}
            value={form.route ? { value: form.route.value, label: form.route.label } : null}
            onChange={(selected) => handleChange(selected, 'route')}  >

          </Select>
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-4">Group</label>
          <Select
            className=" mb-4 rounded-lg border border-gray-300"
            classNamePrefix="custom-select"
            style={{ width: '100%' }}
            name="group"
            options={GroupDataOptions}
            value={form.group ? { value: form.group.value, label: form.group.label } : null}
            onChange={(selected) => handleChange(selected, 'group')}  >

          </Select>
        </div>
        <div className="flex flex-grow justify-end" >
            <Button className={forOrder ? "bg-green text-white font-bold  rounded px-2" :"bg-primary text-white rounded-md p-1 px-3 custom-ant-btn"} onClick={handleSubmit}>
              {forOrder ? `Order (${orderNumber.length}) Number` : "Check Number"}
            </Button>
          </div>
      </div>
    </>
  )
}
export default FileUpload;