import axios from "../api/axios";
import { storedKeys } from "../utils/storedKeys";

const CardService = {
  all: (offset = 0, limit = 10) =>
    axios.get("/credit/cards", { params: { offset, limit } }),
  //   updateStatus: (user_id, status) => axios.patch("/user", { status, user_id }),
  userPermissions: () => axios.get("/user/permission"),
  getPermission: (type) => {
    const permissions = JSON.parse(
      localStorage.getItem(storedKeys.user)
    )?.permissions;

    if (!permissions) return false;

    return permissions.find((e) => e.name === type)?.access;
  },
  isAdmin: () => {
    const user = JSON.parse(localStorage.getItem(storedKeys.user));
    if (!user) return false;

    return user?.role?.name?.toLowerCase() === "admin" ? false : true;
  },
};

export default CardService;
