import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { BalanceProvider } from "./components/BalanceContext";
// import { LoadingProvider } from "./context/LoadingContext";
import { RowLoadingProvider } from "./context/LoadingContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
      <BalanceProvider>
        <RowLoadingProvider >
        <App />
        </RowLoadingProvider>
        </BalanceProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
