import { Button, Col, Dropdown, Flex, Input, Menu, Modal, Progress, Row, Select, Slider, Spin, Table, Tooltip, Typography, message } from "antd";
import { useEffect, useState, useRef, } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import moment from "moment";
import { EllipsisOutlined, ReloadOutlined, StopOutlined, DeleteOutlined, EyeOutlined, EditOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined, PlusOutlined, CopyOutlined } from "@ant-design/icons";
import Item from "antd/es/list/Item";
import PaginationCommon from "../../components/PaginationCommon";
import { all } from "axios";
import { useRowLoading } from "../../context/LoadingContext";
import { PROXY_STATE } from "../../utils/enums";
import { copyToClipboard, validateIPv4 } from "../../utils/CommonFunctions";
import { TbArrowUp } from "react-icons/tb";
import { ApiCall } from "../../utils/CommonFunctions";
import UserService from "../../services/userService";
import useAuth from "../../hooks/useAuth";
import CreditService from "../../services/creditService";
import Swal from "sweetalert2";
import { FaPlus, FaMinus } from "react-icons/fa";
import RegionCard from "./RegionCard";
import { add } from "lodash";

export default function Proxy({ userId }) {
    const { Text } = Typography;
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    const [count, setCount] = useState('');
    const { rowLoadingStates, setRowLoading } = useRowLoading();
    const apiCallInProgress = useRef(false);
    const [allProxy, setAllProxy] = useState([])
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressRowIds, setProgressRowIds] = useState([]);
    const [dataToRecord, setDataToRecord] = useState(false)
    const [showpagination, setShowPagination] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleForInstance, setIsModalVisibleForInstance] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [carrierIpValue, setCarrierIpValue] = useState('');
    const [values1, setValues1] = useState([]);
    const [dialerIpValueError, setDialerIpValueError] = useState(false);
    const [carrierIpValueError, setCarrierIpValueError] = useState(false);
    const [carrieIpValues, setCarrieIpValues] = useState([]);
    const [proxyData, setProxyData] = useState([])
    const [nameFieldValue, setNameFieldValue] = useState('')
    const [userCredits, setUserCredits] = useState(null);
    const [isContactivityOrBYOC, setIsContactivityOrBYOC] = useState('')
    const [contactivityCarrierIp, setContactivityCarrierIP] = useState('')
    const [hoveredRowKey, setHoveredRowKey] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState([{ name: "", slug: "" }])
    const [regions, setRegions] = useState([])
    const [userCardAndCreditData, setUserCardAndCredit] = useState([])
    const [offset, setOffSet] = useState(0);
    const [limit, setLimit] = useState(10);
    const handleStartStop = async (rowId) => {
        // setLoading(true)
        setRowLoading(rowId, true);

        const item = allProxy.find((item) => item._id == rowId);
        if (item?.state == PROXY_STATE.ON) {
            Swal.fire({
                title: "Are you sure you want to Stop?",
                text: "All active live calls will be terminated.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, stop it!",
                customClass: {
                    popup: 'custom-swal'
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setRowLoading(rowId, true);
                    let addField = { proxy_id: rowId, action: PROXY_STATE.SHUTDOWN }
                    if (UserService.isAdmin) {
                        addField = { ...addField, user_id: userId }
                    }
                    try {
                        const response = await axios.patch(`/proxy`, addField)
                            .then(() => {
                                getAllProxy(offset, limit);
                                toast.success("Proxy stopped successfully");
                                setRowLoading(rowId, false)

                            });
                    } catch (error) {
                        const e = error?.response?.data?.title;
                        toast.error(e || error);
                        setRowLoading(rowId, false)

                    }
                }
            })

        }
        if (item?.state == PROXY_STATE.OFF) {
            let addField = { proxy_id: rowId, action: PROXY_STATE.POWER_ON }
            if (UserService.isAdmin) {
                addField = { ...addField, user_id: userId }
            }
            try {
                const response = await axios.patch(`/proxy`, addField)
                    .then(() => {
                        getAllProxy(offset, limit);
                        toast.success("Proxy start successfully");
                    });
            } catch (error) {
                const e = error?.response?.data?.title;
                toast.error(e || error);
            }
        }
        setRowLoading(rowId, false)

    }

    const handleDestroy = async (rowId) => {
        // setLoading(true)
        Swal.fire({
            title: "Are you sure you want to destroy?",
            text: "You won't be able to revert this!,The balance used for the proxy will not be refunded. If you deploy a new proxy, you will be charged the full amount according to your subscribed package",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, destroy it!",
            customClass: {
                popup: 'custom-swal'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setRowLoading(rowId, true);
                    let response;
                    if (UserService.isAdmin()) {
                        response = await axios.delete(`/proxy?proxy_id=${rowId}&user_id=${userId}`);
                    } else {
                        response = await axios.delete(`/proxy?proxy_id=${rowId}`)
                    }
                    if (response !== undefined) {
                        getAllProxy(offset, limit);
                        toast.success("Proxy Server deleted successfully");
                    }
                } catch (error) {
                    const e = error?.response?.data?.title;
                    toast.error(e || error);
                }
                // setLoading(false)
                setRowLoading(rowId, false)
            }
        })






    }
    useEffect(() => {
        getRegion();
        getCreditCount()

    }, []);
    const getCreditCount = async () => {
        try {
            let response;
            if(UserService.isAdmin)
                {
                    response = await axios.get(`/credit/credits-count?user_id=${userId}`)
                }
                else{
                    response = await axios.get(`/credit/credits-count`)
                } 
            setUserCardAndCredit(
                response.data?.data
            );
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }

    }
    const getRegion = async () => {
        try {
            const response = await axios.get("/proxy/regions");
            setRegions(
                response.data?.data.regions.map((e) => ({ ...e, key: e.SLUG }))
            );
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const getItems = (row) => {
        const item = allProxy.find((item) => item._id == row?._id);
        const items = [
            {
                label: (
                    <div onClick={() => navigate(`/proxy/${row._id}`, { state: { proxy: row, userId: userId } })}>
                        <EyeOutlined /> View
                    </div>
                ),
                key: "0",
            },
        ];

        // if (!UserService.isAdmin()) {
        items.push(
            {
                label: (
                    <div onClick={() => handleCloseModal(item)}>
                        <EditOutlined /> Edit
                    </div>
                ),
                key: "1",
            },
            {
                label: (
                    <div onClick={() => handleStartStop(row?._id)}>
                        {item?.state == PROXY_STATE.ON ? (
                            <div>
                                <StopOutlined /> Stop
                            </div>
                        ) : (
                            <div>
                                <ReloadOutlined /> Start
                            </div>
                        )}
                    </div>
                ),
                key: "2",
            },
            {
                label: (
                    <div onClick={() => handleCloseModalForInstance(item)}>
                        <PlusOutlined /> Add Instances
                    </div>
                ),
                key: "3",
            },
            {
                label: (
                    <div style={{ color: "red" }} onClick={() => handleDestroy(row?._id)}>
                        <DeleteOutlined /> Destroy
                    </div>
                ),
                key: "4",
            },

        );
        // }
        // if (userCredits.length > 0) {
        //     items.push({
        //       label: (
        //         <div onClick={() => handleCloseModalForInstance(item)}>
        //           <PlusOutlined /> Add Instances
        //         </div>
        //       ),
        //       key: "3",
        //     });
        //   }
        return items;

    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
            render: (_, record) => {
                const name = record?.name ? `${record?.name}` : "N/A";

                return (
                    <span>
                        {name} <CopyOutlined onClick={() => copyToClipboard(name)} />
                    </span>
                );
            },
        },
        {
            title: "Created Date",
            dataIndex: "created_date",
            sorter: (a, b) => (a.created_date || '').localeCompare(b.created_date || ''),
            render: (_, record) => {
                const timestamp = record?.created_date;
                const dateObj = new Date(timestamp);
                const dateString = dateObj.toISOString().split('T')[0];
                return <span>{record?.created_date == "Invalid date" ? "N/A" : dateString}</span>;
            },
        },
        {
            title: "Expiry Date",
            dataIndex: "purchasing_date",
            sorter: (a, b) => (a.purchasing_date || '').localeCompare(b.purchasing_date || ''),
            render: (_, record) => {
                const timestamp = record?.purchasing_date;
                const dateObj = new Date(timestamp);

                if (isNaN(dateObj.getTime())) {
                    return <span>N/A</span>;
                }
                dateObj.setMonth(dateObj.getMonth() + 1);
                const dateString = dateObj.toISOString().split('T')[0];

                return <span>{dateString}</span>;
            },
        },
        {
            title: "Proxy IP",
            dataIndex: "purchasing_date",
            sorter: (a, b) => (a.instances.find((item) => item.is_signiling === true)?.ipv4 || '').localeCompare(b.instances.find((item) => item.is_signiling === true)?.ipv4 || ''),
            render: (_, record) => {
                {
                    const ip = record?.instances ? record.instances.find((item) => item.is_signiling === true)?.ipv4 : "N/A";
                    return <span>{ip} {ip && <CopyOutlined onClick={() => copyToClipboard(ip)} />}</span>;
                }
            },
        },
    
        {
            title: "Dialer IPs",
            dataIndex: "dialer_ips",
            render: (_, record) => {
                const ips = record?.dialer_ips;
                if (!ips) return "N/A";

                const firstIp = ips[0];

                const moreCount = ips.length > 1 ? ips.length - 1 : '';
                const remainingIps = ips.slice(1);
                const tooltipContent = remainingIps.map((ip, index) => (
                    <div key={index}>{ip},</div>
                ));
                return (
                    <span>
                        {firstIp}
                        {moreCount && (
                            <>
                                , <span style={{ color: '#de4300', textDecoration: 'underline' }}><Tooltip title={tooltipContent}> {moreCount} More  </Tooltip></span>
                            </>
                        )}
                    </span>
                );
            },
        },
        {
            title: "Carrier IP",
            dataIndex: "carrier_ips",
            render: (_, record) => {
                {
                    const regions = record?.carrier_ips ? `${record?.carrier_ips}` : "N/A";

                    return <span>{regions}  <CopyOutlined onClick={() => copyToClipboard(regions)} /></span>;
                }
            },
        }
        ,
        {
            title: "Instance IPs",
            render: (_, record) => {
                const ip = record?.instances.filter((item)=>item.is_media === true);
                const ips=ip.map((item)=>item?.ipv4)
                console.log(ips,"09090909090")
                if (!ips) return "N/A";

                const firstIp = ips[0];

                const moreCount = ips.length > 1 ? ips.length - 1 : '';
                const remainingIps = ips.slice(1);
                const tooltipContent = remainingIps.map((ip, index) => (
                    <div key={index}>{ip},</div>
                ));
                return (
                    <span>
                        {firstIp}
                        {moreCount && (
                            <>
                                , <span style={{ color: '#de4300', textDecoration: 'underline' }}><Tooltip title={tooltipContent}> {moreCount} More  </Tooltip></span>
                            </>
                        )}
                    </span>
                );
            },

        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            sorter: (a, b) => (a.quantity || 0) - (b.quantity || 0),

        },
        {
            title: "Region",
            dataIndex: "region",
            sorter: (a, b) => (a.region || '').localeCompare(b.region || ''),
            render: (_, record) => {
                {
                    const regions = record?.region ? `${record?.region}` : "N/A";
                    return <span>{regions}</span>;
                }
            },
        }
        ,
        {
            title: "Status",
            dataIndex: "state",
            sorter: (a, b) => (a.state || '').localeCompare(b.state || ''),
            render: (_, record) => {
                // if (!record?.region) return <span>N/A</span>;
                const IPStatus = `${record.state === PROXY_STATE.ON ? "ON" : "OFF"}`;
                const statusClass = IPStatus === 'ON' ? 'greenBadge' : 'redBadge';
                return (
                    <span className={statusClass}>
                        {IPStatus}
                    </span>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                rowLoadingStates[record?._id] ? (
                    <Button
                        className="custom-ant-btn"
                        style={{
                            backgroundColor: '#de4300',
                            color: 'white',
                            padding: '8px',
                            display: 'flex',
                        }}
                    >
                        <Spin className="custom-spin" />
                    </Button>
                ) : (
                    <Dropdown overlay={<Menu className="custom-dropdown-menu" items={getItems(record)} />} trigger={['click']}>
                        <Button
                            className="custom-ant-btn"
                            onClick={(e) => e.preventDefault()}
                            style={{
                                backgroundColor: '#de4300',
                                color: 'white',
                                padding: '8px',
                                display: 'flex',
                                border: "none !important"
                            }}
                        >
                            <EllipsisOutlined style={{ fontSize: '20px' }} />
                        </Button>
                    </Dropdown>
                )
            ),
        },
    ];
    useEffect(() => {
        // getAllProxy(0, 10);
        if (auth?._id) {
            getUserCredits(auth?._id)

        }

    }, [auth?._id]);
    const getUserCredits = async (id) => {
        try {
            let response;
            if (UserService.isAdmin()) {
                response = await CreditService.userCredit(userId);
            }
            else {
                response = await CreditService.userCredit(id);
            }

            getAllProxy(offset, limit, response)
            setUserCredits(response.data?.data.userCredits);
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    };
    useEffect(() => {
        getPrize();
    }, []);

    const getAllProxy = async (offset, limit, userCredits = null) => {
        if (apiCallInProgress.current) return;
        apiCallInProgress.current = true;
        try {
            setLoading(true)
            let response;

            if (UserService.isAdmin()) {
                response = await axios.get(`/proxy/all?offset=${offset}&limit=${limit}&user_id=${userId}`);
            } else {
                response = await axios.get(`/proxy/all?offset=${offset}&limit=${limit}`);
                if (userCredits && userCredits.data.data.userCredits.length === 0 && response.data.data.proxies.length === 0) {
                    navigate("/settings/history");
                }
            }
            const progressRows = response.data?.data.proxies.filter((e) => e.is_progress === true);
            setAllProxy(
                response.data?.data.proxies.map((e) => ({ ...e, key: e._id }))
            );
            setCount(response.data.data.total_count)
            if (progressRows.length > 0) {
                progressRows.forEach(async (row) => {
                    setRowLoading(row._id, true);
                    const checkStatus = async () => {
                        const rowDataResponse = await axios.get(`/proxy?proxy_id=${row._id}`);

                        if (rowDataResponse.data.data.is_progress !== true) {
                            setRowLoading(row._id, false)
                            toast.success("Proxy created successfully")
                            getAllProxy(offset, limit)
                        }
                        else {
                            setTimeout(checkStatus, 5000);
                        }

                    }
                    checkStatus();
                },)
            }


        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
        setLoading(false)
        apiCallInProgress.current = false;
    }

    const handleCloseModal = (proxyData) => {
        setIsModalVisible(!isModalVisible);
        setProxyData(proxyData)
        setNameFieldValue(proxyData.name)
        setCarrieIpValues(proxyData?.bring_own_carrier == true ? proxyData.carrier_ips : [])
        setValues1(proxyData.dialer_ips)
        setIsContactivityOrBYOC(proxyData?.bring_own_carrier)
        setContactivityCarrierIP(proxyData?.bring_own_carrier == false ? { value: proxyData.carrier_ips[0], label: proxyData.carrier_ips[0] } : [])
        setSelectedCard(proxyData?.bring_own_carrier == true ? 'BYOC' : 'Contactivity')
    };
    const [balanceToCharge, setBalanceToCharge] = useState('')
    const handleCloseModalForInstance = (proxyData) => {

        setIsModalVisibleForInstance(!isModalVisibleForInstance);
        setProxyData(proxyData)
        const prize = userCredits[0]?.credit?.proxy;
        let oneDayCharge = prize / 30;
        const purchasingDate = new Date(proxyData?.purchasing_date);
        const expiryDate = new Date(purchasingDate);
        expiryDate.setDate(expiryDate.getDate() + 30);
        const currentDate = new Date();
        const diffTime = expiryDate - currentDate;
        const daysToCharge = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let balance = oneDayCharge * daysToCharge;
        setBalanceToCharge(balance)
        setSliderValue(1)
        setSelectedCountry([{ name: "", slug: "" }])
    };
    const handleInputChangeMultipe = (e) => {
        setInputValue(e.target.value);

    };
    const handleInputKeyDownForCarrier = (e) => {
        if (e.key === 'Enter') {
            processInputForCarrier();
        }

    }
    const processInputForCarrier = () => {
        if (carrierIpValue.trim() && validateIPv4(carrierIpValue.trim())) {
            setCarrieIpValues([...carrieIpValues, carrierIpValue.trim()]);
            setCarrierIpValue('');
            setCarrierIpValueError(false)

        }
        else {
            setCarrierIpValueError(true);

        }
    }
    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            procesInput();
        }
    };
    const procesInput = () => {
        if (inputValue.trim() && validateIPv4(inputValue.trim())) {
            setValues1([...values1, inputValue.trim()]);
            setInputValue('');
            setDialerIpValueError(false)
        }
        else {
            setDialerIpValueError(true);
        }
    }
    const handleRemoveValue = (index) => {
        setValues1(values1.filter((_, i) => i !== index));
    };
    const handleRemoveValueForCarrier = (index) => {
        setCarrieIpValues(carrieIpValues.filter((_, i) => i !== index))
    };
    const handleChangeCarrieIp = (value) => {
        setContactivityCarrierIP({ value: value, label: value })

    }
    const handleEdit = async () => {
        if (nameFieldValue == '') {
            toast.error("Name field is required")
            return
        }
        if (values1.length == "0") {
            toast.error("Atleat One dialer IP is required")
            return
        }
        // if (carrieIpValues.length === 0) {
        //     toast.error("Atleat One carrier IP is required")
        //     return
        // }
        let addField = {
            proxy_id: proxyData?._id,
            name: nameFieldValue,
            dialer_ips: values1,
            carrier_ips: selectedCard == "BYOC" ? carrieIpValues : [contactivityCarrierIp?.value],
            bring_own_carrier: selectedCard == 'BYOC' ? true : false

        }
        if (UserService.isAdmin) {
            addField = { ...addField, user_id: userId }
        }
        setLoading(true)
        const respnse = await ApiCall(
            "patch",
            "/proxy/proxy",
            addField,
            "Proxy Server"

        )
        // .then((res) => {
        if (respnse !== undefined) {
            setLoading(false)
            getAllProxy(offset, limit)
            setIsModalVisible(false)
        }
        setLoading(false)

        // })

    }
    const [selectedCard, setSelectedCard] = useState('');
    const handleClick = (name) => {

        setSelectedCard(name);
    };
    const getContentForModal = () => {
        return (
            <>
                <div className="mt-2 mb-2">
                    <label className="font-bold" >Name</label>
                </div>

                <Input className="custom-input" placeholder="Enter Name" name="name" value={nameFieldValue} onChange={(e) => setNameFieldValue(e.target.value)} />

                <div className="mt-2 mb-2">
                    <label className="font-bold" >Dialer IPs</label>
                </div>
                <div>
                    <div className="input-container">
                        <Input
                            className="custom-input"
                            placeholder="Enter multiple values and press Enter"
                            value={inputValue}
                            onChange={handleInputChangeMultipe}
                            onKeyDown={handleInputKeyDown}
                            style={{
                                padding: '5px',
                                borderRadius: '4px',
                                marginBottom: '5px',
                                border: dialerIpValueError ? '1px solid red' : '1px solid #d9d9d9'
                            }}
                        />
                        <button
                            onClick={procesInput}
                            className=""
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: "#de4300",
                                border: "none",
                                borderRadius: "0 8px 8px 0",
                                width: "6rem",
                                height: "2rem",
                                marginBottom: "5px"
                            }}
                        >
                            Add IP
                        </button>
                    </div>
                    <div>
                        {values1?.map((value, index) => (
                            <div key={index} className="tag" style={{ backgroundColor: 'black', padding: '2px 6px', borderRadius: '4px', display: 'inline-block', marginRight: '5px' }}>
                                {value}
                                <button onClick={() => handleRemoveValue(index)} style={{ marginLeft: '5px', cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}><CloseOutlined /></button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-2 mb-2">
                    <label className="font-bold" >Carrier IP</label>
                </div>
                <Row gutter={16} className="mb-4 mt-4 flex items-center">
                    <Col span={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                border: selectedCard === 'Contactivity' ? '2px solid #de4300' : '1px solid #d9d9d9',
                                borderRadius: 4,
                                cursor: 'pointer',
                                transition: 'border 0.2s',
                                padding: '8px',
                            }}
                            onClick={() => handleClick("Contactivity")}
                        >
                            <div style={{ flex: 1 }}>
                                Contactivity
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <span className="bg-primary"
                                    style={{
                                        color: 'white',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Free
                                </span>
                            </div>
                        </div>

                    </Col>
                    <Col span={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                border: selectedCard === 'BYOC' ? '2px solid #de4300' : '1px solid #d9d9d9',
                                borderRadius: 4,
                                cursor: 'pointer',
                                transition: 'border 0.2s',
                                padding: '8px',
                            }}
                            onClick={() => handleClick("BYOC")}
                        >
                            <div style={{ flex: 1 }}>
                                Other Carrier
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                {prize?.bring_own_carrier === 0 ? (
                                    <span className="bg-primary"
                                        style={{
                                            color: 'white',
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Free
                                    </span>
                                ) : (
                                    <span className="bg-primary"
                                        style={{
                                            color: 'white',
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ${prize?.bring_own_carrier}
                                    </span>
                                )}
                            </div>
                        </div>


                    </Col>
                </Row>
                {
                    selectedCard == 'BYOC' ?
                        <>

                            <div className="input-container">
                                {/* <div> */}
                                <Input
                                    className="custom-input"
                                    placeholder="Enter multiple values and press Enter"
                                    value={carrierIpValue}
                                    disabled={carrieIpValues.length === 1}
                                    onChange={(e) => setCarrierIpValue(e.target.value)}
                                    onKeyDown={handleInputKeyDownForCarrier}
                                    style={{
                                        padding: '5px',
                                        borderRadius: '4px',
                                        marginBottom: '5px',
                                        border: carrierIpValueError ? '1px solid red' : '1px solid #d9d9d9'

                                    }}
                                />
                                <button
                                    onClick={() => processInputForCarrier()}
                                    className=""
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: "#de4300",
                                        border: "none",
                                        borderRadius: "0 8px 8px 0",
                                        width: "6rem",
                                        height: "2rem",
                                        marginBottom: "5px"
                                    }}
                                >
                                    Add IP
                                </button>
                            </div>
                            <div>
                                {carrieIpValues?.map((value, index) => (
                                    <div key={index} className="tag" style={{ backgroundColor: 'black', padding: '2px 6px', borderRadius: '4px', display: 'inline-block', marginRight: '5px' }}>
                                        {value}
                                        <button onClick={() => handleRemoveValueForCarrier(index)} style={{ marginLeft: '5px', cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}><CloseOutlined /></button>
                                    </div>
                                ))}
                            </div>
                            {/* </div> */}
                        </> :
                        <>

                            <Select
                                className="custom-select"
                                style={{ width: "100%" }}
                                placeholder="Please select Carrier IP"
                                value={contactivityCarrierIp?.value}
                                onChange={handleChangeCarrieIp}
                                options={[
                                    {
                                        value: '88.151.128.95',
                                        label: '88.151.128.95',
                                    },
                                    {
                                        value: '88.151.130.103',
                                        label: '88.151.130.103'
                                    },
                                    {
                                        value: '88.151.132.98',
                                        label: '88.151.132.98'
                                    }
                                ]}
                            >
                            </Select>
                        </>

                }

                <div className="flex justify-end mt-5 mb-2">
                    <button onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisible(false)

                    }} className="border-primary border-1 text-primary rounded-md p-1 px-3"
                    >
                        Cancel
                    </button>{" "}
                    <button type="submit" onClick={handleEdit} className="bg-primary text-white rounded-md custom-ant-btn p-1 px-3" >
                        {loading && <Spin size="small" className="mr-3 custom-spin" />}
                        Save
                    </button>
                </div>
            </>
        )
    }
    const getPrize = async () => {
        try {
            let response;
            if (UserService.isAdmin()) {
                response = await axios.get(`/proxy/package?user_id=${userId}`);
            }
            else{
                response = await axios.get("/proxy/package");
            }
            const packages = response.data.data.credit
            setPrize(packages)
        } catch (error) {
            const e = error?.response?.data?.title;
            toast.error(e || error);
        }
    }
    const [sliderValue, setSliderValue] = useState(1)
    const [prize, setPrize] = useState([])

    const handleOnChange = (newValue) => {
        setSliderValue(newValue)
    }
    const handleIncrease = () => {
        if (sliderValue < 10) {
            setSliderValue(sliderValue + 1);
        }
    };
    const handleDecrease = () => {
        if (sliderValue > 1) {
            setSliderValue(sliderValue - 1);
        }
    };
    const handleAddInstances = async () => {
        const updatedField = {
            quantity: sliderValue,
            proxy_id: proxyData?._id,
            slug: selectedCountry?.slug,
        }
        const response = await ApiCall(
            "patch",
            "/proxy/instances",
            updatedField,
            "Instances",
            null,

        )
        if (response !== undefined) {
            getAllProxy(offset, limit)
            setIsModalVisibleForInstance(false)
        }

    }
    const totalBalance = parseFloat(balanceToCharge) % 1 !== 0 ? parseFloat(balanceToCharge).toFixed(4) * sliderValue : parseFloat(balanceToCharge) * sliderValue
    const getContentForModalForInstances = () => {
        return (
            <>
                <div className="mt-2">
                    <label className="font-bold" >Quantity</label>
                </div>
                <div className="flex items-center">
                    <span className="bg-primary"
                        style={{
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                        }}
                    >
                        <FaMinus onClick={handleDecrease} />
                    </span>
                    <Slider value={sliderValue} min={1} max={10} onChange={handleOnChange} style={{ width: "100%" }} className="custom-slider ml-3 mt-3 mr-3" />
                    <span className="bg-primary"
                        style={{
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                        }}
                    >
                        <FaPlus onClick={handleIncrease} />
                    </span>

                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p>{sliderValue}</p>
                    <p>10</p>
                </div>
                <div className="mt-2">
                    <label className="font-bold" >Region</label>
                </div>
                <div>
                    <RegionCard regions={regions}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                    />
                </div>
                <div className="border border-border p-4 rounded-lg shadow-lg mt-4">
                    <h3 className="text-md mb-4">Summary</h3>
                    <table className="w-full mb-4 custom-table2">
                        <thead>
                            <tr>
                                <th className="text-left">Service</th>
                                <th className="text-left">Price</th>
                                <th className="text-left">Qty</th>
                                <th className="text-left">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>Proxy Instances</td>
                                <td>${parseFloat(balanceToCharge) % 1 !== 0 ? parseFloat(balanceToCharge).toFixed(4) : parseFloat(balanceToCharge)}</td>
                                <td>{sliderValue}</td>
                                <td>${totalBalance}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-right">
                        <span>Total Cost:${totalBalance} </span>
                    </div>
                    <div className="text-right ">
                        <span>Your {userCardAndCreditData?.balance < 0 ? `Balance: -$${parseFloat(userCardAndCreditData?.balance) % 1 !== 0 ? parseFloat(userCardAndCreditData?.balance).toFixed(4) : parseFloat(userCardAndCreditData?.balance) * -1}` : `Balance: $${parseFloat(userCardAndCreditData?.balance) % 1 !== 0 ? parseFloat(userCardAndCreditData?.balance).toFixed(4) : parseFloat(userCardAndCreditData?.balance)}`}</span>
                    </div>
                    <div className="text-right  ">
                        <span>Remaining Balance:${parseFloat(userCardAndCreditData?.balance - totalBalance)% 1 !== 0 ? parseFloat(userCardAndCreditData?.balance-totalBalance).toFixed(4) : parseFloat(userCardAndCreditData?.balance- totalBalance)} </span>
                    </div>
                </div>
                <div className="flex justify-end mt-5 mb-2">
                    <button onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisibleForInstance(false)
                        setSelectedCountry([{ name: "", slug: "" }])
                        setSliderValue(1)

                    }} className="border-primary border-1 text-primary rounded-md p-1 px-3"
                    >
                        Cancel
                    </button>{" "}
                    <Button type="submit" onClick={handleAddInstances} disabled={userCardAndCreditData.balance <= 0} className="bg-primary text-white rounded-md custom-ant-btn p-1 px-3" >
                        {loading && <Spin size="small" className="mr-3 custom-spin" />}
                        Add
                    </Button>
                </div>
            </>
        )
    }
    const isDisabled = userCredits?.length === 0;
    return (
        <>
            {
                !UserService.isAdmin() &&
                <div className="flex items-center  justify-between">
                    {loading && (
                        <div style={{ position: 'absolute', top: '45%', left: '58%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                            <Spin size="large" className="custom-spin" />
                        </div>
                    )}
                    <Typography.Text className="text-2xl font-bold white-text">Proxy Server</Typography.Text>
                    <div className="flex items-center  gap-4">
                        {
                            isDisabled ? (
                                <Tooltip title="Please purchase your package">
                                    <span>
                                        <Button
                                            disabled={isDisabled}
                                            className="bg-primary text-white flex items-center "
                                            onClick={() => navigate('/proxy/deploy')}
                                            style={{
                                                backgroundColor: isDisabled ? '#ccc' : '',
                                                color: isDisabled ? '#666' : '',
                                            }}
                                        >
                                            Deploy Proxy
                                        </Button>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Button
                                    disabled={isDisabled}
                                    className="bg-primary text-white flex items-center custom-ant-btn"
                                    onClick={() => navigate('/proxy/deploy')}
                                >
                                    Deploy Proxy
                                </Button>
                            )
                        }


                    </div>
                </div>
            }
            {/* <div className="mt-3 bg-card-bg p-3"> */}

            {/* <Table
                    loading={loading}
                    columns={columns}
                    dataSource={allProxy}
                    pagination={false}
                    className="custom-table1"
                    // rowClassName={"hovered-row"}
                    rowHoverable={false}
                // onChange={handleTableChange}
                /> */}
            <div style={{ padding: '10px', backgroundColor: '#333', color: 'white', marginTop: "10px" }}>
                <div className="flex justify-end mb-3">
                    <Input
                        className="w-52 custom-input"
                        //    value={searchValue} 
                        //    onChange={filterGroups}
                        size="middle"

                        placeholder="Filter groups"
                    />
                </div>
                <Table
                    className="custom-table"
                    columns={columns}
                    dataSource={allProxy}
                    pagination={false}
                    rowClassName={(record) =>
                        hoveredRowKey === record.key ? 'hovered-row' : ''
                    }
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                    }}
                    onRow={(record) => ({
                        style: { backgroundColor: 'black', color: 'white', borderBottom: "#5e5555 !important" },
                        onMouseEnter: () => setHoveredRowKey(record.key),
                        onMouseLeave: () => setHoveredRowKey(null),
                    })}
                    components={{
                        header: {
                            cell: (props) => (
                                <th
                                    {...props}
                                    style={{
                                        backgroundColor: '#444',
                                        color: 'white',
                                        padding: '15px',
                                        borderBottom: "2px solid #5e5555 "

                                    }}
                                />
                            ),
                        },
                        body: {
                            cell: (props) => (
                                <td
                                    {...props}
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '15px',
                                        borderBottom: "2px solid #5e5555 "



                                    }}
                                />
                            ),
                        },
                    }}
                />
            </div>

            <PaginationCommon total={count} data={allProxy} handleChange={getAllProxy} setOffSet={setOffSet} setLimit={setLimit} />
            <Modal
                className="custom-modal"
                title="Edit Proxy"
                open={isModalVisible}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
                okButtonProps={{ className: "bg-primary" }}
                okText="Save"
                footer={<></>}
            >
                {getContentForModal()}
            </Modal>
            <Modal
                className="custom-modal"
                title="Add Instance"
                open={isModalVisibleForInstance}
                onOk={handleCloseModalForInstance}
                onCancel={handleCloseModalForInstance}
                okButtonProps={{ className: "bg-primary" }}
                okText="Save"
                footer={<></>}
            >
                {getContentForModalForInstances()}
            </Modal>
            {/* </div> */}
        </>
    )
}